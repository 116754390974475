@import 'style/mixin';

.mainTitle {
  @include media(contents-mobile, contents-tablet) {
    font-size: 40px !important;
    margin-bottom: 39px !important;
    letter-spacing: -2.03px !important;
  }
  @include media(contents-pc) {
    font-size: 60px !important;
    margin-bottom: 70px !important;
    letter-spacing: -3.03px !important;
  }
}

.infoAnnounce {
  @include media(contents-mobile, contents-tablet) {
    margin-bottom: 39px;
  }
  @include media(contents-pc) {
    margin-bottom: 60px;
  }
}

@import 'style/mixin';

.layout {
  min-height: 100vh;
}

.subLayout {
  display: flex;
  flex-direction: column;
}

.header {
}

.main {
  flex-grow: 1;
}

.footer {
}

@charset "utf-8";
/* CSS Document */

.loginAgree_layer {
  max-width: 530px;
}
.loginAgree_layer .txt {
  font-weight: 700;
  color: #272627;
}

.loginAgreeBody {
  width: 100%;
  margin-top: 9px;
  padding-top: 14px;
  text-align: left;
}

/*checkbox*/
.loginAgreeBody .checkboxSec {
  width: 100%;
  height: 24px;
  margin-bottom: 15px;
}
.loginAgreeBody .checkboxSec label {
  font-size: 15px;
  font-weight: 500;
  padding-top: 1px;
  vertical-align: text-bottom;
  color: #272627;
}

.loginAgreeBody h3 {
  width: 100%;
  height: 24px;
  margin-bottom: 14px;
  padding-bottom: 14px;
  border-bottom: 1px solid #c7c8cc;
}
.loginAgreeBody h3 label {
  font-weight: 700;
  color: #17191d;
}
.loginAgreeBody .grayBox p {
  font-weight: 500;
  height: auto !important;
  letter-spacing: -0.65px;
  word-break: keep-all;
  color: #272627;
}
.loginAgreeBody .grayBox .grayBox_text {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.3;
  height: auto;
  letter-spacing: -0.5px;
  word-break: keep-all;
  color: #272627;
}
.loginAgreeBody .grayBox {
  font-size: 13px;
  width: calc(100% - 20px);
  padding: 24px 20px;
  background-color: #f5f5f5;
}
.loginAgreeBody .grayBox .tableContent {
  overflow-x: scroll;
  overflow-y: hidden;
  width: 400px;
  margin: 15px 0;
  padding-right: 20px;
  text-align: center;
}
.loginAgreeBody .grayBox .tableContent table {
  margin-bottom: 6px;
  padding-right: 20px;
  border-top: 1px solid #c7c9d1;
  border-bottom: 1px solid #c7c9d1;
}

.loginAgreeBody .grayBox .tableContent table th {
  font-size: 13px;
  font-weight: 700;
  height: 40px;
  vertical-align: middle;
  color: #747477;
  border-right: 1px solid #c7c9d1;
  border-bottom: 1px solid #c7c9d1;
  background-color: #eaeaea;
}
.loginAgreeBody .grayBox .tableContent table td {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.3;
  height: 56px;
  vertical-align: middle;
  color: #272627;
  border-right: 1px solid #c7c9d1;
  background-color: #fff;
}
.loginAgreeBody .grayBox .tableContent table th:last-child,
.loginAgreeBody .grayBox .tableContent table td:last-child {
  border-right: none;
}

/*수집 및 이용*/
.loginAgreeBody .loginAgree_collection {
  margin-bottom: 25px;
}
.loginAgreeBody .loginAgree_collection table {
  width: 660px;
}
.loginAgreeBody .loginAgree_collection th {
  width: 33.3%;
}

/*위탁 업체*/
.loginAgreeBody .loginAgree_consignment table {
  width: 440px;
}
.loginAgreeBody .loginAgree_consignment th {
  width: 50%;
}

.loginAgreeBody .loginAgree_consignment .label {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 15px;
  letter-spacing: -0.75px;
  color: #000;
}

/*닫기,확인 버튼*/
.loginAgree_layer .bt {
  font-size: 18px;
  font-weight: 500;
  display: block;
  color: #fff;
}
.loginAgree_layer .bt:hover {
  color: #272627;
}
.loginAgree_layer .bt1 {
  background-color: #272627;
}
.loginAgree_layer .bt1:hover {
  background-color: #cccccc;
}
.loginAgree_layer .bt2 {
  background-color: #9fa1a7;
}
.loginAgree_layer .bt2:hover {
  background-color: #e4e5e6;
}

@media (max-width: 999px) {
  .loginAgree_layer {
    max-width: 360px;
  }
  .loginAgree_layer .cnts .loginAgreeBody {
    overflow-y: auto;
    margin-top: 0;
  }
  .loginAgree_layer .cnts .loginAgreeBody.scroll::-webkit-scrollbar-thumb {
    background-color: #ececec;
  }
  .loginAgree_layer .cnts .loginAgreeBody.scroll::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }
  .loginAgree_layer .cnts .loginAgreeBody .loginAgreeBody_content {
    max-height: calc(100vh - 275px);
    padding-right: 17px;
  }
  .loginAgreeBody .grayBox .tableContent {
    width: 230px;
  }
}

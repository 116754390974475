@import 'style/mixin';

.container {
  position: relative;
  padding-top: 8.854vw;
  padding-bottom: 13.021vw;
  background-color: #eaeaea;
  @include media(main-pc-small) {
    padding-bottom: 1000px;
  }
  @include media(main-pc-medium) {
    padding-bottom: 78.1vw;
  }
}

.anchor {
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.backgroundText {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  text-align: right;
  @include media(main-mobile, main-tablet) {
    padding-top: 112px;
  }
  @include media(main-pc-small, main-pc-medium) {
    padding-top: 127px;
  }
}

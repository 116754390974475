@charset "utf-8";
/* CSS Document */

/*회원 로그인*/
.loginBody {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 500;
  font-style: normal;
  width: 100%;
  max-width: 840px;
  margin: 0 auto;
  padding-top: 39px;
  padding-bottom: 240px;
  text-align: left;
  color: #272627;
}

.loginBody h2.sTit {
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 25px;
  letter-spacing: -1.28px;
  color: #909090;
}

.loginBody h2.sTit span {
  font-size: 17px;
  font-weight: 400;
}

.loginBody .loginContent li:first-child {
  font-size: 50px;
  line-height: 1.42;
  width: 463px;
  height: 132px;
  margin-bottom: 75px;
  letter-spacing: -2.53px;
  background-image: url('../image/sub/icon_smile.png');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 32px 48px;
}

.loginBody .loginContent button {
  font-size: 38px;
  position: relative;
  float: left;
  width: 408px;
  height: 120px;
  padding-left: 63px;
  text-align: left;
  letter-spacing: -1.9px;
  border: 1px solid #909090;
  background-color: #eaeaea;
  background-image: url('../image/sub/icon-arrow-nor-web.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 67px) 36px;
  background-size: 67px 67px;
}

.loginBody .loginContent button:last-child {
  margin-left: 24px;
  background-position: calc(100% - 46px) 36px;
}

.loginBody .loginContent button:hover {
  color: #fff;
  background-color: #272627;
  background-image: url('../image/sub/icon-arrow-hover-web.svg');
  background-size: 67px 67px;
}

.loginBody .loginContent button:hover::before {
  position: absolute;
  top: -54px;
  right: 0;
  width: 30px;
  height: 54px;
  content: '';
  background-image: url('../image/sub/icon-ncm-droid-web.svg');
  background-repeat: no-repeat;
  background-size: 30px 54px;
}

.loginBody .loginContent button dt {
  font-size: 13px;
  margin-bottom: 4px;
  letter-spacing: -0.63px;
}

.loginBody .loginContent button dd {
  margin-left: -3px;
}

.loginBody .loginContent ul::after {
  display: block;
  clear: both;
  content: '';
}

/*비 회원 로그인*/
.nonMemberBody {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 500;
  font-style: normal;
  position: relative;
  width: calc(100% - 120px);
  max-width: 1082px;
  height: 100%;
  min-height: calc(1066px - 211px);
  margin: 0 auto;
  padding: 45px 60px 0;
  text-align: left;
  color: #272627;
}
.nonMemberBody h2.sTit {
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 28px;
  color: #909090;
}
.nonMemberBody h2.sTit span {
  font-size: 17px;
  font-weight: 400;
}
.nonMemberTitle li:first-child {
  font-size: 50px;
  line-height: 1.42;
  width: 690px;
  height: 132px;
  margin-bottom: 52px;
  letter-spacing: -2.53px;
  background-image: url('../image/sub/icon_smile.png');
  background-repeat: no-repeat;
  background-position: 340px bottom;
  background-size: 32px 48px;
}

.nonMemberTitle img {
  width: 139px;
  height: 139px;
}
.nonMemberInput {
  position: absolute;
  top: 318px;
  right: 0;
  width: 100%;
  max-width: 708px;
  margin-right: 60px;
}
.nonMemberInput li {
  height: 60px;
  margin-bottom: 54px;
}
.nonMemberInput li:last-child {
  margin-bottom: 0;
}
.nonMemberInput li:nth-child(2) button {
  font-size: 17px;
  position: absolute;
  right: 0;
  width: 159px;
  height: 60px;
  margin-top: -43px;
  color: #272627;
  border: 1px solid #272627;
  background-color: #eaeaea;
}
.nonMemberInput li:nth-child(2) button:hover {
  color: #eaeaea;
  background-color: #272627;
}
.nonMemberInput li:nth-child(2) button.disabldeBut {
  pointer-events: none;
  color: #eaeaea;
  border: 1px solid #c0c0c1;
  background-color: #c0c0c1;
}
.nonMemberInput dl {
  width: 140px;
  text-align: right;
  letter-spacing: -1.48px;
}
.nonMemberInput dt {
  font-size: 13px;
  margin-bottom: 10px;
  letter-spacing: -0.63px;
}
.nonMemberInput dd {
  font-size: 30px;
}
.nonMemberInput input {
  font-family: 'NEXONLv2GothicRegular';
  font-size: 17px;
  position: absolute;
  left: 180px;
  width: 100%;
  max-width: 526px;
  height: 59px;
  letter-spacing: -0.85px;
  border-bottom: 1px solid #909090;
  background-color: #eaeaea;
}
.nonMemberInput input[type='text']:focus {
  border-bottom: 1px solid #272627;
}
.nonMemberInput li:nth-child(1) input[type='text'] {
  margin-top: -43px;
}
.nonMemberInput li:nth-child(2) input[type='number'] {
  width: 343px;
  height: 58px;
  margin-top: -43px;
  border: none;
  border-bottom: 1px solid #909090;
  outline: none;
}
.nonMemberInput li:nth-child(2) input[type='number']:focus {
  border-bottom: 1px solid #272627;
}
.nonMemberInput li:nth-child(2) input[type='text'] {
  margin-top: 67px;
}
.nonMemberInput input::placeholder {
  color: #909090;
}
.nonMemberInput li:nth-child(3) button {
  font-weight: 700;
  position: absolute;
  right: 0;
  width: 220px;
  margin-top: 92px;
  padding: 0 8px;
  white-space: nowrap;
  letter-spacing: -2.33px;
}
.nonMemberInput li:nth-child(3) button::after {
  display: inline-block;
  width: 35px;
  height: 62px;
  content: '';
  background-image: url('../image/sub/icon_robot.png');
  background-repeat: no-repeat;
  background-size: contain;
}
.nonMemberInput li:nth-child(3) button:hover::after {
  width: 49px;
  height: 49px;
  background-image: url('../image/sub/icon_arrow_right.png');
}
.nonMemberInput p {
  font-size: 13px;
  position: absolute;
  margin-top: 20px;
  padding-left: 180px;
  color: #ff6060;
}
.nonMemberInput p.certifiedError {
  margin-top: 130px;
}
.nonMemberButton {
  width: calc(100% - 183px);
  height: 109px;
  margin-top: 142px;
  padding-left: 183px;
}
.nonMemberButton h3 {
  font-size: 21px;
  margin-bottom: 20px;
  letter-spacing: -1.05px;
}
.nonMemberButton button {
  float: left;
  width: 68px;
  height: 68px;
  margin-right: 16px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.nonMemberButton .fbBut {
  background-image: url('../image/sub/icon_facebook.png');
}
.nonMemberButton .naverBut {
  background-image: url('../image/sub/icon_naver.png');
}
.nonMemberButton .ggBut {
  background-image: url('../image/sub/icon_google.png');
}
.nonMemberButton .appBut {
  background-image: url('../image/sub/icon_apple.png');
}

@media (max-width: 999px) {
  .loginBody {
    max-width: 658px;
    padding-top: 0;
  }
  .loginBody h2.sTit {
    font-size: 16px;
    margin-bottom: 20px;
    letter-spacing: -0.83px;
  }
  .loginBody h2.sTit span {
    font-size: 12px;
  }
  .loginBody .loginContent li:first-child {
    font-size: 33px;
    line-height: 1.41;
    width: 304px;
    height: 86px;
    margin-bottom: 61px;
    letter-spacing: -1.65px;
    background-size: 20px 30px;
  }
  .loginBody .loginContent button {
    font-size: 33px;
    width: 320px;
    height: 94px;
    padding-left: 47px;
    letter-spacing: -1.65px;
    background-position: calc(100% - 40px) 35px;
    background-size: 42px 46px;
  }
  .loginBody .loginContent button:last-child {
    margin-left: 17px;
    background-position: calc(100% - 40px) 35px;
  }
  .loginBody .loginContent button:hover {
    background-position: calc(100% - 34px) 36px;
    background-size: 46px 46px;
  }
  .loginBody .loginContent button:hover::before {
    top: -45px;
    width: 30px;
    height: 55px;
    background-size: 25px 45px;
  }

  /*비 회원 로그인*/
  .nonMemberBody {
    width: 100%;
    max-width: 608px;
    min-height: calc(796px - 211px);
    padding-top: 0;
  }
  .nonMemberBody h2.sTit {
    font-size: 16px;
    margin-bottom: 21px;
  }
  .nonMemberBody h2.sTit span {
    font-size: 12px;
  }
  .nonMemberTitle li:first-child {
    font-size: 33px;
    width: 440px;
    height: 85px;
    margin-bottom: 41px;
    background-position: 224px bottom;
    background-size: 20px 30px;
  }
  .nonMemberTitle img {
    width: 92px;
    height: 92px;
  }
  .nonMemberInput {
    top: 180px;
    width: 100%;
    max-width: 450px;
    margin-left: 175px;
  }
  .nonMemberInput li {
    height: 47px;
    margin-bottom: 31px;
  }
  .nonMemberInput dl {
    width: 105px;
    margin-right: 25px;
  }
  .nonMemberInput dt {
    font-size: 10px;
    margin-bottom: 7px;
  }
  .nonMemberInput dd {
    font-size: 16px;
  }
  .nonMemberInput input {
    font-size: 13px;
    left: 130px;
    width: 320px;
    height: 47px;
  }
  .nonMemberInput li:nth-child(1) input[type='text'] {
    margin-top: -26px;
  }
  .nonMemberInput li:nth-child(2) input[type='number'] {
    width: 194px;
    height: 47px;
    margin-top: -26px;
    padding: 0;
  }
  .nonMemberInput li:nth-child(2) button {
    font-size: 13px;
    width: 110px;
    height: 47px;
    margin-top: -25px;
  }
  .nonMemberInput li:nth-child(3) button {
    width: 157px;
    height: 55px;
    margin-top: 91px;
    padding: 0 8px 0 6px;
    letter-spacing: -1.5px;
  }
  .nonMemberInput li:nth-child(3) button::after {
    width: 27px;
    height: 49px;
  }
  .nonMemberInput li:nth-child(3) button:hover::after {
    width: 44px;
    height: 44px;
  }
  .nonMemberInput p {
    margin-top: 25px;
    padding-left: 131px;
  }
  .nonMemberInput p.certifiedError {
    margin-top: 120px;
  }
  .nonMemberButton {
    width: calc(100% - 106px);
    height: 81px;
    margin-top: 122px;
    padding-left: 106px;
  }
  .nonMemberButton h3 {
    font-size: 13px;
    margin-bottom: 13px;
  }
  .nonMemberButton button {
    width: 54px;
    height: 54px;
    margin-right: 12px;
    background-size: cover;
  }
}

@media (max-width: 767px) {
  .loginBody {
    max-width: 320px;
  }
  .nonMemberBody {
    width: calc(100% - 40px);
    min-height: calc(972px - 226px);
    margin-bottom: 100px;
    padding: 0 20px;
  }
  .loginBody .loginContent button {
    display: block;
    float: none;
  }
  .loginBody .loginContent li:first-child {
    margin-bottom: 51px;
  }
  .loginBody .loginContent button:last-child {
    margin-top: 16px;
    margin-left: 0;
  }
  .loginBody .loginContent button:hover::before {
    width: 0;
    height: 0;
    background-image: none;
  }

  .nonMemberTitle li:first-child {
    width: 281px;
    height: 130px;
    margin-bottom: 31px;
    word-break: keep-all;
    background-position: 100px bottom;
  }
  .nonMemberTitle li:first-child br {
    display: none;
  }
  .nonMemberTitle img {
    width: 65px;
    height: 65px;
  }
  .nonMemberInput {
    top: 285px;
    width: 320px;
    margin-right: 20px;
    margin-left: 0;
  }
  .nonMemberInput li {
    margin-bottom: 66px;
  }
  .nonMemberInput dl {
    text-align: left;
  }
  .nonMemberInput li:nth-child(1) input[type='text'] {
    margin-top: 3px;
  }
  .nonMemberInput li:nth-child(2) input[type='number'] {
    margin-top: 3px;
  }
  .nonMemberInput li:nth-child(2) input[type='text'] {
    margin-top: 81px;
  }
  .nonMemberInput li:nth-child(2) button {
    margin-top: 6px;
  }
  .nonMemberInput li:nth-child(3) button {
    margin-top: 71px;
  }
  .nonMemberInput input {
    left: 0;
    width: 320px;
    height: 47px;
  }
  .nonMemberInput p {
    margin-top: 55px;
    padding-left: 0;
  }
  .nonMemberInput p.certifiedError {
    margin-top: 132px;
  }
  .nonMemberButton {
    width: 100%;
    margin-top: 107px;
    padding-bottom: 100px;
    padding-left: 0;
  }
}

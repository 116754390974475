@import 'style/mixin';

.playlist {
  position: relative;
  opacity: 0;
}

.anchor {
  position: sticky;
  top: 0;
  width: 100%;
  height: 100vh;
}

.wrapper {
  position: fixed;
  display: flex;
  top: 52px;
  width: 100%;
  min-height: 100vh;
  background-image: url(~public/static/images/flow/intro-background-web.svg);
  background-size: cover;
}

:global(.hideBanner) {
  .wrapper {
    top: 0;
  }
}

.container {
  position: absolute;
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  @include media(main-mobile) {
    justify-content: center;
    padding-top: 23.2vw;
    padding-top: getResponsiveRule(360, 767, 84, 111);
  }
  @include media(main-tablet) {
    justify-content: center;
    padding-top: 111px;
  }
  @include media(main-pc-small, main-pc-medium) {
    align-items: center;
    height: 100%;
    padding-bottom: 6vw;
  }
}

.playListTitle {
  //margin-right: 54px;
}

.playListRoute {
  flex: 1;
}

.playListSticker {
  width: 100%;
  @include media(main-mobile, main-tablet) {
    visibility: hidden;
  }
}

.playListStickerMobile {
  position: relative;
  height: auto;
  pointer-events: none;
  @include media(main-pc-small, main-pc-medium) {
    visibility: hidden;
  }
}

@charset "utf-8";

/* #content .cnts sup {vertical-align: 95%;} */
#content .cnts.reservationCntsContainer h2.sTit {
  font-size: 60px;
  font-weight: 500;
  letter-spacing: -3.03px;
  color: #272627;
}
#content .cnts.reservationCntsContainer h2.sTit .sTit2 {
  padding: 10px 0 86px;
}
.required::before {
  display: inline-block;
  width: 5px;
  height: 5px;
  content: '';
  vertical-align: top;
  border-radius: 50%;
  background-color: #e65529;
}
.disabled {
  cursor: default;
  pointer-events: none;
}

.btSec2 {
  font-size: 0;
}
.btSec2 a {
  display: inline-block;
  width: 345px;
  vertical-align: top;
}
.btSec2 a:first-child {
  margin-right: 10px;
}
.btSec3 {
  margin: 0 !important;
  padding: 0 !important;
  border-top: 0 !important;
}

#content .cnts.reservationCntsContainer {
  position: relative;
  max-width: 700px;
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;
}

#confirmation {
  max-width: 1280px !important;
  padding-right: 80px !important;
  padding-left: 80px !important;
}
.pagenation {
  font-size: 0;
  position: relative;
  margin: 40px 0 100px;
  text-align: center;
}
.pagenation a {
  font-size: 17px;
  display: inline-block;
  min-width: 35px;
  height: 26px;
  padding-top: 9px;
  vertical-align: middle;
  color: #909090;
}
.pagenation a:hover {
  color: #909090;
  background-color: #d9d9d9;
}
.pagenation a.current {
  color: #ffffff;
  background-color: #272627;
}
.pagenation a.first,
.pagenation a.prev,
.pagenation a.next,
.pagenation a.last {
  background: url(../image/img_pagenation.png) no-repeat left top;
}
.pagenation a.first {
  background-position: -58px -8px;
}
.pagenation a.first:hover {
  background-position: -58px -58px;
}
.pagenation a.first:active {
  background-position: -58px -108px;
}
.pagenation a.prev {
  margin-right: 6px;
  background-position: -8px -8px;
}
.pagenation a.prev:hover {
  background-position: -8px -58px;
}
.pagenation a.prev:active {
  background-position: -8px -108px;
}
.pagenation a.next {
  margin-left: 6px;
  background-position: -158px -8px;
}
.pagenation a.next:hover {
  background-position: -158px -58px;
}
.pagenation a.next:active {
  background-position: -158px -108px;
}
.pagenation a.last {
  background-position: -108px -8px;
}
.pagenation a.last:hover {
  background-position: -108px -58px;
}
.pagenation a.last:active {
  background-position: -108px -108px;
}

#educationView {
  position: relative;
  max-width: 86.111vw !important;
  padding-bottom: 363px !important;
}
#educationView .education-view-contents {
  display: flex;
  gap: 100px;
}
#educationView .eduInfo {
  position: sticky;
  top: 180px;
  align-self: flex-start;
  flex: 1;
  width: 620px;
}
#educationView .eduInfo h2.sTit .sTit1 {
  font: 400 26px 'Noto Sans KR', sans-serif !important;
  letter-spacing: -1.28px;
  color: #909090;
}
#educationView .eduInfo h2.sTit .sTit1 sub {
  font-size: 17px;
  display: inline-block;
  margin: 0 0 2px 8px;
  vertical-align: text-bottom;
  letter-spacing: -0.85px;
}
#educationView .eduInfo h2.sTit .sTit2 {
  font-size: 50px;
  font-weight: 500;
  padding-bottom: 50px !important;
  letter-spacing: -2.5px;
  background: none;
}
#educationView .eduInfo h2.sTit .sTit2 .txt {
  font-family: 'neodgm', sans-serif;
  font-size: 73px;
}
#educationView .eduInfo h2.sTit .sTit2 .txt:last-child {
  padding-left: 3px;
}
#educationView .eduInfo h2.sTit .sTit2 .txt2 {
  line-height: 1.2;
  display: inline;
  word-break: break-word;
}
#educationView .eduInfo .state {
  padding-bottom: 24px;
}
#educationView .eduInfo ul {
  padding-top: 40px;
  border-top: 1px solid #272627;
}
#educationView .eduInfo ul li {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 18px;
  padding-left: 28px;
  letter-spacing: -0.8px;
  color: #272627;
  background-repeat: no-repeat;
}
#educationView .eduInfo ul li.info1 {
  background-image: url(../image/icon-people-detail.svg);
}
#educationView .eduInfo ul li.info2 {
  background-image: url(../image/icon-how-detail.svg);
}
#educationView .eduInfo ul li.info3 {
  background-image: url(../image/icon-application-detail.svg);
}
#educationView .eduInfo ul li.info4 {
  background-image: url(../image/icon-time-detail.svg);
}
#educationView .eduInfo ul li.info5 {
  background-image: url(../image/icon-amount-detail.svg);
}
#educationView .eduInfo ul li.info6 {
  background-image: url(../image/icon-remark-detail.png);
}
#educationView .eduInfo ul li.info7 {
  background-image: url(../image/icon-operation-detail.svg);
}
#educationView .eduInfo .eduBt {
  margin-top: 24px;
}
#educationView .eduInfo .eduBt a {
  font-size: 34px;
  font-weight: 700;
  position: relative;
  display: inline-block;
  width: 206px;
  height: 206px;
  transition: transform 200ms ease-in-out;
  text-align: center;
  letter-spacing: -1.7px;
  color: #272627;
  border-radius: 50%;
  background-color: #e3ff7c;
}
#educationView .eduInfo .eduBt a .txt1 {
  position: absolute;
  top: 0;
  left: 0;
  padding: 14px;
}
#educationView .eduInfo .eduBt a .txt1 img {
  width: 100%;
  height: 100%;
}
#educationView .eduInfo .eduBt a .txt2 {
  position: absolute;
  bottom: 67px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding-top: 57px;
  background: url(../image/img_arr_bk3.png) no-repeat center top;
  background-size: 37px 44px;
}
#educationView .eduInfo .eduBt a:hover {
  transform: scale(1.1);
}
#educationView .eduInfo .eduBt a:active {
  background-color: #b5cd5d;
}
#educationView .eduCntsSec {
  flex: 1;
  width: 800px;
  margin-top: 233px;
  padding-top: 50px;
  border-top: 1px solid #272627;
}
#educationView .eduCntsSec .eduCnts .txt {
  font-size: 16px;
  line-height: 1.88;
  padding: 0 0 81px;
  letter-spacing: -0.8px;
  word-break: break-all;
  color: #272627;
  border-bottom: 1px solid #272627;
}
#educationView .eduCntsSec .eduCnts .txt img {
  max-width: 100%;
  vertical-align: top;
}
#educationView .eduCntsSec .eduTag {
  font-size: 0;
  margin-top: 20px;
}
#educationView .eduCntsSec .eduTag a {
  font-size: 15px;
  display: inline-block;
  margin: 0 8px 10px 0;
  padding: 8px 11px 8px 8px;
  letter-spacing: -0.75px;
  color: #272627;
  background-color: #e2ff7c;
}
#educationView .btSec {
  display: flex;
  justify-content: flex-end;
  padding-top: 59px !important;
  text-align: right;
}
#educationView .request-information-container {
  position: relative;
}

@media (min-width: 1440px) {
  .subHeader2 #content #educationView.cnts {
    padding-top: 338px;
  }
  .subHeader2.no-reservation-banner #content #educationView.cnts {
    padding-top: 286px;
  }
}

@media (max-width: 1439px) {
  #educationView {
    max-width: 800px !important;
  }
  #educationView .education-view-contents {
    display: block;
    gap: initial;
  }
  #educationView .eduInfo {
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
  }
  #educationView .eduInfo ul {
    padding-bottom: 30px;
  }
  #educationView .eduInfo .eduBt {
    position: absolute;
    top: -103px;
    right: 0;
    margin-top: 0;
  }
  #educationView .eduCntsSec {
    width: 100%;
    margin-top: 0 !important;
    margin-left: 0;
    padding-top: 50px;
  }
}

@media (max-width: 999px) {
  #confirmation {
    max-width: 100%;
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .pagenation {
    display: none;
  }
  .subHeader2 #subHeader h1 {
    left: 15px;
    width: 85px;
    height: 32px;
  }
  .subHeader2.no-reservation-banner #subHeader h1 {
    top: 25px !important;
  }
  .subHeader2:not(.no-reservation-banner) #subHeader h1 {
    top: 73px !important;
  }
  .subHeader2 #subHeader #subNav {
    right: 6px;
  }
  .subHeader2:not(.no-reservation-banner) #subHeader #subNav {
    top: 62px !important;
  }
  .subHeader2.no-reservation-banner #subHeader #subNav {
    top: 16px !important;
  }
  .subHeader2 #content .cnts.reservationCntsContainer {
    padding-top: 163px;
  }
  .subHeader2.no-reservation-banner #content .cnts.reservationCntsContainer {
    padding-top: 123px;
  }
  #content .cnts.reservationCntsContainer h2.sTit {
    font-size: 40px;
    letter-spacing: -2.03px;
  }
  #content .cnts.reservationCntsContainer h2.sTit .sTit2 {
    line-height: 1.2;
    padding: 10px 0 59px;
  }
  #educationView .eduInfo h2.sTit .sTit1 {
    font-size: 17px !important;
  }
  #educationView .eduInfo h2.sTit .sTit1 sub {
    font-size: 13px;
    margin: 0 0 0 5px;
    letter-spacing: -0.63px;
  }
  #educationView .eduInfo h2.sTit .sTit2 {
    font-size: 33px !important;
    line-height: 1 !important;
    padding: 7px 0 40px !important;
    letter-spacing: -1.63px;
  }
  #content #educationView.cnts h2.sTit .sTit2 .txt {
    font-size: 50px !important;
  }

  #content #education.cnts h2.sTit .sTit1 {
    font-size: 41px !important;
    letter-spacing: -2.03px;
  }
}

@media (max-width: 767px) {
  #educationView {
    padding-bottom: 220px !important;
  }
  #educationView .eduInfo .eduBt {
    top: -80px;
  }
  #educationView .eduInfo .eduBt a {
    width: 160px;
    height: 160px;
  }
  #educationView .eduInfo .eduBt a .txt2 {
    font-size: 27px;
    bottom: 52px;
    height: 32px;
    padding-top: 45px;
    letter-spacing: -1.33px;
    background-size: 29px 35px;
  }
  #educationView .eduInfo .eduBt a:hover .txt1 {
    padding: 14px;
  }
  #educationView .eduInfo .eduBt a:hover .txt2 {
    bottom: 52px;
    padding-top: 45px;
    background-size: 29px 35px;
  }
  #educationView .eduInfo .eduBt a:hover {
    width: 160px;
    height: 160px;
  }
  #educationView .eduCntsSec {
    width: 100%;
    padding-top: 40px;
  }
  #educationView .eduCntsSec .eduCnts .txt {
    padding: 0 0 30px;
  }
  #educationView .btSec {
    padding-top: 45px !important;
  }
  #education.cnts.reservationCntsContainer h2.sTit .sTit2 br {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .react-datepicker {
    padding: 40px 15px 32px;
  }
  .react-datepicker__month-name {
    margin-top: -2px;
  }
  .react-datepicker__day-names {
    margin-top: 23px;
  }
  .react-datepicker__header--custom--month {
    padding: 0;
  }
  .react-datepicker__day,
  .react-datepicker__day-name {
    width: 45px;
    height: 45px;
  }
  .react-datepicker__week {
    margin-top: 5px;
  }
  .react-datepicker__navigation--previous {
    background-image: url(../image/icon-left@2x.png);
  }
  .react-datepicker__navigation--next {
    background-image: url(../image/icon-right@2x.png);
  }
}

@media (max-width: 747px) {
  /* #subHeader .cnts {margin:0 6px 0 15px;} */
  #subHeader h1 {
    top: 25px;
    left: 15px;
    width: 85px;
    height: 32px;
  }
  #subHeader #lang {
    font-size: 12px;
    top: 29px;
    right: 82px;
  }
  #subHeader #subNav {
    right: 6px;
  }
  #content .cnts.reservationCntsContainer {
    padding-top: 118px;
  }
}

@media (max-width: 647px) {
  #educationView .eduInfo ul {
    padding-top: 29px;
    padding-bottom: 9px;
  }
  #educationView .eduInfo .eduBt {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    margin-top: 0;
    padding-bottom: 30px;
    text-align: center;
  }
  #educationView .eduInfo .eduBt a:hover {
    transform: initial;
  }
}

@media (max-width: 504px) {
  #educationView {
    padding-bottom: 284px !important;
  }
}

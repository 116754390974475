@import 'mixin';

.reservationContainer {
  position: relative;
  max-width: 700px;
  margin: 0 auto;
  @include media(contents-pc) {
    padding: 160px 0 100px;
  }
  @include media(contents-tablet) {
    box-sizing: border-box;
    padding: 127px 0 50px;
  }
  @include media(contents-mobile) {
    box-sizing: border-box;
    min-width: 360px;
    padding: 127px 15px 50px;
  }
}

.subTitleContainer {
  display: flex;
  @include media(contents-pc, contents-tablet) {
    margin-bottom: 30px;
  }
  @include media(contents-mobile) {
    margin-bottom: 20px;
  }
}

.subTitle {
  font-weight: 500;
  white-space: nowrap;
  color: #272627;
  @include media(contents-pc) {
    font-size: 28px;
    letter-spacing: -1.5px;
  }
  @include media(contents-tablet, contents-mobile) {
    font-size: 26px;
    letter-spacing: -1.3px;
  }
}

.subTitleSuperscript {
  font-weight: 500;
  color: #272627;
  @include media(contents-pc) {
    font-size: 14px;
    margin-left: 8px;
    letter-spacing: -0.7px;
  }
  @include media(contents-tablet, contents-mobile) {
    font-size: 12px;
    margin-left: 6px;
    letter-spacing: -0.6px;
  }
}

.required {
  &::before {
    display: inline-block;
    width: 5px;
    height: 5px;
    content: '';
    vertical-align: top;
    border-radius: 50%;
    background-color: #e65529;
  }
}

.datePickerWrapper {
  background-color: rgba(255, 255, 255, 0.5);
  @include media(contents-tablet) {
    margin: 0 -34px;
  }
  @include media(contents-mobile) {
    margin: 0 -15px;
  }
}

.dividingLine {
  height: 1px;
  margin: 50px 0;
  border: none;
  background-color: #afafaf;
  @include media(contents-tablet) {
    margin: 50px -34px;
  }
  @include media(contents-mobile) {
    margin: 50px -15px;
  }
}

.dividingMarginLine {
  height: 1px;
  margin: 50px 0;
  border: none;
  background-color: #afafaf;
}

.admissionContainer {
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  letter-spacing: -0.9px;
  color: #272627;
  background-color: rgba(255, 255, 255, 0.5);
  @include media(contents-tablet) {
    margin: 0 -34px;
  }
  @include media(contents-mobile) {
    margin: 0 -15px;
  }
}

.countButtonWrapper {
  width: 50px;
  height: 50px;
}

.timeList {
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  @include media(contents-pc, contents-tablet) {
    grid-template-columns: repeat(4, 1fr);
  }
  @include media(contents-mobile) {
    grid-template-columns: repeat(auto-fill, minmax(160px, auto));
  }
}

.timeItem {
  font-size: 18px;
  width: 100%;
  height: 50px;
  color: #272627;
  border: solid 1px #c7c8cc;
  background-color: transparent;

  &:hover,
  &:active {
    color: rgba(255, 255, 255, 0.5);
    background-color: #4e4d4e;
  }
  &.selected {
    color: #fff;
    background-color: #272627;
  }
  &:disabled {
    color: rgba(255, 255, 255, 0.5);
    background-color: #c0c0c1;
  }
}

.programSelectWrapper {
  @include media(contents-tablet) {
    margin: 0 -34px;
  }
  @include media(contents-mobile) {
    margin: 0 -15px;
  }
}

.footerButton {
  font-size: 16px;
  font-weight: 500;
  height: 50px;
  @include media(contents-pc, contents-tablet) {
    flex: 1 1 0;
  }
  @include media(contents-mobile) {
    flex: 1 1 auto;
  }
}

.footerButton.disabled {
  cursor: default;
  pointer-events: none;
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: #c0c0c1 !important;
}

.cancelButton {
  color: #fff;
  background-color: #9fa1a7;
  &:hover {
    color: #272627;
    background-color: #fff;
  }
}

.nextButton {
  color: #fff;
  background-color: #272627;
  &:hover {
    color: #272627;
    background-color: #ccc;
  }
}

.noteIcon {
  width: 33px;
  height: 32px;
  margin-right: 8px;
  background: url(~public/static/images/reservation/note_icon.png) no-repeat center / cover;
}

.visitInfoDividingLine {
  height: 4px;
  margin: 19px 0 30px;
  border: none;
  background: url(~public/static/images/reservation/history_line.png) no-repeat center / cover;
}

.visitInfoContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 29px;
}

.visitInfoTitle {
  font-weight: 500;
  margin-right: 23px;
  white-space: nowrap;
  letter-spacing: -1.2px;
  color: #272627;
  @include media(contents-pc) {
    font-size: 24px;
  }
  @include media(contents-tablet, contents-mobile) {
    font-size: 20px;
  }
}

.visitInfoTitleSuperscript {
  font-weight: 500;
  letter-spacing: -0.7px;
  @include media(contents-pc) {
    font-size: 14px;
    margin-left: 10px;
  }
  @include media(contents-tablet, contents-mobile) {
    font-size: 12px;
    margin-left: 8px;
  }
}

.visitInfoValue {
  font-weight: bold;
  color: #272627;
  @include media(contents-pc) {
    font-size: 28px;
    letter-spacing: -1.4px;
  }
  @include media(contents-tablet, contents-mobile) {
    font-size: 20px;
    letter-spacing: -1px;
  }
}

.requesterLine {
  margin: 30px 0 13px 0;
}

.goBackButtonWrapper {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-bottom: 38px;
}

.goBackButton {
  font-weight: 500;
  display: flex;
  align-items: center;
  letter-spacing: -1.2px;
  color: #747477;
  @include media(contents-pc, contents-tablet) {
    font-size: 24px;
  }
  @include media(contents-mobile) {
    font-size: 20px;
  }
  &:after {
    display: inline-block;
    content: '';
    border-radius: 50%;
    background: #747477 url(~public/static/images/reservation/go_back.png) no-repeat center / 23px 23px;
    @include media(contents-pc, contents-tablet) {
      width: 45px;
      height: 45px;
      margin-left: 24px;
    }
    @include media(contents-mobile) {
      width: 35px;
      height: 35px;
      margin-left: 17px;
    }
  }
  &:hover {
    &:after {
      background-color: #9fa1a7;
    }
  }
}

.lineBreak {
  display: block;
  height: 1px;
  border: none;
  @include media(contents-pc) {
    margin: 30px 0;
  }
  @include media(contents-tablet, contents-mobile) {
    margin: 20px 0;
  }
}

.inputForm {
  font-size: 15px;
  width: 100%;
  padding-bottom: 16px !important;
  color: #272627;
  border: none;
  border-bottom: 1px solid #afafaf !important;
  outline: 0;
  background: transparent;
  &::placeholder {
    font-family: 'NEXONLv2GothicRegular', sans-serif;
    font-size: 15px;
    letter-spacing: -0.75px;
    color: rgba(39, 38, 39, 0.5);
  }
  &:disabled {
    opacity: 0.5;
  }
}

.textAreaForm {
  font-size: 15px;
  overflow: auto;
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  padding: 10px;
  resize: none;
  border: 1px solid #afafaf;
  background-color: transparent;
  &:disabled {
    opacity: 0.5;
    border: solid 1px #afafaf;
    background-color: #e0e0e0;
  }
}

.emailFormContainer {
  display: flex;
  @include media(contents-pc, contents-tablet) {
    flex-direction: row;
  }
  @include media(contents-mobile) {
    flex-direction: column;
  }
}

.emailForm {
  display: flex;
  flex: 1 1 auto;
}

.emailInput {
  flex: 1 1 auto;
}

.at {
  font-size: 17px;
  font-weight: 500;
  margin: 10px 10px 0;
  letter-spacing: -0.85px;
  color: #17191d;
}

.smallInputForm {
  @include media(contents-pc, contents-tablet) {
    width: 218px;
  }
  @include media(contents-mobile) {
    width: 165px;
  }
}

.leaderInputDescription {
  font-family: NEXONLv2GothicRegular, sans-serif;
  font-size: 14px;
  margin-top: 11px;
  color: #9fa1a7;
  &::before {
    display: inline-block;
    width: 4px;
    height: 4px;
    margin: 0 5px 3px 0;
    content: '';
    vertical-align: middle;
    border-radius: 50%;
    background-color: #9fa1a7;
  }
}

.transportInput {
  display: inline-block;
  @include media(contents-pc, contents-tablet) {
    margin-left: 10px;
  }
  @include media(contents-mobile) {
    margin-top: 26px;
  }
}

.unit {
  font-size: 17px;
  font-weight: 500;
  letter-spacing: -0.85px;
  color: #272627;
}

.dropdownWrapper {
  background-color: #f5f5f5;
  @include media(contents-pc, contents-tablet) {
    display: inline-block;
    width: 349px;
  }
}

.emailDropdownWrapper {
  @include media(contents-pc, contents-tablet) {
    flex: 0 0 221px;
    width: 221px;
    margin-left: 8px;
  }
  @include media(contents-mobile) {
    margin-top: 20px;
  }
}

.checkList {
  padding-bottom: 30px;
  border-bottom: 1px solid #c7c8cc;
}

.checkList li:nth-of-type(1) {
  padding-bottom: 14px;
  border-bottom: 1px solid #c7c8cc;
}

.checkItem {
  font-size: 15px;
  font-weight: 500;
  position: relative;
  margin-top: 15px;
  letter-spacing: -0.75px;
  color: #272627;
}

.optionalCheck {
  color: #9fa1a7;
}

.couponInputContainer {
  display: flex;
  .inputForm {
    margin-right: 10px;
  }
  .button {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    padding: 17px 0;
    color: #fff;
    background-color: #272627;
    @include media(contents-pc, contents-tablet) {
      flex: 0 0 180px;
    }
    @include media(contents-mobile) {
      width: 23vw;
      min-width: 80px;
    }
    &:hover {
      color: #272627;
      background-color: #ccc;
    }
    &:disabled {
      pointer-events: none;
      color: rgba(255, 255, 255, 0.5);
      background-color: #c0c0c1;
    }
  }
}

.coupon {
  position: relative;
  z-index: 1;
  display: flex;
  box-sizing: border-box;
  cursor: pointer;
  background-image: url(~public/static/images/reservation/coupon-set.svg);
  background-repeat: no-repeat;
  @include media(contents-pc, contents-tablet) {
    align-items: center;
    justify-content: space-between;
    width: 700px;
    height: 152px;
    margin: 30px 0 60px;
    padding: 43px 130px 43px 179px;
    background-position: 0 -400px;
    &.appliedCoupon {
      background-position: 0 -600px;
    }
    @media (hover: hover) {
      &:hover {
        background-position: 0 -600px;
      }
    }
  }
  @include media(contents-mobile) {
    flex-direction: column;
    justify-content: center;
    width: 330px;
    height: 130px;
    margin: 20px auto 40px;
    padding: 13px 0 21px 40px;
    background-position: 0 0;
    &.appliedCoupon {
      background-position: 0 -200px;
    }
    @media (hover: hover) {
      &:hover {
        background-position: 0 -200px;
      }
    }
  }
}

.couponPrice {
  font-weight: bold;
  position: relative;
  width: fit-content;
  letter-spacing: -1.5px;
  color: #272627;
  @include media(contents-pc, contents-tablet) {
    font-size: 30px;
  }
  @include media(contents-mobile) {
    font-size: 22px;
  }
}

.couponDescription {
  font-weight: 500;
  margin-top: 6px;
  letter-spacing: -0.9px;
  color: #9fa1a7;
  @include media(contents-pc, contents-tablet) {
    font-size: 18px;
  }
  @include media(contents-mobile) {
    font-size: 15px;
  }
}

.couponDate {
  font-weight: bold;
  color: #272627;
  @include media(contents-pc, contents-tablet) {
    font-size: 18px;
    letter-spacing: -0.9px;
  }
  @include media(contents-mobile) {
    font-size: 15px;
    margin-top: 16px;
    letter-spacing: -0.75px;
  }
}

// 예약 상세

.infoTitle {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #272627;
  @include media(contents-pc) {
    font-size: 50px;
    margin-bottom: 71px;
    letter-spacing: -2.5px;
  }
  @include media(contents-tablet, contents-mobile) {
    font-size: 40px;
    margin-bottom: 42px;
    letter-spacing: -2px;
  }
  @include media(contents-mobile) {
    line-height: 48px;
    justify-content: start;
  }
  &::after {
    display: inline-block;
    content: '';
    background: url(~public/static/images/reservation/smile.png) no-repeat center / cover;
    @include media(contents-pc, contents-tablet) {
      width: 37px;
      height: 56px;
      margin-left: 24px;
    }
    @include media(contents-mobile) {
      align-self: flex-end;
      width: 29px;
      height: 43px;
      margin-left: 14px;
    }
  }
}

.infoAnnounce {
  font-weight: 500;
  line-height: 1.4;
  position: relative;
  text-align: center;
  letter-spacing: -1px;
  word-break: keep-all;
  color: #ffffff;
  background-color: #272627;
  &::before,
  &::after {
    position: absolute;
    display: inline-block;
    width: 12px;
    height: 12px;
    content: '';
    background-color: #eaeaea;
  }
  &::before {
    top: 0;
    left: 0;
  }
  &::after {
    right: 0;
    bottom: 0;
  }

  @include media(contents-pc, contents-tablet) {
    font-size: 20px;
    margin-bottom: 70px;
    padding: 30px 0;
  }
  @include media(contents-mobile) {
    font-size: 16px;
    margin-bottom: 50px;
    padding: 23px 0 25px;
  }
  a {
    color: #e2ff7c;
    &:hover {
      text-decoration: underline;
      color: #e2ff7c;
    }
  }
}

.breakMobile {
  @include media(contents-pc, contents-tablet) {
    display: none;
  }
}

.requesterInfoTitle {
  font-weight: 500;
  color: #272627;
}

.requesterInfoContent {
  font-weight: 500;
  word-break: break-all;
  color: #272627;
}

.requesterInfoTitle,
.requesterInfoContent {
  @include media(contents-pc) {
    font-size: 22px;
    letter-spacing: -1.1px;
  }
  @include media(contents-tablet, contents-mobile) {
    font-size: 18px;
    letter-spacing: -0.9px;
  }
}

.requirementContainer {
  align-items: start;
  flex-direction: column;
  .requesterInfoContent {
    margin-top: 14px;
    @include media(contents-pc) {
      line-height: 30px;
    }
    @include media(contents-tablet, contents-mobile) {
      line-height: 26px;
    }
  }
}

.programContainer {
  @include media(contents-pc, contents-tablet) {
    align-items: baseline;
  }
}

.programItem {
  @include media(contents-pc, contents-tablet) {
    text-align: right;
    & + & {
      margin-top: 20px;
    }
  }
  @include media(contents-mobile) {
    & + & {
      margin-top: 12px;
    }
  }
}

.listButtonWrapper {
  float: right;
  @include media(contents-pc) {
    margin-top: 74px;
    margin-bottom: 100px;
  }
  @include media(contents-tablet, contents-mobile) {
    margin: 50px 0;
  }
}

.ticketList {
  margin-top: 20px;
}

.ticketItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  letter-spacing: -1.1px;
  color: #747477;
  @include media(contents-pc) {
    font-size: 18px;
  }
  @include media(contents-tablet, contents-mobile) {
    font-size: 16px;
  }
  & + & {
    margin-top: 10px;
  }
}

.name {
  overflow: hidden;
  min-width: 0;
  margin-right: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}

.ticketDiscountItem {
  color: #ff6060;
}

.ticketContent {
  font-weight: bold;
  white-space: nowrap;
}

.priceItem {
  font-weight: bold;
  letter-spacing: -1.2px;
  color: #272627;
  @include media(contents-pc) {
    font-size: 24px;
    line-height: 24px;
    margin: 30px 0 53px !important;
  }
  @include media(contents-tablet, contents-mobile) {
    font-size: 20px;
    line-height: 20px;
    margin: 30px 0 !important;
  }
}

.subPriceItem {
  font-weight: bold;
  color: #272627;
  @include media(contents-pc) {
    font-size: 22px;
    margin: 29px 0;
  }
  @include media(contents-tablet, contents-mobile) {
    font-size: 18px;
    margin: 20px 0;
  }
}

.highlighter {
  position: relative;
  &::before {
    position: absolute;
    z-index: -1;
    bottom: 0;
    display: inline-block;
    width: 100%;
    height: 16px;
    content: '';
    background-color: #e2ff7c;
  }
}

.totalPrice {
  strong {
    @include media(contents-pc) {
      font-size: 32px;
    }
    @include media(contents-tablet, contents-mobile) {
      font-size: 26px;
    }
  }
}

.cancelContainer {
  opacity: 0.5;
}

.cancelItem {
  text-decoration: line-through;
  text-decoration-thickness: 3px;
}

.refundInfo {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  padding: 0 24px 24px;
  background-color: #f5f5f5;
}

.refundInfoTitle {
  font-size: 18px;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 17px;
  letter-spacing: -0.9px;
  color: #272627;
}

.refundInfoItem {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  position: relative;
  display: flex;
  padding-left: 8px;
  letter-spacing: -0.7px;
  color: #9fa1a7;
  &::before {
    position: absolute;
    top: 10px;
    left: 0;
    display: inline-block;
    flex: 0 0 4px;
    width: 4px;
    height: 4px;
    content: '';
    border-radius: 50%;
    background-color: #9fa1a7;
  }
  & > strong {
    font-weight: bold;
    color: #ff6060;
  }
}

// 예약 수정

.editInfoContainer {
  display: flex;
  margin-top: 29px;
  @include media(contents-pc) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  @include media(contents-tablet, contents-mobile) {
    flex-direction: column;
    .visitInfoValue {
      margin-top: 14px;
    }
  }
}

.editDropdownContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.visitInfoContainer + .editDropdownContainer {
  @include media(contents-pc) {
    margin-top: 25px;
  }
  @include media(contents-tablet, contents-mobile) {
    margin-top: 16px;
  }
}

.editDropdownWrapper {
  background-color: #f5f5f5;
  @include media(contents-pc, contents-tablet) {
    display: inline-block;
    width: 315px;
  }
  @include media(contents-mobile) {
    display: inline-block;
    flex: 1 1 0;
    max-width: 315px;
  }
}

.rightArrow {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 0 8px;
  background: url(~public/static/images/reservation/right_arrow_small.png) no-repeat center / cover;
}

.editProgramWrapper {
  display: flex;
  justify-content: space-between;
  margin: 19px 0 13px;
  @include media(contents-pc) {
    font-size: 18px;
  }
  @include media(contents-tablet, contents-mobile) {
    font-size: 16px;
  }
}

.editProgramName {
  color: #272627;
}

.editProgramCount {
  color: #747477;
}

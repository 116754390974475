@charset "utf-8";
/* CSS Document */

.programBody {
  max-width: 1280px;
  margin: 0 auto;
  color: #272627;
  padding-right: 32px;
  padding-left: 32px;
}

.programBody h2.sTit {
  font-size: 61px;
  font-weight: 500;
  letter-spacing: -3.05px;
}
.programBody h2.sTit span {
  font-weight: 700;
}

.inquiry_text {
  width: 100%;
  color: #fff;
  font-size: 17px;
  font-weight: 400;
  background-color: #272627;
  background-image: url('../image/sub/icon_care.png');
  background-position: 28px center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  margin: 51px 0 160px 0;
  padding: 17px 0 18px 60px;
  box-sizing: border-box;
}
.inquiry_text span {
  font-weight: 700;
}

/*tooltip Box*/ /*999px이하 부터*/
.inquiry_text_tooltip {
  display: none;
  position: absolute;
  left: 262px;
  width: 20px;
  height: 20px;
  margin-top: -26px;
}
.inquiry_text_tooltip .tooltip {
  z-index: 1;
  width: max-content;
  padding: 13px 12px 10px 15px;
  background: #272627;
  color: #fff;
  position: absolute;
  top: -60px;
  left: -15px;
  font-size: 13px;
  text-align: center;
  letter-spacing: -0.63px;
}
.inquiry_text_tooltip .tooltip a {
  padding-left: 20px;
  vertical-align: middle;
}
.inquiry_text_tooltip .tooltip::after {
  content: ' ';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px;
  border-color: #272627 transparent transparent transparent;
  position: absolute;
  top: 100%;
  left: 15px;
}

/*.programList{*/
/*	width: 100%;*/
/*}*/

/*.programList_content{*/
/*	width: 100%;*/
/*}*/

.programList_content .monthlyTitle {
  width: 100%;
  max-width: 311px;
  position: sticky;
  top: 206px;
}
.programList_content .monthlyTitle h5 {
  width: 100%;
  padding-bottom: 7px;
  margin-bottom: 21px;
  font-size: 24px;
  font-family: 'kepler-std-display', serif !important;
  font-weight: 400;
  font-style: normal;
  border-bottom: 1px solid #909090;
}
.programList_content .monthlyTitle li:last-child {
  width: 137px;
  height: 137px;
  background-image: url('../image/sub/icon_arrow_one_white.png');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 137px 137px;
  margin-top: 40px;
}
.programList_content .monthlyTitle .monthlyTitle_number {
  font-size: 108px;
  font-family: 'NEXONLv2GothicRegular', sans-serif;
}
.programList_content .monthlyTitle .monthlyTitle_number span {
  margin-left: 8px;
  padding-bottom: 2px;
  font-size: 17px;
  font-family: 'Noto Sans KR', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.85px;
  border-bottom: 1px solid #272627;
}
.programList_content .monthlyTitle .monthlyTitle_number img {
  width: 137px;
  height: 137px;
  margin-top: 44px;
}
.programList_content .monthlyInformation {
  border-top: 1px solid #909090;
  margin-left: 441px;
  margin-top: -307px;
  padding-top: 59px;
}
.programList_content .monthlyInformation dl {
  display: flex;
  gap: 50px;
  margin-bottom: 110px;
  min-height: 212px;
}

.programList_content .monthlyInformation dt {
  width: 373px;
  height: 210px;

  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  z-index: -1;
}
.programList_content .monthlyInformation p {
  position: absolute;
  top: -20px;
  width: max-content;
  max-width: 288px;
  padding: 0 31px 14px 20px;
  margin-bottom: -20px;
  font-size: 33px;
  font-weight: 500;
  background-image: url('../image/sub/icon-brace-left.svg'), url('../image/sub/icon-brace-right.svg');
  background-color: #eaeaea;
  background-position: left 1px, calc(100% - 12px) 1px;
  background-size: 17px 37px;
  background-repeat: no-repeat;
  box-sizing: border-box;
  word-break: break-all;
  line-height: 1.1;
  letter-spacing: -1.7px;
}

.programList_content .monthlyInformation dt .ongoingIcon {
  width: 39px;
  height: 39px;
  background-image: url('../image/sub/icon_robot_bg.png');
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  right: 0;
}

.programList_content .monthlyInformation dd {
  flex: 1;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.8px;
}
.programList_content .monthlyInformation dd li:first-child {
  margin-bottom: 32px;
  line-height: 1.63;
  word-break: break-word;
}
.programList_content .monthlyInformation_list {
  /*width: 100%;*/
}

.time_text,
.location_text,
.ticket_text,
.audio_text,
.game_text {
  width: calc(100% - 30px);
  padding-left: 30px;
  margin-bottom: 13px;
  line-height: 1;
  background-position: left -1px;
  background-repeat: no-repeat;
  background-size: 19px 19px;
}
.time_text {
  background-image: url('../image/sub/icon-monthly-program-time.svg');
}
.location_text {
  background-image: url('../image/sub/icon-monthly-program-location.svg');
}
.ticket_text {
  background-image: url('../image/sub/icon-monthly-program-ticket.svg');
}
.audio_text {
  background-image: url('../image/sub/icon-monthly-program-headphone.svg');
}
.game_text {
  background-image: url('../image/sub/icon-monthly-program-gamepad.svg');
}

.tag_text {
  margin-top: 22px;
}
.tag_text a {
  width: auto;
  padding: 8px;
  padding-top: 7px;
  margin-top: 8px;
  margin-right: 8px;
  font-size: 15px;
  font-weight: 400;
  background-color: #e2ff7c;
  display: inline-block;
}

.tag_text a:first-of-type {
  margin-top: 0;
}

/*전달에 대한 스타일 class*/
.monthlyTitle.grayColor {
  color: #909090;
}
.monthlyTitle.grayColor span {
  border-color: #909090 !important;
}
.monthlyTitle.grayColor li:last-child {
  background-image: url('../image/sub/icon_arrow_one_gray.png');
}
.secondMonthBG {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: rgb(25, 25, 25);
  mix-blend-mode: hue;
  position: absolute;
  z-index: 1;
}

/*지난 프로그램 확인 버튼*/
.checkBut {
  width: calc(100% - 441px);
  display: inline-flex;
  justify-content: center;
  margin-top: -33px;
  margin-bottom: 160px;
  margin-left: 441px;
}
.checkBut button {
  width: 241px;
  height: 50px;
  color: #fff;
  text-align: center;
  background-color: #272627;
  font-size: 16px;
  font-weight: 400;
}

/*현재 달  class="programList_content" 에서만 사용*/
.Tstyle {
  border-top: none !important;
}

@media all and (max-width: 1600px) {
  /*.programBody{*/
  /*	width: 1280px;*/
  /*}*/
  .time_text,
  .location_text,
  .ticket_text,
  .audio_text,
  .game_text {
    line-height: 1.5;
    margin-bottom: 5px;
    background-position: left 4px;
  }
}
@media all and (max-width: 1439px) {
  /*.programBody{*/
  /*	width: 840px;*/
  /*}*/
  .inquiry_text {
    margin: 51px 0 62px 0;
  }
  .programList_content {
    padding-top: 30px;
    border-top: 1px solid #909090;
  }
  .programList_content .monthlyTitle {
    max-width: 100%;
    position: relative;
    top: initial;
  }
  .programList_content .monthlyTitle h5 {
    display: none;
  }
  .programList_content .monthlyTitle .monthlyTitle_number li {
    display: inline-block;
    vertical-align: text-top;
    /*margin-left: -6px;*/
  }
  .programList_content .monthlyTitle li:last-child {
    width: 95px;
    height: 95px;
    margin-top: 0;
    margin-left: 186px;
    background-size: 95px 95px;
    background-position: right top;
  }
  .programList_content .monthlyInformation {
    /*width: 100%;*/
    margin-left: 0;
    margin-top: 70px;
    padding-top: 0;
    border-top: none;
  }
  .checkBut {
    width: 100%;
    margin-left: 0;
  }
}

@media all and (max-width: 999px) {
  .subHeader2 #content .cnts {
    padding-top: 163px;
  }
  .subHeader2.no-reservation-banner #content .cnts {
    padding-top: 123px;
  }
  .programBody {
    /*position: relative;*/
    padding-left: 15px;
    padding-right: 15px;
  }
  .programBody h2.sTit {
    font-size: 39px;
  }
  .programList_content .monthlyTitle .monthlyTitle_number {
    font-size: 87px;
  }

  .programList_content .monthlyTitle .monthlyTitle_number span {
    font-size: 13px;
  }
  .programList_content .monthlyTitle li:last-child {
    width: 67px;
    height: 67px;
    background-size: 67px 67px;
    margin-left: 108px;
  }
  .inquiry_text {
    display: none;
  }
  .inquiry_text_tooltip {
    display: block;
  }
  .programList_content {
    margin-top: 52px;
  }
  .programList_content .monthlyInformation {
    margin-top: 46px;
  }
  .programList_content .monthlyInformation dl {
    margin-bottom: 80px;
    gap: 40px;
  }

  .programList_content .monthlyInformation dt {
    width: 352px;
    height: 197px;
  }

  .programList_content .monthlyInformation p {
    font-size: 30px;
    background-size: 16px 36px;
  }
  .programList_content .monthlyInformation dd {
    /*width: calc(100% - 340px);*/
    /*max-width: 400px;*/
    /*margin-top: -220px;*/
    /*margin-left: 340px;*/
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.8px;
  }
  .programList_content .monthlyInformation dd li:first-child {
    margin-bottom: 20px;
  }
  .programList_content .monthlyInformation dd br {
    display: none;
  }
  .time_text,
  .location_text,
  .ticket_text,
  .audio_text,
  .game_text {
    width: calc(100% - 26px);
    padding-left: 26px;
    font-size: 14px;
  }
  .tag_text {
    margin-top: 18px;
  }
  .tag_text a {
    font-size: 13px;
  }
  .checkBut {
    margin-bottom: 100px;
  }
}
@media all and (max-width: 599px) {
  .inquiry_text_tooltip .tooltip {
    top: 40px;
    left: -221px;
  }
  .inquiry_text_tooltip .tooltip::after {
    border-color: transparent transparent #272627 transparent;
    top: -20px;
    left: 221px;
  }
  .Tstyle {
    padding-top: 72px;
  }
  .programList_content {
    padding-bottom: 28px;
    /*width: 100%;*/
    /*max-width: 320px;*/
    margin: 0 auto;
  }
  .programList_content .monthlyTitle li:last-child {
    display: none;
  }
  .programList_content .monthlyInformation_list {
    padding-top: 0;
  }
  .programList_content .monthlyInformation_list > ul > li {
    margin-bottom: 42px;
  }

  .monthlyInformation_list .tag_text {
    margin-top: 24px;
  }

  .programList_content .monthlyInformation {
    margin-top: 20px;
  }
  .programList_content .monthlyInformation dl {
    display: block;
    margin-bottom: 0;
  }
  .programList_content .monthlyInformation dt {
    width: 100%;
    height: 100%;
    padding-bottom: 56.36%;
  }
  .programList_content .monthlyInformation p {
    padding: 13px 28px 0 38px;
    background-position: 20px bottom, calc(100% - 9px) bottom;
    bottom: 0;
    top: auto;
  }
  .programList_content .monthlyInformation dt .ongoingIcon {
    top: 0;
  }
  .programList_content .monthlyInformation dd {
    margin-left: 0;
    margin-top: 55px;
  }
  .programList_content .monthlyInformation dd li:first-child {
    margin-bottom: 24px;
  }
  .tag_text {
    margin-top: 0;
  }
}

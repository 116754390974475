@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.mainVideoContainer {
  z-index: 10;
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-delay: 1.5s;
  animation-iteration-count: 1;
  opacity: 0;
  background-color: #000;
  animation-fill-mode: forwards;

  &::before {
    display: block;
    padding-bottom: 42.5%;
    content: '';
  }
}

.videoListItem {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.selectedVideo {
  display: block;
}

.videoIframe {
  width: 100%;
  height: 100%;
}

.dotList {
  position: absolute;
  top: -8px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 4px;
}

.dotListItem {
  width: 4px;
  height: 4px;
  background-color: #9fa1a7;
}

.selectedDot {
  background-color: #fff;
}

.videoController {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.videoControllerButton {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}

.videoControllerPlayButton {
  overflow: hidden;
  width: 60px;
  height: 60px;
  letter-spacing: -0.6px;
  border: 1px solid #fff;
  border-radius: 100%;
}

.playText {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigationWrapper {
  position: absolute;
  bottom: 6vw;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 0.7px;
  padding: 0 4vw;
}

.navigationBar {
  position: relative;
  display: block;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

.navigationValue {
  position: absolute;
  display: inline-block;
  height: 1px;
  padding: inherit;
  background-color: #fff;
}

@import 'style/mixin';

@keyframes marquee {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-20%, 0, 0);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes reverseRotate {
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes blinkIcon {
  0% {
    background-image: url(~public/static/images/main/b-collection/illust-icon-first.svg);
  }
  50% {
    background-image: url(~public/static/images/main/b-collection/illust-icon-second.svg);
  }
}

.container {
  width: 100vw;
  padding-bottom: 111px;

  @include media(main-pc-medium) {
    padding-bottom: 15vw;
    padding-top: 10.3125vw;
  }
  @include media(main-pc-small) {
    padding-bottom: 120px;
    padding-top: 132px;
  }
  @include media(main-tablet) {
    padding-bottom: 219px;
    padding-top: 152px;
  }
  @include media(main-mobile) {
    padding-bottom: 134px;
    padding-top: 154px;
  }
}

.logoContainer {
  display: flex;
  justify-content: center;

  @include media(main-pc-medium, main-pc-small, main-tablet) {
    margin-bottom: 43px;
  }
  @include media(main-mobile) {
    margin-bottom: 28px;
  }
}

.logoAssets {
  position: relative;
  @include media(main-pc-medium) {
    width: 10.8vw;
    height: 10.8vw;
  }
  @include media(main-pc-small) {
    width: 139px;
    height: 139px;
  }
  @include media(main-tablet) {
    width: 139px;
    height: 139px;
  }
  @include media(main-mobile) {
    width: max(100px, calc(100px + ((100vw - 360px) / 10.46)));
    height: max(100px, calc(100px + ((100vw - 360px) / 10.46)));
  }
}

.rotatingLogo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation: rotate 15s linear infinite;
  background-image: url(~public/static/images/main/b-collection/rotate-circle-icon.svg);
  background-size: contain;
}

.illustLogo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation: blinkIcon 2s step-start infinite;
  background-image: url(~public/static/images/main/b-collection/illust-icon-first.svg);
  background-size: contain;
}

.collectionList {
  overflow: hidden;
  border-top: 1px solid #aaaaab;
}

.collectionListItem {
  font-weight: normal;
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 100vw;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  color: #282728;
  border-bottom: 1px solid #aaaaab;

  @include media(main-pc-medium) {
    font-size: 4.16vw;
    height: 8.3vw;
  }

  @include media(main-pc-small) {
    font-size: 52px;
    height: 106px;
  }

  @include media(main-tablet) {
    font-size: 48px;
    height: 105px;
  }

  @include media(main-mobile) {
    font-size: 32px;
    height: getResponsiveRule(360, 720, 63, 105);
  }
}

.collectionBase {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.collectionName {
  font-weight: normal;
  letter-spacing: -0.88px;

  @include media(main-mobile) {
    font-size: getResponsiveRule(360, 768, 22, 48, false);
  }
}

.collectionSup {
  font-size: 12px;
  margin-left: 8px;
  letter-spacing: -0.24px;

  @include media(main-mobile) {
    font-size: getResponsiveRule(360, 768, 8, 12);
  }
}

.collectionHover {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 101%, 0);
}

.bannerWrapper {
  width: 100%;
  height: 100%;
}

.bannerContainer {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: fit-content;
  height: 100%;
  animation-name: marquee;
  animation-duration: 10s;
  animation-play-state: paused;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  white-space: nowrap;

  @at-root .on-marquee & {
    animation-play-state: running;
  }
}

.banner {
  display: flex;
  align-items: center;
  @include media(main-pc-medium) {
    height: 6vw;
    margin-right: 1.875vw;
  }
  @include media(main-pc-small) {
    height: 76px;
    margin-right: 24px;
  }
  @include media(main-tablet) {
    height: 72px;
    margin-right: 22.7px;
  }
  @include media(main-mobile) {
    height: getResponsiveRule(360, 720, 33.6, 75);
    margin-right: 15.2px;
  }
}

.bannerImage {
  height: 100%;
}

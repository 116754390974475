@charset "utf-8";

html,
body {
  max-width: 100%;
  min-height: 100vh;
}
#__next {
  min-height: 100vh;
}
#subHeader h1.subTit {
  position: fixed;
  z-index: 10;
  top: 40px;
  left: 40px;
  width: 116px;
  height: 44px;
  background: url(../image/ncm-logo-black.svg) no-repeat center center;
  background-size: cover;
}
.sub-contents #subHeader h1.subTit a {
  display: block;
  width: 100%;
  height: 100%;
  background: none;
}
#subHeader #lang {
  font-size: 15px;
  line-height: 1.79;
  position: fixed;
  z-index: 10;
  top: 40px;
  right: 126px;
  letter-spacing: -0.73px;
  color: #272627;
}
.subHeader2:not(.no-reservation-banner) h1.subTit,
.subHeader2 #lang {
  top: 88px !important;
}

.container {
  width: 100%;
}
/*#content {min-height:100vh;}*/
#content .cnts {
  padding-top: 163px;
}
.subHeader2 #content .cnts {
  padding-top: 211px;
}
.subHeader2.no-reservation-banner #content .cnts {
  padding-top: 171px;
}
.layer {
  position: fixed;
  z-index: 6000;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 390px;
}
.layer .layerCnts {
  font-weight: 500;
  position: relative;
  margin: 0 15px;
  padding: 30px;
  text-align: center;
  background-color: #ffffff;
}
.layer .layerCnts .txt {
  font-size: 20px;
  font-weight: bold;
}
.layer .layerCnts .cnts {
  font-size: 16px;
  display: table;
  width: 100%;
  padding: 24px 0 29px;
  vertical-align: middle;
}
.layer .layerCnts .cnts p {
  line-height: 1.3;
  display: table-cell;
  height: 132px;
  min-height: 132px;
  vertical-align: middle;
}
.layer .layerCnts .bt {
  font-size: 18px;
}
.layer .layerBt .bt2:hover {
  background-color: #e4e5e6 !important;
}
.layer .layerBt::after {
  display: table;
  clear: both;
  content: '';
}
.layer .layerBt2 span {
  float: left;
  width: calc(50% - 5px);
  vertical-align: top;
}
.layer .layerBt2 span:nth-child(2) {
  float: right;
}

@media (max-width: 1279px) {
}

@media (max-width: 699px) {
  #subHeader h1.subTit {
    top: 25px;
    left: 15px;
    width: 85px;
    height: 32px;
  }
  #subHeader #lang {
    font-size: 12px;
    top: 29px;
    right: 82px;
  }
  #subHeader #subNav {
    top: 16px;
    right: 6px;
  }
  .subHeader2:not(.no-reservation-banner) h1.subTit,
  .subHeader2 #lang {
    top: 76px !important;
  }
  .subHeader2:not(.no-reservation-banner) #subNav {
    top: 62px !important;
  }
  .subHeader2 #content .cnts {
    padding-top: 163px;
  }
  .subHeader2.no-reservation-banner #content .cnts {
    padding-top: 123px;
  }
}

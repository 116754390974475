.newsBody {
  font-family: 'Noto Sans KR', sans-serif !important;
  font-style: normal;
  box-sizing: border-box;
  max-width: calc(1280px + 32px + 32px);
  height: 100%;
  margin: 0 auto;
  color: #272627;
}

.newsBody h2.sTit {
  font-size: 61px;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: -3.05px;
}
.newsBody h2.sTit span {
  font-weight: 700;
}

.newsContent {
  width: 100%;
  padding-top: 100px;
}
.newsContent .newsContent_tap {
  overflow: scroll;
  width: 100%;
  height: 64px;
  margin-bottom: 40px;
}
.newsContent .newsContent_tap button {
  font-size: 28px;
  font-weight: 400;
  position: relative;
  float: left;
  width: auto !important;
  margin-right: 48px;
  padding-bottom: 10px;
  letter-spacing: -1.4px;
  color: #5a5a5a;
}

.newsContent .newsContent_tap button:hover {
  color: #272627;
  background-color: #e3ff7c;
}

.newsContent .newsContent_tap button:hover::after,
.newsContent .newsContent_tap .selectedBut::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  border-bottom: 3px solid #272627;
}

.newsContent .newsContent_tap .selectedBut {
  font-weight: 700;
  color: #272627;
  background-color: #e3ff7c;
}
.newsContent .newsContent_tap button:last-child {
  margin-right: 0;
}
.newsContent .newsContent_tap dl {
  text-align: left;
}
.newsContent .newsContent_tap dt {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.55px;
}

.newsContent .newsContent_tap dd {
  margin-top: -2px;
}

.announcementContent {
  width: 100%;
  max-width: 1280px;
  margin-top: 40px;
}

.announcementContent_list ul {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(3, minmax(0, 360px));
  grid-gap: 39px;
}

.announcementContent_list li {
  display: flex;
  align-items: center;
}

.announcementContent_list .aC_listBody {
  display: block;
  flex: 1;
  margin: 80px 0;
}

.announcementContent_list img {
  width: 100%;
  /*height: auto;*/
}

.announcementContent_list dl {
  /*width: 100%;*/
  margin-top: 10px;
}
.announcementContent_list dt {
  font-size: 13px;
  font-weight: 500;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid #909090;
}
.announcementContent_list dd {
  font-size: 21px;
  font-weight: 500;
  line-height: 1.62;
  padding: 15px 0;
  text-align: left;
  letter-spacing: -1.05px;
  word-break: break-all;
}
.announcementContent_list dd:last-child {
  padding: 0;
}
.announcementContent_list dd button {
  font-size: 21px;
  font-weight: 700;
  line-height: 1.4;
  display: flex;
  align-items: center;
  width: max-content;
  height: 41px;
  text-align: left;
  letter-spacing: -1.05px;
  color: #272627;
  border-bottom: 1px solid transparent;
}
.announcementContent_list dd button:before {
  display: inline-block;
  width: 37px;
  height: 37px;
  margin-right: 12px;
  content: '';
  background-image: url('../image/sub/icon_arrow_right.png');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
}
.announcementContent_list dd button:after {
  display: none;
}
.announcementContent_list li:hover .detail_button {
  background-size: 100% 100%;
}
.announcementContent_list dd button:focus {
  /*border-bottom: 1px solid #272627;*/
  /*background-color: #e3ff7c;*/
}

.pagenation {
  font-size: 0;
  position: relative;
  margin: 40px 0 100px;
  text-align: center;
}
.pagenation a {
  font-size: 17px;
  display: inline-block;
  min-width: 35px;
  height: 26px;
  padding-top: 9px;
  vertical-align: middle;
  color: #909090;
}
.pagenation a:hover {
  color: #909090;
  background-color: #d9d9d9;
}
.pagenation a.current {
  color: #ffffff;
  background-color: #272627;
}
.pagenation a.first,
.pagenation a.prev,
.pagenation a.next,
.pagenation a.last {
  background: url(../image/img_pagenation.png) no-repeat left top;
}
.pagenation a.first {
  background-position: -58px -8px;
}
.pagenation a.first:hover {
  background-position: -58px -58px;
}
.pagenation a.first:active {
  background-position: -58px -108px;
}
.pagenation a.prev {
  margin-right: 6px;
  background-position: -8px -8px;
}
.pagenation a.prev:hover {
  background-position: -8px -58px;
}
.pagenation a.prev:active {
  background-position: -8px -108px;
}
.pagenation a.next {
  margin-left: 6px;
  background-position: -158px -8px;
}
.pagenation a.next:hover {
  background-position: -158px -58px;
}
.pagenation a.next:active {
  background-position: -158px -108px;
}
.pagenation a.last {
  background-position: -108px -8px;
}
.pagenation a.last:hover {
  background-position: -108px -58px;
}
.pagenation a.last:active {
  background-position: -108px -108px;
}

/*새소식 상세*/
.newsDetailsBody {
  width: 100%;
  max-width: 1520px;
  margin: 0 auto;
  padding-top: 125px;
  color: #272627;
}
.newsDetailsBody h2.sTit {
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 28px;
  color: #909090;
}
.newsDetailsBody h2.sTit span {
  font-size: 17px;
  font-weight: 400;
  padding-left: 12px;
}
.newsDetailsTitle {
  position: absolute;
  width: 100%;
  max-width: 620px;
  margin-right: 100px;
}
.newsDetailsTitle.fixedPosition {
  position: fixed;
  top: 159px;
}
.newsDetailsTitle dl {
  font-weight: 500;
  margin-bottom: 35px;
  padding-bottom: 10px;
  border-bottom: 1px solid #272627;
}
.newsDetailsTitle dt {
  font-size: 50px;
  line-height: 1.26;
  margin-bottom: 35px;
  letter-spacing: -2.53px;
  word-break: break-all;
}
.newsDetailsTitle dd {
  font-size: 12px;
  color: #909090;
}
.newsDetailsTitle dd > img {
  width: 19px;
  height: 19px;
  margin-right: 6px;
  vertical-align: middle;
}
.newsDetailsTitle dd span {
  padding: 0 10px;
}
.newsDetailsTitle img {
  width: 139px;
  height: 139px;
}
.newsDetailsContent {
  position: relative;
  top: 0;
  width: 100%;
  max-width: 800px;
  margin-top: 179px;
  padding-left: 720px;
}
.newsDetailsText {
  font-size: 16px;
  line-height: 1.88;
  margin-bottom: 20px;
  padding: 49px 0 80px;
  letter-spacing: -0.8px;
  border-top: 1px solid #272627;
  border-bottom: 1px solid #272627;
}
.newsDetailsText img {
  width: 100%;
  height: 100%;
  padding: 15px 0;
}
.newsDetailsTag {
  width: 100%;
  height: 68px;
  margin-bottom: 43px;
}
.newsDetailsTag li {
  font-size: 15px;
  float: left;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 8px;
  text-align: center;
  background-color: #e2ff7c;
}
.newsDetailsTag li:last-child {
  margin-right: 0;
}
.newsDetailsBut {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 200px;
}

@media all and (max-width: 1600px) {
  .newsDetailsBody {
    max-width: 1240px;
  }
  .newsDetailsTitle {
    width: 540px;
  }
  .newsDetailsTitle dl {
    margin-bottom: 15px;
  }
  .newsDetailsContent {
    width: 600px;
    padding-left: 640px;
  }
}

@media (min-width: 1440px) {
  .newsBody {
    margin: 0 auto;
  }
}

@media (min-width: 1000px) {
  .news-container {
    max-width: 1280px;
    margin: 0 32px;
  }
}

@media (min-width: 888px) and (max-width: 999px) {
  .announcementContent_list ul {
    grid-template-columns: repeat(3, minmax(0, 286px));
    grid-gap: 15px;
  }
}

@media (min-width: 600px) and (max-width: 887px) {
  .announcementContent_list ul {
    grid-template-columns: repeat(2, minmax(0, 420px));
    grid-gap: 15px;
  }
}

@media (max-width: 1439px) {
  .newsDetailsBody {
    width: calc(100% - 200px);
    padding-top: 23px;
  }
  .newsDetailsTitle {
    position: static;
    width: 100%;
    max-width: 100%;
    margin-right: 0;
  }
  .newsDetailsTitle dl {
    margin-bottom: 60px;
  }
  .newsDetailsTitle dt {
    font-size: 46px;
  }
  .newsDetailsTitle img {
    width: 112px;
    height: 112px;
  }
  .newsDetailsContent {
    width: 100%;
    max-width: 100%;
    margin-top: 0 !important;
    padding-left: 0;
  }
  .newsDetailsText {
    padding: 29px 0 80px;
    border-top: none;
  }
  .newsDetailsTag {
    margin-bottom: 30px;
  }
}

@media all and (max-width: 999px) {
  .newsBody h2.sTit {
    font-size: 39px;
  }
  .newsBody {
    padding: 15px;
  }
  .newsContent {
    padding-top: 60px;
  }
  .newsContent .newsContent_tap {
    height: 49px;
    margin-bottom: 20px;
  }
  .newsContent .newsContent_tap .newsContent_tapBody {
    width: 615px;
    height: 49px;
  }
  .newsContent .newsContent_tap button {
    margin-right: 30px;
  }
  .newsContent .newsContent_tap dt {
    font-size: 11px;
  }
  .newsContent .newsContent_tap dd {
    font-size: 20px;
  }

  .announcementContent {
    margin-top: 36px;
  }
  /*.announcementContent_list .aC_listBody {*/
  /*	margin: 60px 0;*/
  /*}*/
  /*.announcementContent_list li{*/
  /*	width: 320px;*/
  /*}*/
  /*.announcementContent_list li:nth-child(2n-1) {*/
  /*	margin-right: 44px;*/
  /*}*/

  .announcementContent_list dl {
    margin-top: 5px;
  }
  .announcementContent_list dd {
    line-height: 1.4;
    padding: 14px 0 18px;
  }
  .announcementContent_list dd button {
    font-size: 17px;
    height: 30px;
  }
  .announcementContent_list dd button:before {
    width: 30px;
    height: 30px;
    margin-right: 9px;
  }

  .pagenation {
    display: none;
  }

  /*새 소식 상세*/
  .newsDetailsBody {
    width: calc(100% - 120px);
    padding-top: 10px;
  }
  .newsDetailsTitle h2.sTit {
    font-size: 16px;
  }
  .newsDetailsTitle h2.sTit span {
    font-size: 10px;
    padding-left: 6px;
  }
  .newsDetailsTitle dl {
    margin-bottom: 40px;
  }
  .newsDetailsTitle dt {
    font-size: 25px;
    margin-bottom: 30px;
  }
  .newsDetailsTitle img {
    width: 56px;
    height: 56px;
  }
  .newsDetailsText {
    padding: 19px 0 70px;
  }
  .newsDetailsTag {
    margin-bottom: 10px;
  }
  .newsDetailsTag li {
    font-size: 13px;
  }
  .newsDetailsBut {
    margin-bottom: 100px;
  }
}

@media all and (max-width: 599px) {
  .newsBody {
    width: 100vw;
    margin: 0;
    padding: 15px;
  }

  .announcementContent_list ul {
    display: block;
  }
  .newsContent .newsContent_tap {
    height: 66px;
    margin-bottom: 16px;
  }
  .newsContent .newsContent_tap .newsContent_tapBody {
    height: 49px;
  }

  .announcementContent_list .aC_listBody {
    margin: 0 0 85px;
  }

  /* 새소식 상세 */
  .newsDetailsBody {
    width: calc(100% - 40px);
    padding-top: 10px;
  }
  .newsDetailsText li {
    padding: 0 10px;
    text-indent: -9px;
  }
  .newsDetailsTag {
    margin-bottom: 20px;
  }
}

.newsContent .newsContent_tap {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.newsContent .newsContent_tap::-webkit-scrollbar-thumb,
.newsContent .newsContent_tap::-webkit-scrollbar-track,
.newsContent .newsContent_tap::-webkit-scrollbar {
  display: none;
}

@import 'style/mixin';

.wrapper {
  display: none;
  background-color: #232423;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;

  @media (pointer: coarse) and (orientation: landscape) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.container {
  margin-bottom: pxToVH(33, 360);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconLandscapeBlocker {
  display: block;
  width: 80px;
  height: 80px;
  background-image: url(~public/static/images/landscape-banner/ico-landscape-banner.svg);
  background-size: cover;
}

.notice {
  margin-top: 18px;
  font-size: 20px;
  color: #d8d8d8;
  line-height: 30px;
  text-align: center;
}

.highlightText {
  color: #e2fe7c;
  font-weight: bold;
}

.toggleButton {
  position: absolute;
  top: 1px;
  right: 0;
  width: 30px;
  height: 30px;
  background: url(~public/static/images/reservation/dropdown_arrow_default.png) no-repeat center / contain;
}

.open {
  background-image: url(~public/static/images/reservation/dropdown_arrow_open.png);
}

.operationText {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.3;
  display: none;
  padding: 24px 20px;
  letter-spacing: -0.65px;
  color: #272627;
  background-color: #f5f5f5;
}

.operationTextWrapper {
  margin-top: 15px;
}

@import 'style/mixin';

.container {
  position: relative;
  z-index: 10;
  @include media(contents-pc) {
    margin-top: 8px;
  }
  @include media(contents-tablet, contents-mobile) {
    margin-top: -7px;
  }
}

.buttons {
  display: flex;
  width: 100%;
  @include media(contents-pc, contents-tablet) {
    flex-direction: row;
    max-width: 700px;
    button + button {
      margin-left: 10px;
    }
  }
  @include media(contents-mobile) {
    flex-direction: column;
    button + button {
      margin-top: 10px;
    }
  }
}

.fixedButtons {
  position: fixed;
  bottom: 0;
  left: 50%;
  display: flex;
  flex-direction: row;
  transform: translateX(-50%);
  background: url(~public/static/images/reservation/button_layer.png) no-repeat center / 100% 100%;

  @include media(contents-pc) {
    padding: 27px 0 36px;
  }

  @include media(contents-tablet) {
    width: calc(100% - 80px);
    min-width: 700px;
    padding: 30px 40px;
  }

  @include media(contents-mobile) {
    width: calc(100% - 30px);
    max-width: 670px;
    padding: 30px 15px;
  }

  button {
    flex: 1 1 0;
  }

  button + button {
    margin: 0;
  }

  :global button:nth-of-type(1) {
    padding: 0 !important;
  }

  :global button:nth-of-type(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0;
  }
}

.priceFixedButtons {
  @include media(contents-pc) {
    padding-top: 85px;
  }
  @include media(contents-tablet, contents-mobile) {
    padding-top: 85px;
  }
}

.fixedButtons {
  .totalPriceContainer {
    @include media(contents-pc) {
      bottom: 110px !important;
      width: 100%;
    }
    @include media(contents-tablet) {
      bottom: 111px !important;
      width: calc(100% - 80px);
    }
    @include media(contents-mobile) {
      bottom: 111px !important;
      width: calc(100% - 30px);
    }
  }
}

.totalPriceContainer {
  font-weight: bold;
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  letter-spacing: -1.2px;
  color: #272627;
  @include media(contents-pc) {
    font-size: 24px;
    line-height: 24px;
  }
  @include media(contents-tablet, contents-mobile) {
    font-size: 20px;
    line-height: 20px;
  }
}

.helper {
  position: absolute;
  top: 50px;
}

@import 'style/mixin';

@keyframes blinkCursor {
  50% {
    opacity: 0;
  }
}

@keyframes fadeInAndShowUp {
  from {
    opacity: 0;
    top: 20px;
  }
  to {
    opacity: 1;
    top: 0;
  }
}

.container {
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  opacity: 0;
  background-color: #232423;
}

.wrapper {
  position: absolute;

  @include media(main-mobile) {
    right: max(14px, 3.89vw);
    width: 77.7vw;
    max-width: 490px;
  }
  @include media(main-tablet) {
    right: 5.8vw;
    width: 62.23vw;
    max-width: 620px;
  }
  @include media(main-pc-small) {
    right: 64px;
    display: flex;
  }
  @include media(main-pc-medium) {
    right: 6.64vw;
    display: flex;
  }
}

.supInviteYou {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.75;
  position: relative;
  vertical-align: top;
  letter-spacing: -0.72px;
  opacity: 0;
  color: #909090;

  &.fadeIn {
    animation: fadeInAndShowUp 0.5s linear 0.5s forwards;
  }

  @include media(main-mobile, main-tablet) {
    margin-top: 24px;
    margin-bottom: 12px;
    text-align: left;
  }
  @include media(main-pc-medium) {
    font-size: calc(12px + (100vw - 1280px) / 320);
  }
}

.InviteYouContainer {
  position: relative;
  display: flex;
}

.iconSlash {
  margin-right: 2.667vw;
  opacity: 0;

  &.fadeIn {
    animation: fadeInAndShowUp 0.5s linear 0.5s forwards;
  }

  @include media(main-mobile, main-tablet) {
    display: none;
  }
  @include media(main-pc-small) {
    height: 157px;
  }
  @include media(main-pc-medium) {
    height: 12.2vw;
  }
}

.textInviteYou {
  flex: 1 1 auto;
  opacity: 0;

  &.fadeIn {
    animation: fadeInAndShowUp 0.5s linear 0s forwards;
  }

  @include media(main-pc-small) {
    width: 571px;
  }
  @include media(main-pc-medium) {
    width: 44.6vw;
  }
}

.cursorWrapper {
  display: flex;
  align-items: flex-end;
  opacity: 0;

  &.fadeIn {
    animation: fadeInAndShowUp 0.5s linear 0s forwards;
  }
}

.cursor {
  animation: blinkCursor 1s step-end 0s infinite;

  @include media(main-mobile) {
    width: 10.27vw;
    margin-bottom: 2.22vw;
  }
  @include media(main-tablet) {
    width: 7.97vw;
    margin-bottom: 1.61vw;
  }
  @include media(main-pc-small) {
    width: 82px;
    margin-bottom: 18px;
  }
  @include media(main-pc-medium) {
    width: 6.4vw;
    margin-bottom: 1.4vw;
  }
}

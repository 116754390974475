@import 'style/mixin';
.educationTitle {
  font-weight: 500;
  line-height: 1.2;
  word-break: break-all;
  color: #272627;
  @include media(contents-pc) {
    font-size: 50px;
    margin-bottom: 103px;
    letter-spacing: -2.5px;
    &::before,
    &::after {
      width: 24px;
      height: 51px;
    }
  }
  @include media(contents-tablet, contents-mobile) {
    font-size: 32.5px;
    margin-bottom: 63px;
    letter-spacing: -1.63px;
    &::before,
    &::after {
      width: 15px;
      height: 33px;
    }
  }
  &::before {
    display: inline-block;
    margin-right: 9px;
    content: '';
    vertical-align: middle;
    background: url(~public/static/images/contents/education/icon-bracket-left.svg) no-repeat center / contain;
  }
  &::after {
    display: inline-block;
    margin-left: 9px;
    content: '';
    vertical-align: middle;
    background: url(~public/static/images/contents/education/icon-bracket-right.svg) no-repeat center / contain;
  }
}

.educationTitleSuperscript {
  font-weight: 500;
  display: inline-block;
  color: #909090;
  @include media(contents-pc) {
    font-size: 25.5px;
    margin-bottom: 30px;
    letter-spacing: -1.28px;
    & > sub {
      font-size: 12.5px;
      margin-left: 11px;
      vertical-align: bottom;
      letter-spacing: -0.63px;
    }
  }
  @include media(contents-tablet, contents-mobile) {
    font-size: 17px;
    letter-spacing: -0.85px;
  }
}

.classContainer {
  display: grid;
  @include media(contents-pc, contents-tablet) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
  @include media(contents-mobile) {
    grid-row-gap: 10px;
  }
}

.classItem {
  font-size: 18px;
  font-weight: bold;
  position: relative;
  width: 100%;
  height: 100px;
  text-align: center;
  border: solid 1px #afafaf;
  &:hover,
  &:active,
  &.selected {
    color: #fff;
    background-color: #272627;
  }
  &:disabled {
    cursor: default;
    color: rgba(255, 255, 255, 0.5);
    background-color: #c0c0c1;
  }
}

.notEditableClassItem {
  pointer-events: none;
  color: rgba(39, 38, 39, 0.5) !important;
  border: none;
  background-color: #ccc !important;
}

.waitingTag {
  font-size: 14px;
  font-weight: 500;
  line-height: 13px;
  position: absolute;
  top: 8px;
  right: 8px;
  display: inline-block;
  box-sizing: border-box;
  height: 28px;
  padding: 7px 5px 8px;
  color: #fff;
  border-radius: 14px;
  background-color: #454de0;
}

.waitingButton {
  background-color: #454de0;
  &:hover {
    color: #fff;
    background-color: #2a32c7;
  }
}

.noticeTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 17px;
  letter-spacing: -0.9px;
  color: #272627;
}

.noticeItem {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  position: relative;
  display: flex;
  padding-left: 8px;
  letter-spacing: -0.75px;
  color: #747477;
  &::before {
    position: absolute;
    top: 10px;
    left: 0;
    display: inline-block;
    flex: 0 0 4px;
    width: 4px;
    height: 4px;
    content: '';
    border-radius: 50%;
    background-color: #747477;
  }
}

@import 'style/mixin';

.mainTitle {
  font-weight: 500;
  color: #272627;
  line-height: 1.2;
  @include media(pc-medium, pc-small) {
    font-size: 60px;
    margin-bottom: 100px;
    letter-spacing: -3px;
  }
  @include media(tablet, mobile) {
    font-size: 40px;
    margin-bottom: 59px;
    letter-spacing: -2px;
  }
}

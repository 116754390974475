@charset "utf-8";

.errorSec {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
  min-height: 100vh;
}
.error {
  text-align: center;
  font-weight: 500;
}
.error .txt1 {
  font-size: 38px;
  letter-spacing: -1.9px;
  color: #030303;
}
.error .txt2 {
  margin-top: 20px;
  font-size: 16px;
  letter-spacing: -0.8px;
  color: #747477;
}
.errorSec .btSec {
  margin-top: 59px;
}
.errorSec .bt {
  max-width: 270px;
}
.errorNotfound .error-player {
  width: 100%;
  max-width: 736px;
}
.error404 .error-player {
  width: 100%;
  max-width: 629px;
}
.errorNotfound .btSec {
  font-size: 0;
}
.errorNotfound .btSec .bt {
  margin: 0 5px;
}
.errorNotfound .btSec2 .bt {
  width: calc(50% - 5px);
}
.errorNotfound .btSec2 .bt:first-child {
  margin-left: 0;
}
.errorNotfound .btSec2 .bt:last-child {
  margin-right: 0;
}

@media (max-width: 999px) {
  .error .txt1 {
    margin-top: 15px;
    font-size: 23px;
    letter-spacing: -1.13px;
    color: #040404;
  }
  .error .txt2 {
    margin-top: 15px;
    font-size: 13px;
    letter-spacing: -0.65px;
    color: rgba(4, 4, 4, 0.8);
  }
  .errorSec .btSec {
    margin-top: 53px;
  }
  .errorSec .btSec .bt {
    height: 33px;
    padding-top: 17px;
    font-size: 13px;
    letter-spacing: -0.65px;
  }
}

@import 'style/mixin';

.bannerContainer {
  min-height: 70px;

  @include media(main-tablet, main-pc-small, main-pc-medium) {
    padding-left: 50px;
    background: url(~public/static/images/main/main-section/square-banner-icon.svg) no-repeat left 12px;
  }

  @include media(main-mobile) {
    padding-left: 40px;
    background-size: 8%;
  }
}

.banner {
  font-size: 15px;
  line-height: 1.47;
  position: relative;
  max-width: 148px;
  min-height: 43px;
  padding: 12px 60px 12px 20px;
  letter-spacing: -0.36px;
  word-break: keep-all;
  word-wrap: break-word;
  color: #fff;
  background-color: #e65529;

  @include media(main-tablet, main-pc-small, main-pc-medium) {
    &:first-of-type::before {
      position: absolute;
      top: 12px;
      left: -12px;
      display: inline-block;
      width: 12px;
      height: 11px;
      content: '';
      background: url(~public/static/images/main/main-section/square-banner-tail.svg) no-repeat left top;
    }
  }

  @include media(main-mobile) {
    font-size: 12px;
    letter-spacing: -0.29px;
    padding: 10px 44px 12px 19px;
  }
}

.banner + .banner {
  margin-top: 10px;
}

.link {
  cursor: pointer;
}

.closeButton {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 11px;
  height: 11px;
  padding: 20px;
  background: url(~public/static/images/main/main-section/square-banner-button-close.svg) no-repeat center;
}

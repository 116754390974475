@import 'style/mixin';

@keyframes rotate360 {
  to {
    transform: rotate(360deg);
  }
}

@keyframes computerHistory {
  50% {
    background-image: url(~public/static/images/main/e-invite-you/icon-computer-move.svg);
  }
}

.container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  box-sizing: border-box;
  width: fit-content;
  margin: 60% auto 0;
  @include media(main-mobile) {
    width: 100vw;
    padding: 0 5.55vw;
  }
  @include media(main-tablet) {
    width: 100vw;
  }
  @include media(main-pc-small, main-pc-medium) {
    align-items: center;
    margin-bottom: 30%;
  }
}

.postersContainer {
  display: flex;
  justify-content: space-between;
  @include media(main-mobile, main-tablet) {
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
  }
  @include media(main-pc-small) {
    width: fit-content;
  }
}

/* 포스터 5개 */

.posterNCMWrapper {
  @include media(main-mobile, main-tablet) {
    align-self: flex-end;
    order: 1;
  }
  @include media(main-tablet) {
    margin-right: min(4.5vw, 55px);
  }
  @include media(main-pc-small) {
  }
}

.posterGGWrapper {
  @include media(main-mobile, main-tablet) {
    align-self: flex-start;
    order: 2;
    margin-top: 108px;
  }
  @include media(main-tablet) {
    margin-left: 46px;
  }
  @include media(main-pc-small) {
    margin-top: 201px;
  }
  @include media(main-pc-medium) {
    margin-top: 15.7vw;
  }
}

.posterKOTWWrapper {
  @include media(main-mobile, main-tablet) {
    align-self: flex-end;
    order: 3;
    margin-top: 103px;
  }
  @include media(main-tablet) {
    margin-top: -69px;
    margin-right: min(18.75vw, 190px);
  }
  @include media(main-pc-small) {
    position: relative;
    left: 84px;
    margin-top: 446px;
  }
  @include media(main-pc-medium) {
    position: relative;
    left: 6.56vw;
    margin-top: 34.84vw;
  }
}

// .posterKOTWWrapper {
//   @include media(main-mobile) {
//     order: 5;
//     margin-top: 110px;
//   }
//   @include media(main-tablet) {
//     align-self: flex-start;
//     order: 5;
//     margin-top: 100px;
//     margin-left: 79px;
//   }
//   @include media(main-pc-small) {
//     position: absolute;
//     bottom: -370px;
//     left: 50px;
//   }
//   @include media(main-pc-medium) {
//     position: absolute;
//     bottom: pxToVW(-370, 1280);
//     left: pxToVW(49, 1280);
//   }
// }

.posterPediaWrapper {
  display: flex;
  justify-content: center;
  @include media(main-mobile) {
    order: 6;
    margin-top: 120px;
  }
  @include media(main-tablet) {
    order: 6;
    margin-top: 124px;
  }
  @include media(main-pc-small, main-pc-medium) {
    position: absolute;
    bottom: -1167px;
    left: 50%;
    transform: translateX(-50%);
  }
  @include media(main-pc-medium) {
    bottom: -91.1vw;
  }
}

.hoverInteraction {
  &:hover {
    img {
      transform: scale(1.2, 1.2);
    }
    .textUnderline {
      background-size: 0 1px, 100% 1px;
    }
  }
}

.posterNCMContainer {
  display: flex;
}

.posterGGContainer {
  display: flex;
  flex-direction: column-reverse;
}

.posterKOTWContainer {
  display: flex;
  @include media(main-mobile, main-tablet) {
    flex-direction: column-reverse;
  }
  @include media(main-pc-small, main-pc-medium) {
    flex-direction: row-reverse;
  }
}

// .posterKOTWContainer {
//   display: flex;
//   @include media(main-mobile) {
//     flex-direction: column-reverse;
//   }
//   @include media(main-tablet, main-pc-small, main-pc-medium) {
//     flex-direction: row-reverse;
//   }
// }

.posterPediaContainer {
  display: inline-block;
  width: fit-content;
}

.textUnderline {
  transition: background-size 400ms;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
  background-repeat: no-repeat;
  background-position: 100% 100%, 0 100%;
  background-size: 100% 1px, 0 1px;
}

.posterNCMTitle {
  font-size: 15px;
  line-height: 1.47;
  z-index: 10;
  align-self: self-end;
  margin-right: 2vw;
  text-align: right;
  color: #fff;

  @include media(main-mobile) {
    font-size: 11px;
  }
  @include media(main-tablet, main-pc-small) {
    font-size: 13px;
  }
  @include media(main-pc-medium) {
    font-size: 15px;
  }
}

.posterGGTitle {
  line-height: 1.47;
  z-index: 10;
  color: #fff;

  @include media(main-mobile) {
    font-size: 11px;
    margin-top: 12px;
  }
  @include media(main-tablet) {
    font-size: 13px;
    margin-top: 22px;
  }
  @include media(main-pc-small) {
    font-size: 13px;
    margin-top: 20px;
  }
  @include media(main-pc-medium) {
    font-size: 15px;
    margin-top: 20px;
  }
}

.posterKOTWTitle {
  line-height: 1.47;
  position: relative;
  z-index: 10;
  // width: fit-content;
  text-align: left;
  color: #fff;

  @include media(main-mobile) {
    font-size: 11px;
    margin-top: 12px;
    text-align: right;
  }
  @include media(main-tablet) {
    font-size: 13px;
    margin-top: 22px;
    text-align: right;
  }
  @include media(main-pc-small) {
    font-size: 13px;
    margin-left: 19px;
  }
  @include media(main-pc-medium) {
    font-size: 15px;
    margin-left: 16px;
  }
}

// .posterKOTWTitle {
//   line-height: 1.47;
//   z-index: 10;
//   color: #fff;

//   @include media(main-mobile) {
//     font-size: 11px;
//     margin-top: 12px;
//   }
//   @include media(main-tablet) {
//     font-size: 13px;
//     margin-left: 12px;
//   }
//   @include media(main-pc-small) {
//     font-size: 13px;
//     margin-left: 19px;
//   }
//   @include media(main-pc-medium) {
//     font-size: 15px;
//     margin-left: 16px;
//   }
// }

.posterPediaTitle {
  line-height: 2;
  display: inline-block;
  color: #fff;
  @include media(main-mobile) {
    font-size: 12px;
    margin-top: 16px;
    strong {
      font-size: 14px;
    }
  }
  @include media(main-tablet) {
    font-size: 13px;
    margin-top: 16px;
    strong {
      font-size: 15px;
    }
  }
  @include media(main-pc-small) {
    font-size: 13px;
    margin-top: 21px;
    strong {
      font-size: 15px;
    }
  }
  @include media(main-pc-medium) {
    font-size: 15px;
    margin-top: 26px;
    strong {
      font-size: 18px;
    }
  }
}

.posterBoxShadow {
  box-shadow: 66.7px -24.3px 57px 0 #232423;
}

.posterNCMImage {
  overflow: hidden;

  @include media(main-mobile, main-tablet) {
    width: 200px;
    height: 280px;
  }
  @include media(main-pc-small) {
    width: 212px;
    height: 296px;
  }
  @include media(main-pc-medium) {
    width: 16.56vw;
    height: 23.12vw;
  }
}

.posterGGImage {
  overflow: hidden;

  @include media(main-mobile, main-tablet) {
    width: 200px;
    height: 280px;
  }
  @include media(main-pc-small) {
    width: 212px;
    height: 296px;
  }
  @include media(main-pc-medium) {
    width: 16.56vw;
    height: 23.12vw;
  }
}

.posterKOTWImage {
  overflow: hidden;

  @include media(main-mobile, main-tablet) {
    width: 280px;
    height: 200px;
  }
  @include media(main-pc-small) {
    width: 296px;
    height: 212px;
  }
  @include media(main-pc-medium) {
    width: 23.12vw;
    height: 16.56vw;
  }
}

// .posterKOTWImage {
//   overflow: hidden;

//   @include media(main-mobile, main-tablet) {
//     width: 280px;
//     height: 200px;
//   }
//   @include media(main-pc-small) {
//     width: 296px;
//     height: 212px;
//   }
//   @include media(main-pc-medium) {
//     width: 23.12vw;
//     height: 16.56vw;
//   }
// }

.posterPediaImage {
  overflow: hidden;
  @include media(main-mobile) {
    width: 100%;
    height: 441px;
  }
  @include media(main-tablet) {
    width: 679px;
    height: 441px;
  }
  @include media(main-pc-small) {
    width: 884px;
    height: 574px;
  }
  @include media(main-pc-medium) {
    width: 69vw;
    height: 44.8vw;
  }
}

.image {
  width: 100%;
  height: 100%;
  transition: transform 0.5s;
  transform: scale(1);
  object-fit: cover;
}

/* 하단 버튼 */

.buttonNCMHistoryWrapper {
  display: flex;

  @include media(main-mobile) {
    justify-content: center;
    order: 4;
    margin-top: 117px;
  }
  @include media(main-tablet) {
    justify-content: center;
    order: 4;
    margin-top: 96px;
  }
  @include media(main-pc-small) {
    position: absolute;
    right: 66px;
    bottom: -250px;
    justify-content: flex-end;
    width: 100%;
  }
  @include media(main-pc-medium) {
    position: absolute;
    right: 66px;
    bottom: -22.5vw;
    justify-content: flex-end;
    width: 100%;
  }
}

.buttonNCMHistory {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s linear;

  &:hover,
  &:focus {
    transform: scale(1.2, 1.2);
  }

  @include media(main-mobile, main-tablet) {
    width: 215px;
    height: 215px;
  }
  @include media(main-pc-small) {
    width: 212px;
    height: 212px;
  }
  @include media(main-pc-medium) {
    width: 18.43vw;
    height: 18.43vw;
  }
}

.iconNCMHistory {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation-name: rotate360;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.iconNCMComputer {
  display: inline-block;
  width: 7.813vw;
  height: 7.813vw;
  animation-name: computerHistory;
  animation-duration: 2s;
  animation-timing-function: step-end;
  animation-iteration-count: infinite;
  background-image: url(~public/static/images/main/e-invite-you/icon-computer-original.svg);
  background-repeat: no-repeat;
  background-size: 100%;

  @include media(main-mobile) {
    width: 109px;
    height: 110px;
  }
  @include media(main-tablet) {
    width: 136px;
    height: 136px;
  }
  @include media(main-pc-small) {
    width: 123px;
    height: 123px;
  }
  @include media(main-pc-small) {
    width: 8.59vw;
    height: 8.59vw;
  }
}

@use 'sass:math';

$breakpoints: (
  main-mobile: (
    max: 767px,
  ),
  main-tablet: (
    min: 768px,
    max: 999px,
  ),
  main-pc-small: (
    min: 1000px,
    max: 1279px,
  ),
  main-pc-medium: (
    min: 1280px,
  ),
  mobile: (
    max: 599px,
  ),
  tablet: (
    min: 600px,
    max: 999px,
  ),
  pc-small: (
    min: 1000px,
    max: 1439px,
  ),
  pc-medium: (
    min: 1440px,
  ),
  contents-mobile: (
    max: 767px,
  ),
  contents-tablet: (
    min: 768px,
    max: 999px,
  ),
  contents-pc: (
    min: 1000px,
  ),
);

@mixin media($keys...) {
  @each $key in $keys {
    $min: map-get(map-get($breakpoints, $key), 'min');
    $max: map-get(map-get($breakpoints, $key), 'max');
    @if $min and $max {
      @media (min-width: $min) and (max-width: $max) {
        @content;
      }
    } @else if $min {
      @media (min-width: $min) {
        @content;
      }
    } @else if $max {
      @media (max-width: $max) {
        @content;
      }
    }
  }
}

@function power($base, $exponents) {
  $raised: 1;

  @for $i from 1 through $exponents {
    $raised: $raised * $base;
  }

  @return $raised;
}

@function MathRoundToFixed($number, $point) {
  $powerPoint: power(10, $point);

  @return math.div(math.round($number * $powerPoint), $powerPoint);
}

@function pxToViewport($px, $viewportSize) {
  $calculatedValue: math.div(100 * $px, $viewportSize);
  $fixedVW: MathRoundToFixed($calculatedValue, 2);

  @return $fixedVW;
}

@function pxToVH($px, $viewportHeight) {
  $fixedValue: pxToViewport($px, $viewportHeight);
  @return #{$fixedValue}vh;
}

@function pxToVW($px, $viewportWidth) {
  $fixedValue: pxToViewport($px, $viewportWidth);
  @return #{$fixedValue}vw;
}

@function getResponsiveRule($minWidth, $maxWidth, $minSize, $maxSize, $max: true) {
  $widthGap: $maxWidth - $minWidth;
  $sizeGap: $maxSize - $minSize;
  $widthPerPixel: math.div($widthGap, $sizeGap);
  $fixedWidthPerPixel: MathRoundToFixed($widthPerPixel, 2);

  @if ($max) {
    @return max(#{$minSize}px, calc(#{$minSize}px + (calc((100vw - #{$minWidth}px) / #{$fixedWidthPerPixel}))));
  }

  @return calc(#{$minSize}px + (calc((100vw - #{$minWidth}px) / #{$fixedWidthPerPixel})));
}

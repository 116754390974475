@import 'style/mixin';

@keyframes blinkEffect {
  75% {
    opacity: 0;
  }
}

.container {
  position: relative;
  width: 100vw;
  min-height: calc(100vh + 300px);
  background-color: #eaeaea;
}

.paddler {
  position: relative;
  height: 10vh;
}

.boardWrapper {
  //position: sticky;
  //top: 0;
  min-height: 100vh;
  //background-color: #eaeaea;
  transform: translate3d(0, 0, 0);
}

.header {
  padding-top: 160px;
  padding-bottom: 46px;

  @include media(main-mobile) {
    padding-top: 160px;
    padding-bottom: 23px;
  }
}

.navigation {
  display: flex;
  justify-content: center;
  user-select: none;
}

.navigationListItem {
  font-size: 34px;
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  letter-spacing: -2px;

  & + .navigationListItem {
    @include media(main-mobile) {
      //height: 38px;
      margin-top: -2px;
    }
    @include media(main-tablet) {
      margin-top: 4px;
    }
    @include media(main-pc-small) {
      margin-top: 6px;
    }
    @include media(main-pc-medium) {
      margin-top: 0.468vw;
    }
  }

  @include media(main-mobile, main-tablet, main-pc-small) {
    padding-left: 44px;
  }

  @include media(main-pc-medium) {
    padding-left: 4.2188vw;
  }
}

.arrowIcon {
  display: none;

  @at-root &.on {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    content: '';

    background-image: url(~/public/static/images/main/d-news/main-post-nav-arrow-14.svg);
    background-repeat: no-repeat;
    background-size: 100%;
  }

  @include media(main-mobile) {
    width: 26px;
    height: 19px;
    padding-top: 10px;
  }

  @include media(main-tablet, main-pc-small) {
    width: 32px;
    height: 24px;
    padding-top: 14px;
  }

  @include media(main-pc-medium) {
    width: 2.6563vw;
    height: 1.875vw;
    padding-top: 0.8vw;
  }
}

.navigationTitleContainer {
  bottom: 0;
}

.navigationTitle {
  font-family: kepler-std-display, sans-serif;
  font-weight: normal;
  line-height: 1.2;
  position: relative;

  @include media(main-mobile) {
    font-size: 30px;
    letter-spacing: -1px;
  }
  @include media(main-tablet) {
    font-size: 41px;
  }

  @include media(main-pc-small) {
    font-size: 34px;
  }

  @include media(main-pc-medium) {
    font-size: 2.6563vw;
  }
}

.navigationSup {
  position: relative;
  display: inline-block;
  padding: 0.5729vw 11px 0 8px;
  vertical-align: top;
  white-space: nowrap;

  @include media(main-mobile) {
    font-size: 9px;
    margin-top: 7px;
    padding-right: 3px;
    padding-left: 4px;
    letter-spacing: -0.5px;
  }

  @include media(main-tablet) {
    font-size: 11px;
    letter-spacing: -1px;
  }

  @include media(main-pc-small) {
    font-size: 11.5px;
    letter-spacing: -1px;
  }

  @include media(main-pc-medium) {
    font-size: 0.898vw;
  }
}

.boardListContainer {
  position: relative;
  overflow: hidden;
}

.scrollBarWrapper {
  position: relative;
  top: 0;
  bottom: auto;

  height: 2px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}

.scrollBar {
  position: relative;
  top: 0;
  left: 0;
  width: 80px; // responsive
  height: 100%;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
}

.navigationBarWrapper {
  //transition: opacity 0.5s;
}

.boardList {
  position: relative;
  display: flex;
  height: auto;

  @include media(main-mobile, main-tablet) {
    margin-top: 19px;
  }

  @include media(main-pc-small) {
    margin-top: 33px;
  }

  @include media(main-pc-medium) {
    margin-top: 2.578vw;
  }
}

.boardListItem {
  position: relative;

  @include media(main-mobile) {
    width: 170px;
    margin-right: 31px;
  }

  @include media(main-tablet) {
    width: 210px;
    margin-right: 39px;
  }

  @include media(main-pc-small) {
    width: 238px;
    margin-right: 44px;
  }

  @include media(main-pc-medium) {
    width: 18.594vw;
    margin-right: 3.438vw;
  }
}

.postImageContainer {
  position: relative;
}

.postImageWrapper {
  overflow: hidden;
}

.postItem {
  position: relative;
  display: inline-block;
  width: 100%;

  &:hover {
    .postImage {
      transform: scale(1.2);
      filter: none;
    }
  }
}

.postImage {
  display: block;
  width: 100%;
  transition: transform 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  transform: scale(1);
  object-fit: cover;
  filter: grayscale(100%);

  @include media(main-mobile) {
    max-height: 257.1px;
  }

  @include media(main-tablet) {
    max-height: 318.2px;
  }

  @include media(main-pc-small) {
    max-height: 360px;
  }

  @include media(main-pc-medium) {
    max-height: pxToVW(360, 1000);
  }
}

.publishTime {
  position: absolute;
  bottom: 0;
  transform: rotate(180deg);
  writing-mode: vertical-rl;

  @include media(main-mobile) {
    font-size: 9px;
    right: -14px;
  }

  @include media(main-tablet) {
    font-size: 11px;
    right: -18px;
  }

  @include media(main-pc-small) {
    font-size: 10.5px;
    right: -18px;
  }

  @include media(main-pc-medium) {
    font-size: 12px;
    right: -1.56vw;
  }
}

.postTitleContainer {
  position: relative;
  padding-bottom: 10px;

  @include media(main-mobile, main-tablet) {
    margin-top: 9px;
  }

  @include media(main-pc-small) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-top: 16px;
  }

  @include media(main-pc-medium) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-top: 1.25vw;
  }
}

.postCategoryText {
  display: block;
  white-space: nowrap;
  letter-spacing: -0.53px;

  @include media(main-mobile) {
    font-size: 9px;
    line-height: 1;
    margin-bottom: 4px;
    word-break: break-all;
  }

  @include media(main-tablet) {
    font-size: 11px;
    line-height: 1;
    margin-bottom: 10px;
    word-break: break-all;
  }

  @include media(main-pc-small) {
    font-size: 11px;
    line-height: 10px;
  }

  @include media(main-pc-medium) {
    font-size: calc(11px + (100vw - 1280px) / 320);
    line-height: 1.9;
  }
}

.postTitle {
  font-weight: 500;
  word-break: break-all;

  @include media(main-mobile) {
    font-size: 14px;
    max-width: 100%;
  }
  @include media(main-tablet) {
    font-size: 16.5px;
    line-height: 18px;
  }
  @include media(main-pc-small) {
    font-size: 17.5px;
    margin-right: 9px;
  }
  @include media(main-pc-medium) {
    font-size: 1.41vw;
    margin-right: 0.703vw;
  }
}

.boardListController {
  position: absolute;
  z-index: 100;
  top: 0;
  bottom: 0;
  width: 84px;

  &:hover {
    cursor: none;
  }

  &.controllerLeft {
    left: 0;
  }

  &.controllerRight {
    right: 0;
  }

  @include media(main-mobile, main-tablet) {
    display: none;
  }
}

.iconControllerArrowLeft,
.iconControllerArrowRight {
  position: fixed;
  z-index: 200;
  width: 100px;
  height: 100px;
  animation-name: blinkEffect;
  animation-duration: 0.5s;
  animation-timing-function: step-end;
  animation-iteration-count: infinite;
  pointer-events: none;
  opacity: 0;
  background-image: url(~/public/static/images/main/d-news/posts-left-arrow.png);
  background-size: 100%;
}

.iconControllerArrowLeft {
  transform: rotate(180deg);
}

@import 'style/mixin';

@keyframes blink {
  50% {
    opacity: 0;
  }
}

@keyframes downMobile {
  0% {
    top: -14vw;
  }
}

@keyframes downPC {
  0% {
    top: -4vw;
  }
}

.stickerContainer {
  position: relative;
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    content: '';
    transform: translate3d(-50%, -50%, 0);
    opacity: 0.505;
    border-radius: 100%;
    background-color: #000;
    filter: blur(30px);
  }
}

.play {
  .stickerImage {
    visibility: hidden;
  }
  .container {
    display: block;
  }
}

.stickerImage {
  position: relative;
  z-index: 10;
}

.container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: none;
}

.selected {
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75%;
    height: 75%;
    content: '';
    transform: translate3d(-50%, -50%, 0);
    opacity: 0.505;
    border-radius: 100%;
    background-color: #e1fa87;
    filter: blur(50px);
  }

  .stickerImage {
    visibility: hidden;
  }
  .container {
    display: block;
    &::before {
      position: absolute;
      left: 50%;
      display: inline-block;
      content: '';
      transform: translate(-50%, 0);
      background-image: url(~public/static/images/flow/selected-arrow.svg);
      background-repeat: no-repeat;
      background-size: cover;
      @include media(mobile, tablet) {
        top: -9vw;
        width: 7.7vw;
        height: 9vw;
        animation: downMobile 1s infinite ease;
      }
      @include media(pc-small, pc-medium) {
        top: -2.97vw;
        width: 2.54vw;
        height: 2.97vw;
        animation: downPC 1s infinite ease;
      }
    }
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      animation: blink 1s infinite ease;
      background-image: url(~public/static/images/flow/selected-top-left.svg), url(~public/static/images/flow/selected-top-right.svg),
        url(~public/static/images/flow/selected-bottom-right.svg), url(~public/static/images/flow/selected-bottom-left.svg);
      background-repeat: no-repeat;
      background-position: top left, top right, bottom right, bottom left;
      @include media(mobile, tablet) {
        background-size: 3.8vw 3.8vw;
      }
      @include media(pc-small, pc-medium) {
        background-size: 1.05vw 1.05vw;
      }
    }
  }
}

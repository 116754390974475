@import 'style/mixin';

.isPc {
}

.isMobile {
}

.navigationContainer {
  position: fixed;
  z-index: 30;

  @include media(main-mobile) {
    right: 15px;
    bottom: 15px;
  }
  @include media(main-tablet) {
    right: 24px;
    bottom: 24px;
  }
  @include media(main-pc-small, main-pc-medium) {
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
  }
}

.navigationWrapper {
  display: flex;
  align-items: center;
}

.navigationName {
  font-size: 12px;
  line-height: 28px;
  position: absolute;
  right: 40px;
  visibility: hidden;
  height: 28px;
  padding: 0 10px;
  white-space: nowrap;
  color: #fff;
  border: solid 1px rgba(121, 121, 121, 0.22);
  border-radius: 3px;
  background-color: #191919;
  backdrop-filter: blur(4.1px);
}

.navigationItem {
  display: block;
  width: 34px;
  height: 34px;
  border: none;
  background-color: #000000;

  &::before {
    display: inline-block;
    width: 100%;
    height: 100%;
    content: '';
    opacity: 0.5;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16.7px 16.7px;
  }

  @at-root .isPc & {
    &:hover {
      &::before {
        opacity: 1;
      }
      & + .navigationName {
        visibility: visible;
      }
    }
  }
}

.selectedItem {
  background-color: #4f504f;
  &::before {
    opacity: 1;
  }
}

.navigation-home {
  &::before {
    background-image: url(~public/static/images/main/navigation/home.svg);
  }
}

.navigation-highlight {
  &::before {
    background-image: url(~public/static/images/main/navigation/highlight.svg);
  }
}

.navigation-news {
  &::before {
    background-image: url(~public/static/images/main/navigation/news.svg);
  }
}

.navigation-play-route {
  &::before {
    background-image: url(~public/static/images/main/navigation/play-route.svg);
  }
}

.navigation-invite-you {
  &::before {
    background-image: url(~public/static/images/main/navigation/invite-you.svg);
  }
}

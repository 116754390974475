@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  position: fixed;
  z-index: 9000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  transition-timing-function: ease;
  transition-duration: 0.3s;
  transition-property: opacity;
  background-color: rgba(0, 0, 0, 0.5);
}

.controlButton {
  font-size: 20px;
  font-weight: 300;
  width: 15%;
  height: 100%;
  cursor: none;
  white-space: nowrap;
  letter-spacing: -1px;
  color: #fff;
}

.controlButtonText {
  position: fixed;
  z-index: 999;
  pointer-events: none;
  opacity: 0;
}

.videoContainer {
  display: flex;
  align-items: center;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
  width: 100%;
}

.closeButton {
  width: 28px;
  height: 28px;
  margin-bottom: 29px;
  background-image: url(~public/static/images/main/video-player/icon-button-close.svg);

  &:hover {
    animation-name: rotate360;
    animation-duration: 500ms;
    animation-timing-function: linear;
  }
}

.videoWrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 42.5%;
}

.videoArea {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.videoLink {
  font-size: 16px;
  width: 500px;
  margin-top: 29px;
  text-align: center;
  letter-spacing: -0.8px;
}

.videoLinkText {
  color: #fff;
}

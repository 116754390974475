@charset "UTF-8";

.react-datepicker {
  padding: 44px 55px 52px;
  font-size: 18px;
  box-sizing: border-box;
}
.react-datepicker__header--custom--month {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 6px;
}

.react-datepicker__navigation {
  width: 35px;
  height: 35px;
}
.react-datepicker__navigation--previous {
  background-image: url(../image/icon-left.png);
  background-size: 35px 35px;
}
.react-datepicker__navigation--next {
  background-image: url(../image/icon-right.png);
  background-size: 35px 35px;
}

.react-datepicker__month {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  color: #272627;
}
.react-datepicker__month-name {
  font-size: 24px;
  font-weight: bold;
  margin-top: -6px;
}

.react-datepicker__day-names,
.react-datepicker__week {
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
}
.react-datepicker__day-names {
  margin-top: 27px;
  color: #747477;
}
.react-datepicker__week {
  margin-top: 10px;
}
.react-datepicker__day,
.react-datepicker__day-name {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 50px;
  height: 50px;
}
.react-datepicker__day {
  cursor: pointer;
}
.react-datepicker__day:hover {
  background-color: #4e4d4e;
  color: #fff;
}
.react-datepicker__day--today {
  background-color: rgba(39, 38, 39, 0.1);
}
.react-datepicker__day--selected {
  background-color: #272627;
  color: #fff;
}
.react-datepicker__day--disabled {
  color: #9fa1a7;
  pointer-events: none;
}
.react-datepicker__day--disabled:hover {
  background-color: transparent;
}
.react-datepicker__day--excluded {
  color: #c7c7ca;
}

@media screen and (max-width: 766px) {
  .react-datepicker {
    padding: 40px 15px 32px;
  }
  .react-datepicker__month-name {
    margin-top: -2px;
  }
  .react-datepicker__day-names {
    margin-top: 23px;
  }
  .react-datepicker__header--custom--month {
    padding: 0;
  }
  .react-datepicker__day,
  .react-datepicker__day-name {
    width: 45px;
    height: 45px;
  }
  .react-datepicker__week {
    margin-top: 5px;
  }
  .react-datepicker__navigation--previous {
    background-image: url(../image/icon-left@2x.png);
  }
  .react-datepicker__navigation--next {
    background-image: url(../image/icon-right@2x.png);
  }
}

@import 'mixin';

.container {
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  @include media(contents-pc) {
    padding: 160px 80px 100px;
  }
  @include media(contents-tablet, contents-mobile) {
    box-sizing: border-box;
    padding: 127px 15px 50px;
  }
}

.reservationList {
  margin-top: 60px;
  border-top: 1px solid #909090;
}

.reservationItem {
  font-weight: 500;
  border-bottom: 1px solid #909090;

  @include media(contents-pc) {
    background: url(~public/static/images/reservation/right_arrow_gray.png) no-repeat top 50% right 70px / 33px 24px;
    &:hover {
      transition: background-position 0.5s ease;
      background: url(~public/static/images/reservation/right_arrow.svg) no-repeat top 50% right 40px / 33px 24px;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
  @include media(contents-tablet, contents-mobile) {
  }
}

.reservationLink {
  @include media(contents-pc) {
    display: flex;
    height: 118px;
  }
  @include media(contents-tablet, contents-mobile) {
    display: inline-block;
    width: 100%;
  }
}

.statusArea {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  @include media(contents-pc) {
    box-sizing: border-box;
    min-width: 279px;
    max-width: 279px;
    padding: 0 40px;
    border-right: 2px dashed hsla(0, 0%, 56%, 0.5);
  }
  @include media(contents-tablet, contents-mobile) {
    height: 96px;
    border-bottom: 2px dashed hsla(0, 0%, 56%, 0.5);
  }
}

.infoArea {
  display: flex;
  align-items: center;
  @include media(contents-pc) {
    font-size: 21.5px;
    padding: 0 40px 0 15px;
    letter-spacing: -1.08px;
  }
  @include media(contents-tablet, contents-mobile) {
    font-size: 16px;
    justify-content: center;
    height: 55px;
  }
}

.date {
  color: #5a5a5a;
  @include media(contents-pc) {
    font-size: 17px;
    letter-spacing: -0.85px;
  }
  @include media(contents-tablet, contents-mobile) {
    font-size: 14px;
    letter-spacing: -0.7px;
  }
  & + .state {
    @include media(contents-pc) {
      margin-top: 16px;
    }
    @include media(contents-tablet, contents-mobile) {
      margin-top: 14px;
    }
  }
}

.state {
  display: flex;
  align-items: center;
  @include media(contents-pc) {
    font-size: 28.5px;
    letter-spacing: -1.43px;
  }
  @include media(contents-tablet, contents-mobile) {
    font-size: 19.5px;
    letter-spacing: -0.98px;
  }
}

.infoItem {
  display: flex;
  align-items: center;
  white-space: nowrap;
  @include media(contents-pc) {
    padding: 0 25px;
  }
  @include media(contents-tablet, contents-mobile) {
    padding: 0 20px;
  }
  & + & {
    border-left: 1px solid #909090;
  }
}

.educationInfoArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 0;
  @include media(contents-pc) {
    padding-right: 110px;
  }
  @include media(contents-tablet, contents-mobile) {
    height: 82px;
    .infoArea {
      height: fit-content;
      margin-top: 12px;
    }
  }
}

.educationTitle {
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  @include media(contents-pc) {
    font-size: 21.5px;
    margin-bottom: 15px;
    padding: 0 40px;
    letter-spacing: -1.08px;
  }
  @include media(contents-tablet, contents-mobile) {
    font-size: 17.5px;
    text-align: center;
    letter-spacing: -0.88px;
  }
}

// 예약 내역 없는 경우

.noItemContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  @include media(contents-pc) {
    height: 521px;
  }
  @include media(contents-tablet, contents-mobile) {
    height: 291px;
  }
}

.noItemIcon {
  background: url(~public/static/images/reservation/img_nolist.png) no-repeat center / cover;
  @include media(contents-pc) {
    width: 50px;
    height: 50px;
  }
  @include media(contents-tablet, contents-mobile) {
    width: 40px;
    height: 40px;
  }
}

.noItemDescription {
  font-weight: 500;
  color: #5a5a5a;
  @include media(contents-pc) {
    font-size: 19.5px;
    margin-top: 20px;
    letter-spacing: -0.98px;
  }
  @include media(contents-tablet, contents-mobile) {
    font-size: 16px;
    margin-top: 15px;
    letter-spacing: -0.8px;
  }
}

// 상태값에 따른 스타일 정의 start

/*예약 신청*/
.requestStatus {
  .statusArea {
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      width: 20px;
      height: 20px;
      content: '';
      background: url(~public/static/images/reservation/corner_triangle.png) no-repeat center;
    }
  }
  .state {
    &::after {
      display: inline-block;
      width: 19px;
      height: 6px;
      margin-left: 4px;
      content: '';
      background: url(~public/static/images/reservation/ico_wait.png) no-repeat center;
    }
  }
}

/*예약 완료*/
.completeStatus {
  .state {
    &::after {
      display: inline-block;
      width: 22px;
      height: 16px;
      margin-left: 6px;
      content: '';
      background: url(~public/static/images/reservation/ico_complete.png) no-repeat center;
    }
  }
}

/*방문 완료*/
.visitStatus {
  .date {
    color: #909090;
  }
  .state {
    color: #5a5a5a;
    &::after {
      position: absolute;
      display: inline-block;
      content: '';
      background: url(~public/static/images/reservation/stamp.png) no-repeat center / cover;
      @include media(contents-pc) {
        top: 16px;
        right: 42px;
        width: 85px;
        height: 85px;
      }
      @include media(contents-tablet, contents-mobile) {
        top: 14px;
        left: calc(50% + 20px);
        width: 72px;
        height: 72px;
      }
    }
  }
  .infoArea {
    color: #909090;
  }
}

/*방문 취소, 예약 취소*/
.cancelStatus {
  .date {
    color: #909090;
  }
  .state {
    color: #5a5a5a;
  }
  .infoArea {
    color: #909090;
  }
}

/*대기 접수*/
.waitStatus {
  .statusArea {
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      width: 20px;
      height: 20px;
      content: '';
      background: url(~public/static/images/reservation/corner_triangle.png) no-repeat center;
    }
  }
}

/* 결제대기 */
.paymentStatus {
  .statusArea {
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      width: 20px;
      height: 20px;
      content: '';
      background: url(~public/static/images/reservation/corner_triangle.png) no-repeat center;
    }
  }
  .state {
    &::after {
      display: inline-block;
      margin-left: 5px;
      content: '결제 가능';
      color: #fff;
      background-color: #454de0;
      @include media(contents-pc) {
        font-size: 14px;
        padding: 7px 10px 8px;
        letter-spacing: -0.7px;
        border-radius: 14px;
      }
      @include media(contents-tablet, contents-mobile) {
        font-size: 13px;
        padding: 6px 8px;
        letter-spacing: -0.65px;
        border-radius: 12px;
      }
    }
  }
}

// 상태값에 따른 스타일 정의 end

@import 'style/mixin';

.titleContainer {
  position: relative;
  //width: 495px;
}

.title {
  @include media(main-mobile) {
    width: pxToVW(300, 360);
    max-width: 474px;
  }
  @include media(main-tablet) {
    width: 61vw;
  }
  @include media(main-pc-small) {
    width: 494px;
    margin-left: 60px;
  }
  @include media(main-pc-medium) {
    width: 38.6vw;
    margin-left: 4.7vw;
  }
}

.titleDescription {
  font-family: neodgm, sans-serif;
  line-height: 1.69;
  position: absolute;
  right: 0;
  letter-spacing: -0.61px;
  color: #d8d8d8;

  @include media(main-mobile) {
    display: flex;
    justify-content: flex-end;
    margin-top: pxToVW(18, 360);
    margin-right: pxToVW(-10, 360);
    font-size: getResponsiveRule(360, 768, 10, 12, false);
  }
  @include media(main-tablet) {
    font-size: 1.5vw;
    transform: translateY(8vw);
  }
  @include media(main-pc-small) {
    font-size: 12px;
    transform: translateY(-218px);
  }
  @include media(main-pc-medium) {
    font-size: 0.9vw;
    transform: translateY(-17vw);
  }
}

.following-image-canvas-container {
  position: fixed;
  width: 480px;
  height: 270px;
  overflow: hidden;
  display: none;
}

@media (min-width: 1000px) {
  .following-image-canvas-container {
    display: block;
  }
}

.following-image-canvas,
.following-image-canvas-image-wrapper,
.following-image-canvas-wrapper-element,
.following-image-canvas-image-container,
.following-image-canvas-image {
  width: 100%;
  height: 100%;
}

.following-image-canvas {
  position: relative;
  overflow: hidden;
  height: 0;
  transition: height 0.7s ease-in-out;
}

.following-image-canvas-image-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-color: #eaeaea;
}

.following-image-canvas-image-removal {
  height: 0;
}

.following-image-canvas-image {
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
}

@import 'style/mixin';

.status {
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  white-space: nowrap;

  @include media(contents-mobile, contents-tablet) {
    font-size: 13px;
    min-width: 61px;
    height: 26px;
    padding: 6px 8px;
    letter-spacing: -0.63px;
    border-radius: 13px;
  }

  @include media(contents-pc) {
    font-size: 15px;
    min-width: 73px;
    height: 30px;
    padding: 6px 9px 9px 10px;
    letter-spacing: -0.75px;
    border-radius: 15px;
  }
}

.attendanceTag {
  color: #fff;
  background-color: #272627;
}

.waitTag {
  color: #fff;
  background-color: #454de0;
}

.scheduleTag {
  white-space: nowrap;
  border: 1px solid #272627;
}

.progressTag {
  color: #fff;
  background-color: #272627;
}

.endTag {
  color: hsla(0, 0%, 100%, 0.5);
  background-color: #909090;
}

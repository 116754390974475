@import 'style/mixin';

.programWrapper {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 32px;
  background-color: rgba(255, 255, 255, 0.5);
  @include media(contents-pc) {
    padding: 25px 10px 25px 30px;
  }
  @include media(contents-tablet) {
    padding: 25px 34px 25px;
  }
  @include media(contents-mobile) {
    padding: 25px 15px 25px;
  }
}

.programContainer {
  width: 100%;
  @include media(contents-pc) {
    overflow: auto;
    max-height: 627px;
  }
  @include media(contents-tablet, contents-mobile) {
    overflow: visible;
  }
}

.programList {
  padding-right: 27px;
  @include media(contents-tablet, contents-mobile) {
    padding-right: 0;
  }
}

.programItem {
  position: relative;
  padding: 25px 0;
  @include media(contents-pc, contents-tablet) {
    justify-content: space-between;
  }
  @include media(contents-mobile) {
    flex-direction: column;
  }
}

.programItem + .programItem {
  &::before {
    position: absolute;
    top: 0;
    display: inline-block;
    width: 100%;
    height: 1px;
    content: '';
    background-color: #afafaf;
  }
}
.programDescription {
  @include media(contents-pc, contents-tablet) {
    min-height: 125px;
    margin-right: 185px;
  }
}

.programName {
  font-size: 17px;
  font-weight: bold;
  letter-spacing: -0.85px;
  color: #272627;
}

.programContent {
  font-size: 15px;
  line-height: 1.47;
  margin-top: 12px;
  letter-spacing: -0.75px;
  color: #747477;
}

.programInfoList {
  margin-top: 14px;
}

.programInfoItem {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.47;
  display: flex;
  letter-spacing: -0.75px;
  color: #272627;
  &::before {
    display: inline-block;
    width: 18px;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
  }
}

.programImageWrapper {
  width: fit-content;
  height: fit-content;
  object-fit: cover;
  @include media(contents-pc, contents-tablet) {
    position: absolute;
    top: 25px;
    right: 0;
  }
  @include media(contents-mobile) {
    position: relative;
    margin-bottom: 19px;
  }
}

.programImage {
  object-fit: cover;
}

.closed {
  &::before {
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    content: '마감\A(현장문의)';
    text-align: center;
    white-space: pre;
    color: #fff;
    background-color: rgba(23, 25, 29, 0.8);
  }
}

.closedFree {
  &::before {
    content: '현장문의';
  }
}

.time {
  &::before {
    background-image: url(~public/static/images/reservation/ico_time.png);
  }
}

.location {
  &::before {
    background-image: url(~public/static/images/reservation/ico_location.png);
  }
}

.amount {
  &::before {
    background-image: url(~public/static/images/reservation/ico_amount.png);
  }
}

.game {
  &::before {
    background-image: url(~public/static/images/reservation/ico_game.png);
  }
}

.audio {
  &::before {
    background-image: url(~public/static/images/reservation/ico_audio.png);
  }
}

.programAdmission {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  padding: 20px;
  background-color: #eaeaea;
  @include media(contents-pc, contents-tablet) {
    align-items: center;
  }
  @include media(contents-mobile) {
    flex-direction: column;
  }
}

.title {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.75px;
  color: #272627;
}

.programAdmissionSelect {
  display: flex;
  align-items: center;
  @include media(contents-pc, contents-tablet) {
    flex: 0 0 300px;
    justify-content: flex-end;
  }
  @include media(contents-mobile) {
    flex-direction: column;
    margin-top: 20px;
  }
}

.dropdownWrapper {
  background-color: #fff;
  @include media(contents-pc, contents-tablet) {
    flex: 0 0 180px;
  }
  @include media(contents-mobile) {
    width: 100%;
  }
}

.countButtonContainer {
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #272627;
  @include media(contents-pc, contents-tablet) {
    flex: 0 0 180px;
  }
  @include media(contents-mobile) {
    width: 100%;
    margin-top: 20px;
  }
}

.dropdownWrapper + .countButtonContainer {
  @include media(contents-pc, contents-tablet) {
    margin-left: 20px;
    padding-left: 20px;
    border-left: 1px solid rgba(65, 53, 26, 0.2);
  }
}

.countButtonWrapper {
  width: 40px;
  height: 40px;
  @include media(contents-pc, contents-tablet) {
    flex-direction: row;
  }
  @include media(contents-mobile) {
    flex-direction: column;
  }
}

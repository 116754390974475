@mixin set-button-color {
  .minus {
    background-image: url(~public/static/images/reservation/minus_active.svg);
  }
  .plus {
    background-image: url(~public/static/images/reservation/plus_active.png);
  }
}

.circleButton {
  width: 100%;
  height: 100%;
  border: solid 1px #afafaf;
  border-radius: 50%;
  background-color: #fff;
  @media (hover: hover) {
    &:hover {
      background-color: #4e4d4e;
      @include set-button-color;
    }
  }
  &:active {
    background-color: #272627;
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
    background-color: #9e9d9e;
  }

  &:active,
  &:disabled {
    @include set-button-color;
  }
}

.minus {
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: url(~public/static/images/reservation/minus_default.svg) no-repeat center / 16px 2px;
}

.plus {
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: url(~public/static/images/reservation/plus_default.png) no-repeat center / 16px 16px;
}

@import 'style/mixin';

.moreThingContainer {
  @include media(main-mobile) {
    margin-top: 86px;
    margin-bottom: 49.1px;
  }

  @include media(main-tablet, main-pc-small) {
    margin-top: 140px;
    margin-bottom: 108.5px;
  }

  @include media(main-pc-medium) {
    margin-top: 10.938vw;
    margin-bottom: 7.266vw;
  }
}

.moreThingText {
  display: block;
  font-style: normal;
  line-height: 1.37;
  letter-spacing: -2.33px;

  @include media(main-mobile) {
    font-size: 31px;
  }

  @include media(main-tablet, main-pc-small) {
    font-size: 40px;
  }

  @include media(main-pc-medium) {
    font-size: 2.5vw;
  }
}

.iconMore {
  @include media(main-mobile) {
    margin-top: 21px;
    width: 29.1px;
  }

  @include media(main-tablet, main-pc-small) {
    margin-top: 27px;
    width: 21.3px;
  }

  @include media(main-pc-medium) {
    margin-top: 1.563vw;
    width: 1.51vw;
  }
}

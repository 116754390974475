@import 'style/mixin';

@keyframes move {
  20% {
    bottom: -10%;
    left: -15%;
  }
  50% {
    bottom: 50%;
    left: -15%;
  }
  70% {
    bottom: 80%;
    left: 20%;
  }
  100% {
    bottom: 80%;
    left: 100%;
  }
}

@keyframes move360 {
  20% {
    left: 50%;
    bottom: 9%;
  }
  50% {
    left: 50%;
    bottom: 23%;
  }
  70% {
    left: 68%;
    bottom: 35%;
  }
  100% {
    left: 100%;
    bottom: 35%;
  }
}

@keyframes moveIn360 {
  20% {
    left: 50%;
    bottom: 9%;
  }
  50% {
    left: 50%;
    bottom: 23%;
  }
  100% {
    left: 100%;
    bottom: 30%;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.playListRouteContainer {
  position: absolute;

  @include media(main-mobile) {
    position: fixed;
    right: calc(0px - ((767px - 100vw) / 2.2));
    bottom: 12.5vh;
    width: 300px;

    @media (max-width: 360px) {
      right: pxToVW(-185, 360);
      width: pxToVW(300, 360);
    }
  }

  @include media(main-tablet) {
    // TODO: vh인지 vw인지 check 필요
    top: 56vh;
    right: 0;
    width: 38vw;
  }

  @include media(main-pc-small) {
    left: 842px;
    width: 334px;
    @media (min-width: 1180px) {
      right: 0;
      left: initial;
    }
  }

  @include media(main-pc-medium) {
    right: 0;
    width: 26vw;
  }
}

.routeImage768 {
  display: none;
  display: block;

  @include media(main-tablet, main-pc-small, main-pc-medium) {
    display: block;
  }
}

.routeImage360 {
  display: none;

  @include media(main-mobile) {
    display: block;
  }
}

.routeButtonWrapper {
  position: absolute;
  bottom: -10%;
  width: 216px;
  height: 78px;

  @include media(main-mobile) {
    position: fixed;
    bottom: 9%;
    left: calc(50% - getResponsiveRule(360, 768, 190, 253, false) / 2);
    width: getResponsiveRule(360, 768, 190, 253, false);
    height: getResponsiveRule(360, 768, 67.8, 90, false);
  }

  @include media(main-tablet) {
    left: -80%;
    width: 33vw;
    height: 11.85vw;
  }

  @include media(main-pc-small) {
    left: -60%;
    width: 230px;
    height: 83px;
  }

  @include media(main-pc-medium) {
    left: -60%;
    width: 18vw;
    height: 6.5vw;
  }
}

.routeButton {
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  height: 100%;
  padding-left: 20px;
  text-align: left;
  letter-spacing: -0.72px;
  color: #232423;
  background-image: url(~public/static/images/main/c-playlist/button-playlist-route.svg);
  background-size: 100% 100%;

  &:hover {
    .routeButtonBorder {
      animation: blink 1s ease-in infinite;
    }
  }

  @include media(main-mobile) {
    font-size: getResponsiveRule(360, 768, 16, 22, false);
    padding-right: getResponsiveRule(360, 768, 62, 88, false);
    padding-left: getResponsiveRule(360, 768, 12, 20, false);
  }

  @include media(main-tablet) {
    font-size: 2.86vw;
    padding-right: 11vw;
    padding-left: 2.5vw;
  }
  @include media(main-pc-small) {
    font-size: 19px;
    padding-right: 80px;
    padding-left: 20px;
  }
  @include media(main-pc-medium) {
    font-size: 1.56vw;
    padding-right: 6.2vw;
    padding-left: 1.2vw;
  }
}

.routeButtonBorder {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-image: url(~public/static/images/main/c-playlist/button-playlist-route-border.svg);
  background-size: 100% 100%;
}

.routeButtonText {
  text-align: center;
}

.focusedButtonWrapper {
  animation-name: move;
  animation-duration: 3s;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;

  @include media(main-mobile) {
    animation-name: move360;
  }

  @media (max-width: 360px) {
    animation-name: moveIn360;
  }
}

.iconPlaylistRobot {
  position: absolute;
  background-image: url(~public/static/images/main/c-playlist/icon-playlist-button-robot.svg);
  background-repeat: no-repeat;
  background-size: contain;

  @include media(main-mobile) {
    top: -32px;
    right: 42.6px;
    width: 17.5px;
    height: 32px;
  }
  @include media(main-tablet) {
    top: -4.86vw;
    right: 6.38vw;
    width: 2.6vw;
    height: 4.86vw;
  }
  @include media(main-pc-small) {
    top: -34.8px;
    right: 45.2px;
    width: 19px;
    height: 34.8px;
  }
  @include media(main-pc-medium) {
    top: -2.7vw;
    right: 3.53vw;
    width: 1.48vw;
    height: 2.7vw;
  }
}

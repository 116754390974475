.subHeader2 #content.guide-content .cnts {
  padding-top: 196px;
}
.subHeader2.no-reservation-banner #content.guide-content .cnts {
  padding-top: 156px;
}

.guideMuseumsBody {
  position: relative;
  max-width: 1720px;
  margin: 0 auto;
  padding: 0 32px;
  color: #272627;
}
.guideTitle {
  display: flex;
  align-items: flex-end;
  height: 550px;
  margin: 0 -32px;
  background-image: url('../image/sub/icon_arrow_two.png'), url('../image/sub/img_watch_main_m.png');
  background-repeat: no-repeat;
  background-position: right bottom, top center;
  background-size: 354px 139px, 1720px 550px;
}
.guideTitle dl {
  margin-bottom: 30px;
  margin-left: 32px;
  color: #fff;
}
.guideTitle dt {
  font-size: 47px;
  margin-bottom: 11px;
}
.guideTitle dd {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 50px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -2.53px;
}
.guideTitle dl dd span {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  margin-left: 12px;
  letter-spacing: -0.63px;
}
.guideTitle p {
  position: absolute;
  transform: rotate(-90deg);
}
.guideTitle .guideTitle_text {
  top: 79px;
  right: -102px;
  width: 170px;
  text-align: right;
}
.guideTitle .guideTitle_arrow_text {
  top: 495px;
  right: 281px;
  width: 100px;
}

.guideContent {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 100px;
}
.guideContent h3 {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 17px;
  font-weight: 400;
  box-sizing: border-box;
  width: 100%;
  height: 52px;
  margin-bottom: 40px;
  padding: 17px 0 0 61px;
  color: #fff;
  background-color: #272627;
  background-image: url('../image/sub/icon_care.png');
  background-repeat: no-repeat;
  background-position: 29px center;
  background-size: 24px 24px;
}
.guideContent h3 span {
  font-weight: 700;
}
.guideContent h4 {
  font-size: 30px;
  font-weight: 500;
  width: 100%;
  padding-bottom: 20px;
}
.guideContent h4 span {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin-left: 7px;
  vertical-align: top;
}
.guideContent .guide-container {
  width: 100%;
  padding: 27px 0 80px;
  border-top: 1px solid #909090;
}
.id-href-container {
  margin-top: -52px;
  padding-top: 52px;
}
.guideContent > div:last-child .guide-container {
  padding-bottom: 100px;
}
.guideContent dt {
  float: left;
  width: 369px;
  height: 50px;
  margin-top: 8px;
  background-image: url('../image/sub/icon_arrow_right.png');
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 51px 51px;
}
.guideContent dd {
  width: calc(100% - 369px);
  height: 100%;
  padding-left: 369px;
}

.guideContent .highlight {
  color: #ff6060;
}

.guideContent .guideHours dd {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.74;
  letter-spacing: -0.85px;
}
.guideContent .guideAdmission dd {
  height: 289px;
  background: url(~public/static/images/contents/guide/img-ticket-l.png) no-repeat 369px / contain;
}
.guideContent .guideItems dd ul {
  box-sizing: border-box;
  width: 100%;
  background-repeat: no-repeat;
  background-position: left top;
}
.guideContent .guideItems dd li:nth-child(1) {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 12px;
  letter-spacing: -0.85px;
}
.guideContent .guideItems dd li:nth-child(2) {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: -0.85px;
}
.guideContent .guideItems dd .kitText {
  position: relative;
  font-size: 17px;
  letter-spacing: -1.1px;
  line-height: 1.8;
}
.guideContent .guideItems dd .packText {
  position: relative;
  font-size: 17px;
  letter-spacing: -1.1px;
  line-height: 1.8;
}
.ticket-item-title {
  font-weight: 700;
  font-size: 17px;
}
.ticket-item-desc {
  position: absolute;
  left: 70px;
  font-size: 17px;
}
.ticket-item-desc.__added-desc {
  margin-top: 30px;
}
.guideContent .guideItems dd .packText br {
  display: none;
}
.guideContent .guideItems P {
  font-size: 17px;
  font-weight: 700;
  margin: 20px 0;
}
.guideContent .guideDiscount dd {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: -0.85px;
}
.guideContent .guideDiscount dd span {
  font-weight: 700;
  margin-right: 15px;
}
.guideContent .guideDiscount dd li {
  margin-bottom: 25px;
}
.guideContent .guideDiscount br {
  display: none;
}
.guideContent .guideMap dt {
  height: 50px;
}
.guideContent .guideMap dd {
  position: relative;
  width: 100%;
  padding-top: 82px;
  padding-left: 0;
}
.guideContent .guideMap .mapContent {
  width: calc(100% - 337px);
  height: 480px;
  background-color: white; /*nap넣고 삭제하기*/
}
.guideContent .guideMap .mapContent_text {
  position: absolute;
  top: 82px;
  right: 0;
  box-sizing: border-box;
  width: 337px;
  height: 480px;
  padding: 39px;
  background-color: #272627;
}
.guideContent .guideMap .mapContent_text li {
  font-size: 17px;
  line-height: 1.45;
  margin-bottom: 20px;
  letter-spacing: -1.85px;
  color: #909090;
}
.guideContent .guideMap .mapContent_text li:last-child {
  margin-bottom: 0;
}
.guideContent .guideMap .mapContent_text li span {
  font-weight: 500;
  color: #fff;
}

@media (max-width: 1440px) {
  .guideContent .guideAdmission dd {
    height: 514px;
    background: url(~public/static/images/contents/guide/img-ticket-m.png) no-repeat 282px / contain;
  }
}

@media all and (max-width: 1720px) {
  .subHeader2.no-reservation-banner #content.guide-content .cnts {
    padding-top: 111px;
  }
  .guideMuseumsBody {
    /*width: calc(100% - 100px);*/
  }
  .guideTitle .guideTitle_text {
    display: none;
  }

  .guideTitle dl dd span {
    font-size: 11px;
    margin-left: 10px;
  }

  .guideContent h4 span {
    margin-left: 5px;
  }
  .guideContent dt {
    width: 282px;
  }
  .guideContent dd {
    width: calc(100% - 282px);
    padding-left: 282px;
  }

  .guideContent .guideAdmission dd {
    background-position: 282px;
  }

  .guideContent .guideDiscount dd li {
    line-height: 1.8;
    margin-bottom: 10px;
    letter-spacing: -1.1px;
  }
  .guideContent .guideMap dd {
    width: 100%;
    padding-top: 90px;
    padding-left: 0;
  }
  .guideContent .guideMap .mapContent_text {
    top: 90px;
  }
}

@media (min-width: 1344px) {
  .guideMuseumsBody {
    padding: 0;
  }
  .guideTitle {
    margin: 0;
  }
}

@media (max-width: 999px) {
  .subHeader2 #content.guide-content .cnts {
    padding-top: 151px;
  }
  .guideMuseumsBody {
    padding: 0 15px;
  }
  .guideTitle {
    height: 42.188vw;
    max-height: 550px;
    margin: 0 -15px;
    background-image: url('../image/sub/icon_arrow_one.png'), url(../image/sub/img_watch_main_m.png);
    background-repeat: no-repeat;
    background-position: right bottom, center top;
    background-size: 84px 82px, 100% 100%;
  }

  .guideTitle dt {
    font-size: 35px;
    margin-bottom: 8px;
  }
  .guideTitle dd {
    font-size: 35px;
  }
  .guideTitle_text,
  .guideTitle .guideTitle_arrow_text {
    display: none;
  }

  .guideContent {
    padding-top: 60px;
  }
  .guideContent h3 {
    font-size: 13px;
    height: 42px;
    margin-bottom: 30px;
    padding: 14px 0 0 37px;
    background-position: 11px center;
    background-size: 20px 20px;
  }
  .guideContent dt {
    width: 210px;
    margin-top: 0;
    background-size: 44px 44px;
  }
  .guideContent dd {
    width: calc(100% - 210px);
    padding-left: 210px;
  }
  .guideContent h4 {
    font-size: 23px;
    padding-bottom: 13px;
  }
  .guideContent .guide-container {
    padding: 20px 0 65px;
  }
  .guideContent > div:last-child .guide-container {
    padding-bottom: 80px;
  }
  .guideContent .guideHours dd {
    font-size: 13px;
    line-height: 1.68;
    letter-spacing: -0.65px;
  }
  .guideContent .guideAdmission dd {
    height: 336px;
    background: url(~public/static/images/contents/guide/img-ticket-s.png) no-repeat 210px;
  }
  .guideContent .guideItems dd ul {
    /* padding-left: 88px; */
  }
  .guideContent .guideItems dd .kitText {
    /* height: 62px; */
    /* margin-bottom: 15px; */
    padding-top: 0;
    background-size: 58px 58px;
  }
  .guideContent .guideItems dd .packText {
    height: 59px;
    margin-top: 17px;
    padding-top: 8px;
    background-size: 58px 59px;
  }
  .guideContent .guideItems dd li:nth-child(2) {
    font-size: 13px;
  }
  .guideContent .guideItems dd li:nth-child(1) {
    margin-bottom: 9px;
  }
  .guideContent .guideItems P {
    padding-left: 17px;
  }

  .guideContent .guideDiscount br {
    display: block;
  }
  .guideContent .guideDiscount dd {
    font-size: 13px;
    background-position: 210px top;
  }
  .guideContent .guideMap .mapContent {
    width: 100%;
    height: 250px;
  }
  .guideContent .guideMap dd {
    width: 100%;
    padding-top: 90px;
    padding-left: 0;
  }
  .guideContent .guideMap .mapContent_text {
    position: static;
    width: 320px;
    height: 365px;
    padding: 30px;
  }
  .guideContent .guideMap .mapContent_text li {
    font-size: 13px;
    margin-bottom: 15px;
    letter-spacing: -0.65px;
  }
}

@media (max-width: 768px) {
  .guideTitle {
    height: 324px;
    background-position: right bottom, center top;
    background-size: 84px 82px, 768px 324px;
  }
}

@media all and (max-width: 599px) {
  .guideContent .guideHours dd {
    width: calc(100% - 30px);
    padding-left: 30px;
    word-break: keep-all;
  }
  .guideContent .guideDiscount dd {
    box-sizing: border-box;
    padding-left: 30px;
    word-break: keep-all;
  }
  .guideContent dt {
    width: 100%;
    background-size: 34px 34px;
  }
  .guideContent dd {
    width: 100%;
    padding-left: 0;
  }

  .guideContent .guideAdmission dd {
    height: 400px;
    background-position: center 50px;
  }
  .guideContent .guideItems dd {
    width: calc(100% - 50px);
    padding: 50px 25px 0;
    word-break: keep-all;
  }
  .guideContent .guideItems dd ul {
    /* padding-left: 81px; */
  }
  .guideContent .guideItems dd .kitText {
    /* height: 82px; */
    /* margin-bottom: 18px; */
    background-position: left 13px;
  }
  .guideContent .guideItems dd .packText {
    height: 72px;
    margin-top: 27px;
    padding-top: 0;
    background-position: left 5px;
  }
  .guideContent .guideItems dd .packText br {
    display: block;
  }
  .guideContent .guideMap dt {
    height: 44px;
  }
  .guideContent .guideMap dd {
    padding-top: 67px;
  }
  .guideContent .guideMap .mapContent {
    height: 200px;
  }
  .guideContent .guideMap .mapContent_text {
    width: 100%;
  }
}

@import 'style/mixin';

.introContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: rgb(32, 32, 31);
}

.introPlayer {
  display: none;
  width: 500px;
  height: 500px;

  @include media(main-mobile) {
    width: 250px;
    height: 250px;
  }
}

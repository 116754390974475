@import 'style/mixin';

@keyframes moveLeft {
  100% {
    left: 20px;
  }
}

@keyframes moveRight {
  100% {
    left: -20px;
  }
}

@keyframes moveDown {
  0% {
    bottom: 0;
  }
  100% {
    bottom: -20px;
  }
}

@keyframes gradientToRight {
  0% {
    background-position: right;
  }
  100% {
    background-position: left center;
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

@keyframes blinkButtonSide {
  50% {
    border-width: 0;
  }
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding-top: 52px;
  @include media(pc-small, pc-medium) {
    width: 100vw;
    height: 100vh;
    min-height: 510px;
    background-image: url(~public/static/images/flow/intro-background-web.svg);
    background-size: cover;
  }
  &::before {
    @include media(mobile, tablet) {
      position: fixed;
      z-index: -10;
      top: 0;
      right: 0;
      bottom: -20vh;
      left: 0;
      display: block;
      content: '';
      transform: translate3d(0, 0, 0);
      background-image: url(~public/static/images/flow/intro-background-mobile.svg);
      background-size: cover;
    }
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  @include media(pc-small, pc-medium) {
    position: absolute;
    top: 4.1vw;
    left: 50%;
    padding-top: 52px;
    transform: translate(-50%, 0);
  }
}

.title {
  text-indent: -9999px;
  background-image: url(~public/static/images/flow/intro-title.svg);
  background-repeat: no-repeat;
  background-size: contain;

  @include media(mobile) {
    width: 91.1vw;
    height: 20.6vw;
    margin-top: min(21.6vw, 130px);
  }

  @include media(tablet) {
    width: 546.7px;
    height: 123.3px;
    margin-top: 130px;
  }

  @include media(pc-small, pc-medium) {
    width: 17.7vw;
    height: 4vw;
  }
}

.subTitle {
  font-family: neodgm, sans-serif;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #2dffe5;
  @include media(mobile) {
    font-size: 4.7vw;
    padding: 4.05vw 0 15.6vw;
  }
  @include media(tablet) {
    font-size: 36px;
    padding: 31px 0 121.7px;
  }
  @include media(pc-small, pc-medium) {
    font-size: 1.2vw;
    padding-top: 0.7vw;
  }
  &::after {
    @include media(mobile, tablet) {
      position: relative;
      display: inline-block;
      content: '';
      animation: moveDown 1s ease infinite;
      background: url(~public/static/images/flow/intro-arrow-down.svg) center / cover no-repeat;
    }
    @include media(mobile) {
      width: 3.06vw;
      height: 5.56vw;
      margin-top: 4.3vw;
    }
    @include media(tablet) {
      width: 23px;
      height: 43px;
      margin-top: 33.5px;
    }
  }
}

.scrollArea {
  @include media(mobile, tablet) {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(~public/static/images/flow/intro-routes-mobile.svg);
    background-repeat: no-repeat;
    background-size: cover;
  }
  @include media(mobile) {
    background-position: -31vw center;
  }
  @include media(tablet) {
    background-position: calc(50% - 120px) center;
  }
  @include media(pc-small, pc-medium) {
    overflow-x: auto;
    margin-top: -10vw;
    padding: 10vw 0;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.routesContainer {
  position: relative;
  @include media(mobile) {
    width: 100vw;
    height: 414vw;
  }
  @include media(tablet) {
    width: 780px;
    height: 3229px;
  }
  @include media(pc-small, pc-medium) {
    overflow: visible;
    width: 200vw;
    min-width: 2000px;
    background-image: url(~public/static/images/flow/intro-routes-web.svg);
    background-repeat: no-repeat;
    background-size: cover;
    aspect-ratio: 9.3;
  }
}

.typeTitle {
  font-family: neodgm, sans-serif;
  text-align: center;
  text-transform: capitalize;
  color: #f5f5f5;
  border: 1px solid transparent;
  border-image-source: url(~public/static/images/flow/intro-type-title.svg);
  border-image-slice: 30 fill;
  border-image-width: 30px;
  border-image-repeat: stretch;

  @include media(mobile) {
    font-size: 4.1vw;
    padding: 1.4vw 2.5vw;
  }
  @include media(tablet) {
    font-size: 32px;
    padding: 11px 19.5px;
  }
  @include media(pc-small, pc-medium) {
    font-size: 1.2vw;
    line-height: 2.3vw;
    height: 2.2vw;
    padding: 0 0.73vw;
  }
  &.selected {
    color: #e2fe7c;
    border-image-source: url(~public/static/images/flow/intro-type-selected-title.svg);
  }
}

.stickerKoreanName {
  display: inline-block;
  transform: translateY(-4%);

  @include media(mobile) {
    font-size: 3.8462vw;
  }
  @include media(tablet) {
    font-size: 30px;
  }
  @include media(pc-small, pc-medium) {
    font-size: 1vw;
  }
}

.typeDescription {
  font-family: neodgm, sans-serif;
  font-size: 1.1vw;
  margin-top: 0.5vw;
  padding: 1.5vw;
  color: #232423;
  background-image: url(~public/static/images/flow/intro-type-description.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.route {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: none;

  @include media(mobile) {
    width: 41vw;
    max-width: 320px;

    &:nth-child(1) {
      top: -2.5%;
      left: 49%;
    }
    &:nth-child(2) {
      top: 5.5%;
      left: 5%;
    }
    &:nth-child(3) {
      top: 12%;
      left: 53%;
    }
    &:nth-child(4) {
      top: 23%;
      left: 34%;
    }
    &:nth-child(5) {
      top: 32%;
      left: 5%;
    }
    &:nth-child(6) {
      top: 39.5%;
      left: 52%;
    }
    &:nth-child(7) {
      top: 47%;
      left: 8%;
    }
    &:nth-child(8) {
      top: 53.5%;
      left: 49%;
    }
    &:nth-child(9) {
      top: 63%;
      left: 17%;
    }
    &:nth-child(10) {
      top: 72%;
      left: 47%;
    }
    &:nth-child(11) {
      top: 81%;
      left: 7%;
    }
    &:nth-child(12) {
      top: 88%;
      left: 47%;
    }
  }

  @include media(tablet) {
    width: 245px;

    &:nth-child(1) {
      top: -1.5%;
      left: 50%;
    }
    &:nth-child(2) {
      top: 6.5%;
      right: 60%;
    }
    &:nth-child(3) {
      top: 13.5%;
      left: 55%;
    }
    &:nth-child(4) {
      top: 23.8%;
      left: 36.5%;
    }
    &:nth-child(5) {
      top: 32.5%;
      right: 58.5%;
    }
    &:nth-child(6) {
      top: 40.5%;
      left: 55%;
    }
    &:nth-child(7) {
      top: 48%;
      left: 17%;
    }
    &:nth-child(8) {
      top: 54.5%;
      left: 52%;
    }
    &:nth-child(9) {
      top: 62.8%;
      right: 38%;
    }
    &:nth-child(10) {
      top: 72%;
      left: 49%;
    }
    &:nth-child(11) {
      top: 82.2%;
      right: 55.5%;
    }
    &:nth-child(12) {
      top: 89%;
      left: 49%;
    }
  }

  @include media(pc-small, pc-medium) {
    width: 12.5vw;
    &:nth-child(1) {
      top: 32%;
      left: 1.2%;
    }
    &:nth-child(2) {
      top: 70%;
      left: 10.5%;
    }
    &:nth-child(3) {
      top: 17%;
      left: 18.3%;
    }
    &:nth-child(4) {
      top: 60%;
      left: 26.4%;
    }
    &:nth-child(5) {
      top: 22%;
      left: 34.2%;
    }
    &:nth-child(6) {
      top: 59%;
      left: 42%;
    }
    &:nth-child(7) {
      top: 30%;
      left: 51.7%;
    }
    &:nth-child(8) {
      top: 63%;
      left: 60.2%;
    }
    &:nth-child(9) {
      top: 22%;
      left: 66.8%;
    }
    &:nth-child(10) {
      top: 55%;
      left: 75.8%;
    }
    &:nth-child(11) {
      top: 20%;
      left: 85.5%;
    }
    &:nth-child(12) {
      top: 53%;
      left: 92.3%;
    }
  }
}

.selectRouteButton {
  position: fixed;
  z-index: 60;
  left: 50%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: none;
  transform: translateX(-50%);
  background-image: linear-gradient(to right, #e2fe7c 45%, #f2ffbc 50%, #e2fe7c 55%);
  background-repeat: no-repeat;
  background-position: center;
  background-origin: border-box;
  box-shadow: 0 10px 30px 0 #000;

  @include media(mobile, tablet) {
    animation: gradientToRight 4s ease infinite;
  }

  &:hover {
    .highlight {
      visibility: visible;
      animation: blink 1s infinite step-end;
    }

    .selectRouteButtonBackground::before {
      animation: blinkButtonSide 1s infinite step-start;
    }
  }

  @include media(mobile) {
    bottom: 6.7vw;
    height: 20.5vw;
    border-radius: 10vw;
    background-size: 150vw 100%;
  }
  @include media(tablet) {
    bottom: 52px;
    height: 160px;
    border-radius: 78px;
    background-size: 1170px 100%;
  }
  @include media(pc-small, pc-medium) {
    bottom: 2.5vw;
    height: 4.5vw;
    animation: gradientToRight 5s ease infinite;
    border-radius: 18.056vw;
    background-size: 50vw 100%;
  }
}

.selectRouteButtonBackground {
  display: flex;
  align-items: center;
  height: 100%;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    border: 1px solid #000;

    @include media(mobile) {
      margin: 0.5vw;
      border-radius: 10vw;
    }
    @include media(tablet) {
      margin: 3.9px;
      border-radius: 78px;
    }
    @include media(pc-small, pc-medium) {
      margin: 0.28vw;
      border-radius: 18.056vw;
    }
  }

  &::after {
    position: absolute;
    right: 0;
    content: '';
    background-image: url(~public/static/images/flow/ico-intro-select-button.svg);
    background-repeat: no-repeat;
    background-size: 100%;

    @include media(mobile) {
      width: 15vw;
      height: 15vw;
      margin-right: 2vw;
    }
    @include media(tablet) {
      width: 120px;
      height: 120px;
      margin-right: 10px;
    }
    @include media(pc-small, pc-medium) {
      width: 3vw;
      height: 3vw;
      margin-right: 0.5vw;
    }
  }

  @include media(mobile) {
    margin: 0.5vw;
    padding-right: 20vw;
    border-radius: 10vw;
  }

  @include media(tablet) {
    margin: 3.9px;
    padding-right: 120px;
    border-radius: 78px;
  }

  @include media(pc-small, pc-medium) {
    margin: 0.28vw;
    padding-right: 3vw;
    border-radius: 18.056vw;
  }
}

.selectRouteButtonText {
  font-family: neodgm, sans-serif;
  font-size: 1.8vw;
  white-space: nowrap;
  color: #232423;

  @include media(mobile) {
    font-size: 6.7vw;
    padding-right: 0.2vw;
    padding-left: 8vw;
  }

  @include media(tablet) {
    font-size: 52.3px;
    padding-right: 25px;
    padding-left: 45px;
  }

  @include media(pc-small, pc-medium) {
    font-size: 1.6vw;
    padding-right: 1vw;
    padding-left: 1.5vw;
    letter-spacing: -0.03vw;
  }
}

.selectRouteKoreanText {
  display: inline-block;
  transform: translateY(-4%);

  @include media(mobile) {
    font-size: 6.4103vw;
  }

  @include media(tablet) {
    font-size: 50px;
  }

  @include media(pc-small, pc-medium) {
    font-size: 1.4vw;
  }
}

.highlight {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: hidden;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 5px solid #fedf35;

  @include media(mobile) {
    //margin: -0.5vw;
    border-radius: 10vw;
  }

  @include media(tablet) {
    //margin: -3.9px;
    border-radius: 78px;
  }

  @include media(pc-small, pc-medium) {
    //margin: -0.28vw;
    border-width: 0.3vw;
    border-radius: 5.18vw;
    border-radius: 14.787vw;
  }
}

.page {
  font-family: Baskerville;
  font-size: 1.7vw;
  position: absolute;
  right: 5vw;
  bottom: 3.9vw;
  display: flex;
  color: #fff;
  @include media(mobile, tablet) {
    display: none;
  }
}

.pageButton {
  position: relative;
  display: inline-block;
  width: 2.2vw;
  cursor: none;
  background-image: url(~public/static/images/flow/intro-right-arrow.svg);
  background-repeat: no-repeat;
  background-size: contain;
  aspect-ratio: 22/16;
  &.prev {
    left: -10px;
    transform: rotate(180deg);
    animation: moveRight 1s infinite ease;
  }
  &.next {
    left: 10px;
    animation: moveLeft 1s infinite ease;
  }
  &.hidden {
    visibility: hidden;
  }
}

.cursor {
  position: fixed;
  z-index: 3010;
  width: 31px;
  height: 49px;
  pointer-events: none;
  background-image: url(~public/static/images/flow/intro-cursor.svg);
  background-repeat: no-repeat;
  background-size: contain;
  filter: drop-shadow(5px 5px 5px #222);
}

@import 'style/mixin';

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rotateX360deg {
  to {
    transform: rotateX(360deg);
  }
}

@keyframes mainLineTranslateY {
  from {
    transform: translateY(16px);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes mainSecondLineThroughLine {
  from {
    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  }

  to {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}

.mainImageSection {
  position: relative;
  width: 100%;
}

.mainImageSVG {
  position: relative;
}

.defaultFadeIn {
  opacity: 0;
  animation-fill-mode: forwards;
}

.defaultTransLate {
  transform: translateY(16px);
  animation-fill-mode: forwards;
}

.fadeFirstLine {
  animation-name: fadeIn;
  animation-duration: 0.5s;
}

.translateYFirstLine {
  animation-name: mainLineTranslateY;
  animation-duration: 0.5s;
}

.fadeSecondLine {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-delay: 0.5s;
}

.translateYSecondLine {
  animation-name: mainLineTranslateY;
  animation-duration: 1s;
  animation-delay: 0.5s;
}

.fadeThirdLine {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-delay: 1s;
}

.translateYThirdLine {
  animation-name: mainLineTranslateY;
  animation-duration: 1s;
  animation-delay: 1s;
}

.clipPathTextLine {
  animation-name: mainSecondLineThroughLine;
  animation-duration: 1s;
  animation-delay: 1s;
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  animation-fill-mode: forwards;
}

.fadeSubText {
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-delay: 2s;
  opacity: 0;
  animation-fill-mode: forwards;
}

.iconSmileLink {
  display: block;
}

.iconSmileContainer1000 {
  position: absolute;
  top: 64%;
  right: 36%;

  .iconFunSpaceNCMRound {
    display: none;
  }

  .iconSmile {
    width: 4.896vw;
    height: 4.896vw;
    animation-name: rotateX360deg;
    animation-duration: 0.8s;
    animation-delay: 2s;
  }

  &:hover {
    cursor: none;
    .iconSmile {
      background-image: url(~public/static/images/main/main-section/img-pinkbin.svg);
    }

    .iconFunSpaceNCMRound {
      display: block;
      animation-name: rotate360;
      animation-duration: 20s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  }
}

.iconSmileContainer768 {
  position: absolute;
  top: 57%;
  right: 80%;

  .iconSmile {
    width: 7.317vw;
    height: 7.317vw;
    background-image: url(~public/static/images/main/main-section/img-pinkbin.svg);
  }

  .iconFunSpaceNCMRound {
    width: 16.016vw;
    height: 16.016vw;
    animation-name: rotate360;
    animation-duration: 20s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
}

.iconSmileContainer360 {
  position: absolute;
  top: 60%;
  right: 82%;

  .iconSmile {
    width: 10vw;
    height: 10vw;
    background-image: url(~public/static/images/main/main-section/img-pinkbin.svg);
  }

  .iconFunSpaceNCMRound {
    width: pxToVW(80, 360);
    height: pxToVW(80, 360);
    animation-name: rotate360;
    animation-duration: 20s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
}

.iconSmile {
  position: relative;
  z-index: 10;
  background-image: url(~public/static/images/main/main-section/img-pinkbin.svg);
  background-repeat: no-repeat;
  background-size: 100%;
}

.iconFunSpaceNCMContainer {
}

.iconFunSpaceNCMRound {
  position: absolute;
  top: -60%;
  left: -65%;
  width: 10.729vw;
  height: 10.729vw;
  background-image: url(~public/static/images/main/main-section/img-pinkbin-hover-background.svg);
  background-repeat: no-repeat;
  background-size: 100%;
}

.rotateSmileText {
  animation-name: rotate360deg;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.smilePointer1000 {
  position: fixed;
  z-index: 100;
  width: 272px;
  height: 249px;
  pointer-events: none;
  opacity: 0;
  background-image: url(~public/static/images/main/main-section/img-pinkbin-hover-pointer.svg);
  background-repeat: no-repeat;
}

.smilePointer768 {
  position: absolute;
  z-index: 100;
  top: 74%;
  left: 19%;
  width: pxToVW(138, 768);
  height: pxToVW(117, 768);
  background-image: url(~public/static/images/main/main-section/img-pinkbin-tablet-pointer.svg);
  background-repeat: no-repeat;
  background-size: 100%;
}

.smilePointer360 {
  position: absolute;
  z-index: 100;
  top: 81%;
  left: 8%;
  width: pxToVW(110, 360);
  height: pxToVW(93, 360);
  background-image: url(~public/static/images/main/main-section/img-pinkbin-mobile-pointer.svg);
  background-repeat: no-repeat;
  background-size: 100%;
}

.imagePointerAreaTheComputer {
  position: absolute;
  //width: 622px;
  //height: 100px;
  top: 2%;
  left: 13%;
  width: 32.396vw;
  height: 5.208vw;
}

.imagePointerAreaAnIdea {
  position: absolute;
  //width: 300px;
  //height: 77px;
  top: 2%;
  left: 62%;
  width: 15.625vw;
  height: 4.01vw;
}

.imagePointerAreaChanged {
  position: absolute;
  //width: 352px;
  //height: 103px;
  top: 32%;
  left: 46%;
  width: 18.333vw;
  height: 5.365vw;
}

@import 'style/mixin';

@keyframes moveUp {
  from {
    transform: translateY(200%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes moveDown {
  from {
    top: -52px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes moveLeft {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(calc(-100% + 100vw), 0, 0);
  }
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
}

.reservationBannerWrapper {
  position: fixed;
  z-index: 3000;
  top: 0;
  width: 100%;
  height: 52px;
}

.reservationBannerContainer {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;
  display: block;
  height: 100%;
  animation-name: moveDown;
  animation-duration: 1s;
  animation-timing-function: ease;
  background-color: #ffbbcf;
  animation-fill-mode: forwards;
}

.reservationBanner {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 300vw;
  height: 100%;
  transition: background-position 0.5s ease-out;
  animation-name: moveLeft;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  background-image: url(~public/static/images/layout/reservation-banner2.svg), url(~public/static/images/layout/reservation-banner2.svg);
  background-repeat: repeat-x;
  background-position: 50% 50%, 50% 200%;
  background-size: 526px 20px;

  &:hover {
    animation-play-state: paused;
    background-position: 50.5% -100%, 50.5% 50%;
  }
}

.headerContents {
  position: fixed;
  z-index: 3000;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 26px 18px 0 20px;
  pointer-events: none;
  //mix-blend-mode: difference;
  .reservationBannerWrapper + & {
    top: 52px;
  }

  @include media(main-mobile) {
    padding-top: 15px;
  }
}

.appTitle {
  width: 92px;
  height: 32px;
  transition-delay: 1s;
  transition-duration: 0.5s;
  transition-property: opacity;
  pointer-events: all;

  @include media(contents-pc) {
    width: 136px;
    height: 47px;
  }
}

.mainLink {
  display: block;
  height: 100%;
  background-image: url(~public/static/images/layout/ncm-logo.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.navigationWrapper {
  width: 50px;
  height: 50px;
  pointer-events: all;
}

.navigationOpenButton {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url(~public/static/images/layout/menu/icon-menu-hamburger.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.subPage {
  mix-blend-mode: normal;
  &.headerContents {
    @include media(mobile, tablet) {
      padding: 26px 15px 0;
    }
    @include media(pc-small, pc-medium) {
      padding: 26px 40px 0;
    }
  }
  .appTitle {
    @include media(mobile, tablet) {
      width: 92px;
      height: 32px;
    }
    @include media(pc-small, pc-medium) {
      width: 136px;
      height: 47px;
    }
  }
}

.black {
  .mainLink {
    background-image: url(~public/static/images/layout/ncm-logo-black.svg);
  }
  .navigationOpenButton {
    background-image: url(~public/static/images/layout/menu/icon-menu-hamburger-black.svg);
  }
}

.navigationContainer {
  position: fixed;
  z-index: 3000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  overflow: auto;
  justify-content: space-between;
  margin-top: 52px;
  background-color: #282728;

  &.hideBanner {
    margin-top: 0;
  }

  @include media(main-pc-small, main-pc-medium) {
    padding-top: 70px;
    padding-right: 8vw;
    padding-left: min(5vw, 145px);
  }

  @include media(main-mobile, main-tablet) {
    padding: 52px 28px 0 24px;
  }
}

.navigation {
  flex: 1;
}

.mainMenuLink {
  display: flex;
  color: #fff;

  &:hover {
    color: #fff;
    .mainMenuSup,
    .mainMenuTitle {
      animation-name: moveUp;
      animation-duration: 0.5s;
      animation-timing-function: linear;
    }

    .iconMainMenuHasChildren {
      animation-name: rotate360;
      animation-duration: 0.5s;
      animation-timing-function: linear;
    }
  }

  @include media(main-pc-small, main-pc-medium) {
    font-size: 50px;
  }

  @include media(main-mobile, main-tablet) {
    font-size: 40px;
    letter-spacing: -2px;
  }
}

.mainMenuLinkOrderWrapper {
  @include media(main-pc-small, main-pc-medium) {
    margin-right: 18px;
  }

  @include media(main-mobile, main-tablet) {
    margin-right: 12px;
  }
}

.mainMenuList {
  display: flex;
  flex-direction: column;
  width: fit-content;

  li + li {
    margin-top: 70px;
  }
}

.mainMenuSubList {
  display: none;
  flex-direction: column;
  padding-top: 30px;
  padding-left: 70px;

  li + li {
    margin-top: 30px;
  }

  @at-root .activate & {
    display: flex;
  }
}

.mainMenuSubLink {
  font-size: 20px;
  font-weight: 300;
  display: block;
  overflow: hidden;
  letter-spacing: -1px;
  color: #909090;

  @at-root .menuOverflowWrapper:hover & {
    animation-name: moveUp;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    color: #909090;
  }
}

.menuOverflowWrapper {
  display: block;
  overflow: hidden;
  cursor: pointer;
}

.mainMenuSup {
  font-family: kepler-std-display, sans-serif;
  font-size: 20px;
  display: block;
  letter-spacing: -1px;
  color: #fff;
}

.mainMenuTitle {
  display: block;
  overflow: hidden;
  margin-top: 6px;
}

.activate {
}

.iconMainMenuOutLink {
  width: 24px;
  height: 24px;
  background: url(~public/static/images/layout/menu/icon-main-menu-outlink.svg) no-repeat center / cover;

  @include media(main-pc-small, main-pc-medium) {
    margin-top: 20px;
    margin-left: 15px;
  }
  @include media(main-mobile, main-tablet) {
    margin-top: 15px;
    margin-left: 15px;
  }
}

.iconMainMenuHasChildren {
  align-self: center;
  margin-top: 6px;
  margin-left: 16px;
  background-image: url(~public/static/images/layout/menu/icon-menu-children-open.svg);
  background-size: contain;

  @at-root .activate & {
    height: 2px;
    background-image: url(~public/static/images/layout/menu/icon-menu-children-close.svg);
    background-size: contain;
  }

  @include media(main-pc-small, main-pc-medium) {
    width: 22px;
    height: 22px;
  }

  @include media(main-mobile, main-tablet) {
    width: 19px;
    height: 19px;
  }
}

.subMenuList {
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;

  li + li {
    margin-top: 40px;
  }

  @include media(main-pc-small, main-pc-medium) {
    min-width: 411px;
    margin-top: 114px;
  }

  @include media(main-mobile, main-tablet) {
    position: absolute;
    right: 32px;
    width: 295px;
    margin-top: 121px;
  }
}

.subMenuListItem {
}

.subMenuTitle {
  font-size: 26.5px;
  display: flex;
  align-items: center;
  color: #fff;

  &:hover {
    color: #fff;
  }

  &:before {
    content: '{';
    @include media(main-pc-small, main-pc-medium) {
      margin-right: 16px;
    }

    @include media(main-mobile, main-tablet) {
      margin-right: 10px;
    }
  }

  &:after {
    content: '}';
    @include media(main-pc-small, main-pc-medium) {
      margin-left: 16px;
    }

    @include media(main-mobile, main-tablet) {
      margin-left: 10px;
    }
  }

  @include media(main-mobile, main-tablet) {
    font-size: 20px;
    width: fit-content;
    letter-spacing: -1px;
  }
}

.iconOutLink {
  display: inline-block;
  margin-left: 6px;
  background-image: url(~public/static/images/layout/menu/icon-menu-outlink.svg);
  background-repeat: no-repeat;
  background-size: cover;

  @include media(main-pc-small, main-pc-medium) {
    width: 22px;
    height: 22px;
  }

  @include media(main-mobile, main-tablet) {
    width: 16px;
    height: 16px;
  }
}

.authProcessLink {
  font-size: 14px;
  display: inline-block;
  margin-top: 60px;
  letter-spacing: -0.7px;
  color: #fff;

  &:hover {
    color: #fff;
  }

  @include media(main-mobile, main-tablet) {
    position: absolute;
    right: 32px;
    width: 295px;
    margin-top: 324px;
    padding-bottom: 86px;
    text-align: left;
  }
}

.menuCloseButton {
  position: absolute;
  @include media(main-pc-small, main-pc-medium) {
    top: 41px;
    right: 47px;
  }

  @include media(main-mobile, main-tablet) {
    top: 32px;
    right: 28px;
  }
}

.iconMenuCloseButton {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-image: url(~public/static/images/layout/menu/icon-menu-close.svg);
  background-repeat: no-repeat;
  background-size: cover;

  @at-root .menuCloseButton:hover & {
    animation-name: rotate360;
    animation-duration: 0.5s;
    animation-timing-function: linear;
  }
}

.hide {
  display: none !important;
}

@charset "utf-8";

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
acronym,
address,
big,
cite,
code,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  border: 0;
  outline: 0;
  background: transparent;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
body {
  line-height: 1;
}
.no-scroll {
  overflow: hidden;
}
ol,
ul {
  list-style: none;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}
input[type='text'],
input[type='text']:focus {
  padding: 0;
  border: none;
  border-radius: 0;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
html,
body {
  max-width: 100%;
}
html {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}
body {
  overflow-x: hidden;
  width: 100%;
  background-color: #ffffff;
}
html,
body,
textarea,
select,
input,
button {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #4a4e57;
}
a {
  cursor: pointer;
  text-decoration: none;
  color: #363940;
}
a:hover {
  text-decoration: none;
  color: #363940;
}
button {
  display: inline-block;
  *overflow: visible;
  padding: 0;
  cursor: pointer;
  border: 0;
  background: transparent;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
caption,
legend,
.hidden {
  font-size: 0;
  line-height: 0;
  visibility: hidden;
  overflow: hidden;
  width: 0;
  height: 0;
  padding: 0;
}
.dimmed {
  position: fixed;
  z-index: 5000;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: #000000;
}
.scroll::-webkit-scrollbar {
  width: 6px;
}
.scroll2::-webkit-scrollbar {
  height: 6px;
}
.scroll::-webkit-scrollbar-track {
  background-color: transparent;
}
.scroll::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #c7c8cc;
}
.scroll::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}
.scroll {
  scrollbar-3dlight-color: #eaeaea;
  scrollbar-arrow-color: #c7c8cc;
  scrollbar-base-color: #eaeaea;
  scrollbar-face-color: #c7c8cc;
  scrollbar-track-color: #eaeaea;
  scrollbar-darkshadow-color: #eaeaea;
  scrollbar-highlight-color: #eaeaea;
  scrollbar-shadow-color: #eaeaea;
}

/* fonts */
.nsD {
  font-family: 'Noto Sans KR', sans-serif !important;
  font-weight: 300;
  font-style: normal;
}
.nsR {
  font-family: 'Noto Sans KR', sans-serif !important;
  font-weight: 400;
  font-style: normal;
}
.nsM {
  font-family: 'Noto Sans KR', sans-serif !important;
  font-weight: 500;
  font-style: normal;
}
.nsB {
  font-family: 'Noto Sans KR', sans-serif !important;
  font-weight: 700;
  font-style: normal;
}
.kdR {
  font-family: 'kepler-std-display', serif !important;
  font-weight: 400;
  font-style: normal;
}
.kdB {
  font-family: 'kepler-std-display', serif !important;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'neodgm';
  src: url('../font/neodgm_pro.ttf');
}
@font-face {
  font-family: 'NEXONFootball';
  src: url('../font/NEXONFootballLight.otf');
}
@font-face {
  font-family: 'NEXONFootballBold';
  src: url('../font/NEXONFootballBold.otf');
}

@font-face {
  font-family: 'NEXONLv2GothicRegular';
  src: url('../font/NEXONLv2Gothic.eot');
  src: url('../font/NEXONLv2Gothic.eot?#iefix') format('embedded-opentype'), url('../font/NEXONLv2Gothic.woff') format('woff'), url('../font/NEXONLv2Gothic.ttf') format('truetype');
}
@font-face {
  font-family: 'NEXONLv2GothicLight';
  src: url('../font/NEXONLv2GothicLight.eot');
  src: url('../font/NEXONLv2GothicLight.eot?#iefix') format('embedded-opentype'), url('../font/NEXONLv2GothicLight.woff') format('woff'),
    url('../font/NEXONLv2GothicLight.ttf') format('truetype');
}
@font-face {
  font-family: 'NEXONLv2GothicBold';
  src: url('../font/NEXONLv2GothicBold.eot');
  src: url('../font/NEXONLv2GothicBold.eot?#iefix') format('embedded-opentype'), url('../font/NEXONLv2GothicBold.woff') format('woff'),
    url('../font/NEXONLv2GothicBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Baskerville';
  font-style: normal;
  font-weight: normal;
  src: url('../font/LibreBaskerville-Regular.ttf');
}

@font-face {
  font-family: 'Baskerville';
  font-style: normal;
  font-weight: bold;
  src: url('../font/LibreBaskerville-Bold.ttf');
}

@font-face {
  font-family: 'Athelas';
  font-style: normal;
  font-weight: normal;
  src: url('../font/Athelas-Regular.ttf');
}

@font-face {
  font-family: 'Athelas';
  font-style: normal;
  font-weight: bold;
  src: url('../font/Athelas-Bold.ttf');
}

/* link underline effect */
.linkUnderline {
  position: relative;
}
.linkUnderline::before {
  content: '';
}
.linkUnderline::before {
  transition: transform 0.3s;
  transform: scale3d(0, 1, 1);
  transform-origin: 100% 50%;
}
.linkUnderline::before,
.linkUnderline::after {
  position: absolute;
  top: 100%;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 1px;
  pointer-events: none;
  background: #eaeaea;
}
.linkUnderline:hover::before {
  transform: scale3d(1, 1, 1);
  transform-origin: 0 50%;
}

.background-underline .link-underline {
  display: inline;
  transition: background-size 400ms;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
  background-repeat: no-repeat;
  background-position: 100% 100%, 0 100%;
  background-size: 100% 2px, 0 2px;
}

.background-underline .link-underline.white-line {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
  background-size: 100% 1px, 0 1px;
}

.background-underline:hover .link-underline {
  background-size: 0 2px, 100% 2px;
}

.background-underline:hover .link-underline.white-line {
  background-size: 0 1px, 100% 1px;
}

/* 2021년 12월 12일 공통 CSS 정리 */

/* from sub_reservation.css 8 - 13 */
/* same error.css 3 - 7 */
.bt {
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  width: 100%;
  height: 35px;
  padding-top: 15px;
  text-align: center;
  color: #ffffff;
}
.bt.disabled {
  cursor: default;
  pointer-events: none;
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: #c0c0c1 !important;
}
.bt1 {
  background-color: #272627;
}
.bt1:hover {
  background-color: #cccccc;
}
.bt2 {
  background-color: #9fa1a7;
}
.bt2:hover {
  color: #272627;
  background-color: #ffffff;
}

.container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.disabledContainer {
  cursor: default;
}

.checkbox {
  display: none;
}

.customCheckbox {
  display: inline-block;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border: 1px solid #c7c8cc;
  &:hover {
    border-color: #272627;
  }
}

.checkbox[value='true'] + .customCheckbox {
  background: url(~/public/static/images/components/checkbox/checked.png) no-repeat center / 16px 12px;
}

.checkbox[value='true'] + .customCheckbox.darkMode {
  border: 1px solid #272627;
  background: #272627 url(~/public/static/images/components/checkbox/checked_darkmode.png) no-repeat center / 16px 12px;
}

.checkbox[value='false'] + .customCheckbox {
}

.checkbox:disabled + .customCheckbox {
  pointer-events: none;
  background: #e0e0e0 url(~/public/static/images/components/checkbox/checked_disabled.png) no-repeat center / 16px 12px;
}

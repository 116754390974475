/* section 2 */

.highlight-pen {
  position: relative;
}
.highlight-pen::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  transition: background-position 0.5s ease-out;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 50%, #e2ff7c 50%);
  background-position: -0% 0;
  background-size: 200% auto;
}

.on .highlight-pen::before {
  opacity: 0.8;
  background-position: -100% 0;
}

@media (pointer: fine) {
  .highlight-pen:hover::before {
    opacity: 0.8;
    background-position: -100% 0;
  }
}

@media (max-width: 999px) {
  .pencil-underline:after {
    width: 100%;
  }
}

/* COMMON */
.hover-scale-image img {
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}
.hover-scale-image:hover img {
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  transform: scale(1.1);
}
.background-underline .link-underline {
  display: inline;
  transition: background-size 400ms;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
  background-repeat: no-repeat;
  background-position: 100% 100%, 0 100%;
  background-size: 100% 2px, 0 2px;
}

.background-underline .link-underline.white-line {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
  background-size: 100% 1px, 0 1px;
}

.background-underline:hover .link-underline {
  background-size: 0 2px, 100% 2px;
}

.background-underline:hover .link-underline.white-line {
  background-size: 0 1px, 100% 1px;
}

@import 'style/mixin';

.container {
  font-weight: 500;
  position: relative;
  height: 150px;
  margin-bottom: 68px;
  text-align: center;
  vertical-align: top;
  letter-spacing: -0.85px;
  color: rgba(23, 25, 29, 0.2);
  @include media(contents-pc, contents-tablet) {
    font-size: 17px;
  }
  @include media(contents-mobile) {
    font-size: 15px;
  }
}

.text {
  position: absolute;
  bottom: 0;
  transform: translateX(-25%);
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  vertical-align: top;
  @include media(contents-pc, contents-tablet) {
    margin: 34px 7px 0 8px;
  }
  @include media(contents-mobile) {
    margin: 27px 5px 0 6px;
  }
}

.prev_dot {
  background-color: #272627;
}

.next_dot {
  background-color: rgba(39, 38, 39, 0.2);
}

.state {
  display: inline-block;
}

.prev_state,
.next_state {
  &::before {
    display: block;
    content: '';
    border-radius: 50%;
    @include media(contents-pc, contents-tablet) {
      width: 30px;
      height: 30px;
      margin: 0 auto 33px;
    }
    @include media(contents-mobile) {
      width: 24px;
      height: 24px;
      margin: 0 auto 27px;
    }
  }
}

.prev_state {
  margin-right: -8px;
  color: #272627;
  &::before {
    background-color: #272627;
  }
}

.next_state {
  margin-left: -7px;
  &::before {
    background-color: rgba(23, 25, 29, 0.2);
  }
}

.current_state {
  margin: 0 3px 0 4px;
  color: #272627;
  &::before {
    display: block;
    margin: 0 auto 10px;
    content: '';
    background: url(~public/static/images/reservation/state_check.png) no-repeat center / cover;
    @include media(contents-pc, contents-tablet) {
      width: 77px;
      height: 77px;
    }
    @include media(contents-mobile) {
      width: 60px;
      height: 60px;
    }
  }
}

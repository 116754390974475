@import 'style/mixin';

.container {
  font-size: 18px;
  letter-spacing: -0.9px;
  color: #272627;
  background-color: rgba(255, 255, 255, 0.5);
  @include media(contents-pc) {
    padding: 30px 30px 0;
  }
  @include media(contents-tablet) {
    margin: 0 -34px;
    padding: 30px 34px 0;
  }
  @include media(contents-mobile) {
    margin: 0 -15px;
    padding: 30px 15px 0;
  }
}

.categoryContainer {
  padding-bottom: 30px;
  & + & {
    padding-top: 30px;
    border-top: 1px dashed #afafaf;
  }
}

.categoryTitle {
  font-weight: bold;
  margin-bottom: 30px;
  @include media(contents-pc) {
    font-size: 24px;
  }
  @include media(contents-tablet, contents-mobile) {
    font-size: 20px;
  }
}

.categoryList {
}

.ticketContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include media(contents-pc) {
    & + & {
      margin-top: 20px;
    }
  }
  @include media(contents-tablet, contents-mobile) {
    position: relative;
    align-items: start;
    & + & {
      margin-top: 36px;
    }
  }
}

.ticketTitle {
  @include media(contents-pc) {
    font-size: 20px;
  }
  @include media(contents-tablet, contents-mobile) {
    font-size: 18px;
  }
}

.bracket {
  color: #747477;
}

.ticketCounter {
  display: flex;
  align-items: center;
  @include media(contents-pc) {
    flex: 0 0 227px;
  }
  @include media(contents-tablet, contents-mobile) {
    flex: 0 0 150px;
  }
}

.price {
  font-weight: bold;
  @include media(contents-pc) {
    font-size: 20px;
    flex: 0 0 49px;
    margin-right: 28px;
    text-align: right;
  }
  @include media(contents-tablet, contents-mobile) {
    font-size: 18px;
    position: absolute;
    bottom: -5px;
    left: 0;
  }
}

.countNumber {
  flex: 0 0 70px;
  text-align: center;
}

.countButtonWrapper {
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
}

.cautionContainer {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: -0.75px;
  color: #747477;
  background-color: rgba(255, 255, 255, 0.5);
  @include media(contents-pc) {
    margin-top: 10px;
    padding: 25px 28px;
  }
  @include media(contents-tablet) {
    margin: 10px -34px 0;
    padding: 25px 34px;
  }
  @include media(contents-mobile) {
    margin: 10px -15px 0;
    padding: 25px 15px;
  }
}

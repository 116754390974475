@charset "utf-8";

.navigation-images {
  position: fixed;
  /*top: 0;*/
  /*z-index: 0;*/
  transform-origin: top left;
  pointer-events: none;
  will-change: transform;
}

.overImg {
  opacity: 0;
}

.mainContainer::before {
  position: fixed;
  top: -100%;
  left: -50%;
  width: 300%;
  height: 300%;
  content: '';
  animation: grain 8s steps(10) infinite;
  pointer-events: none;
  opacity: 0.6;
  background-image: url(../image/img_noise.png);
}
@keyframes grain {
  0%,
  to {
    transform: translate(0);
  }
  10% {
    transform: translate(-5%, -10%);
  }
  20% {
    transform: translate(-15%, 5%);
  }
  30% {
    transform: translate(7%, -25%);
  }
  40% {
    transform: translate(-5%, 25%);
  }
  50% {
    transform: translate(-15%, 10%);
  }
  60% {
    transform: translate(15%);
  }
  70% {
    transform: translateY(15%);
  }
  80% {
    transform: translate(3%, 35%);
  }
  90% {
    transform: translate(-10%, 10%);
  }
}

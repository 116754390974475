.main-contents {
  #mainLink {
    background-image: url(~public/static/images/layout/ncm-logo-black.svg);
  }
  &.on-is-navigation-white #mainLink {
    background-image: url(~public/static/images/layout/ncm-logo.svg);
  }
  #mainNavigationButton {
    background-image: url(~public/static/images/layout/menu/icon-menu-hamburger-black.svg);
  }
  &.on-is-navigation-white #mainNavigationButton {
    background-image: url(~public/static/images/layout/menu/icon-menu-hamburger.svg);
  }
}

::selection {
  background-color: #e3ff7c;
}

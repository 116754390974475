@import 'style/mixin';

.wrapper {
  @include media(main-mobile, main-tablet) {
    overflow: hidden;
    width: 100vw;
    padding: 50px 0;
  }
  @include media(main-pc-small, main-pc-medium) {
    overflow: auto;
    height: 100vh;
  }
}

.scrollArea {
  height: inherit;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 1000px) {
    overflow-x: auto;
  }

  @media (max-width: 999px) {
    //overflow: hidden;
  }
}

.container {
  display: flex;
  overflow: hidden;
  width: fit-content;

  @media (min-width: 1000px) {
    height: 100%;
    padding: 0 100%;
  }

  @media (max-width: 999px) {
    display: block;
    width: 100%;
  }
}

.stickerWrapper {
  position: relative;
  width: fit-content;
  height: fit-content;
  padding: 1vw;
  pointer-events: none;

  &:nth-child(1) {
    @include media(main-mobile, main-tablet) {
      left: 10%;
    }
    @include media(main-pc-small, main-pc-medium) {
      bottom: 5%;
    }
  }
  &:nth-child(2) {
    @include media(main-mobile, main-tablet) {
      left: 7%;
    }
    @include media(main-pc-small, main-pc-medium) {
      bottom: -9%;
    }
  }
  &:nth-child(3) {
    @include media(main-mobile, main-tablet) {
      left: -9%;
    }
    @include media(main-pc-small, main-pc-medium) {
      bottom: -5%;
    }
  }
  &:nth-child(4) {
  }
  &:nth-child(5) {
  }
  &:nth-child(6) {
    @include media(main-pc-small, main-pc-medium) {
      bottom: -6%;
    }
  }
}

.stickerWrapper:nth-child(2n - 1) {
  @include media(main-pc-small, main-pc-medium) {
    align-self: flex-end;
  }
}

.stickerWrapper:nth-child(2n) {
  @include media(main-mobile, main-tablet) {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

.stickerWrapper {
  &:nth-child(1) .sticker {
    transform: rotate(30deg);
  }
  &:nth-child(2) .sticker {
    transform: rotate(30deg);
  }
  &:nth-child(3) .sticker {
    transform: rotate(-30deg);
  }
  &:nth-child(4) .sticker {
    transform: rotate(-30deg);
  }
  &:nth-child(5) .sticker {
    transform: rotate(30deg);
  }
  &:nth-child(6) .sticker {
    transform: rotate(30deg);
  }
}

.stickerInteract {
  pointer-events: all;
}

.sticker {
  @include media(main-mobile) {
    width: getResponsiveRule(360, 767, 168, 215);
  }

  @include media(main-tablet) {
    width: 28vw;
  }

  @include media(main-pc-small) {
    width: 252.8px;
  }

  @include media(main-pc-medium) {
    width: 19.75vw;
  }
}

.stickerKoreanName {
  font-size: 22px;
}

@import "style/mixin";

@keyframes rollingBanner {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-25%, 0, 0);
  }
}

.bannerContainer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 51px;
  background-color: #ff0064;
}

.bannerRolling {
  display: flex;
  animation: rollingBanner 16s linear infinite;
}

.bannerContents {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.bannerContent {
  font-size: 15px;
  color: #fff;
}

.enContent {
  display: flex;
  align-items: center;
  &::before {
    display: inline-block;
    width: 50px;
    height: 2.3px;
    margin: 0 30px;
    content: '';
    background-color: #fff;
  }
  &::after {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 0 30px;
    content: '';
    background: url(~public/static/images/main/main-section/line-banner-icon.svg);
  }
}

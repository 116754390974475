@import 'style/mixin';

@keyframes bouncingArrowDown {
  0% {
    transform: translate3d(-50%, 20%, 0);
  }
  50% {
    transform: translate3d(-50%, -10%, 0);
  }
  100% {
    transform: translate3d(-50%, 20%, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.mainContainer {
  width: 100vw;
  height: 100vh;
}

.mainSection {
  position: fixed;
  display: flex;
  overflow: hidden;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 52px 15.7% 0;
  background-color: #191818;

  @include media(main-tablet) {
    padding: 52px 46px 0;
  }

  @include media(main-mobile) {
    align-items: initial;
    justify-content: center;
    padding: 52px pxToVW(25, 360) 0 pxToVW(22, 360);
  }
}

.mainSectionImage360 {
  @include media(main-mobile) {
    margin-top: pxToVH(146, 664);
    height: fit-content;
  }
}

.iconArrowDown {
  position: absolute;
  bottom: 40px;
  left: 50%;
  display: inline-block;
  width: 34px;
  height: 47px;
  transform: translate3d(-50%, 0, 0);
  animation-name: bouncingArrowDown;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  background-image: url(~public/static/images/main/main-section/icon-arrow-down.png);
  background-size: cover;
}

.mainVideo {
  position: fixed;
  left: 0;
  bottom: 0;

  @include media(main-pc-medium) {
    width: 21.8vw;
    height: 7.4vw;
  }
  @include media(main-pc-small) {
    width: 290px;
    height: 96px;
  }
  @include media(main-tablet) {
    width: 42.4vw;
    height: 15vw;
  }
  @include media(main-mobile) {
    width: 273px;
    height: 96px;
  }
}

.squareBanner {
  position: fixed;
  animation: fadeIn 0.5s linear 1.5s 1 forwards;
  opacity: 0;

  @include media(main-tablet, main-pc-small, main-pc-medium) {
    top: 155px;
    right: 33px;
  }

  @include media(main-mobile) {
    top: 122px;
    right: 25px;
  }
}

.lineBanner {
  position: absolute;
  z-index: 20;
  right: -650px;
  width: 2000px;
  animation: fadeIn 0.5s linear 2s 1 forwards;
  opacity: 0;

  @include media(main-tablet, main-pc-small, main-pc-medium) {
    bottom: 1vw;
    transform: rotate(-30deg);
  }
  @include media(main-mobile) {
    bottom: -10vw;
    transform: rotate(-23deg);
  }
}

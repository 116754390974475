@charset "utf-8";
/* CSS Document */

#clauseBody {
  position: relative;
  max-width: 900px !important;
  margin: 0 auto;
  color: #272627;
}
.clauseTitle {
  position: sticky;
  top: 173px;
  left: 0px;
  width: 190px;
  padding-bottom: 23px;
  border-bottom: 1px solid #272627;
}
.clauseTitle dt {
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 17px;
}
.clauseTitle dd {
  font-size: 42px;
  font-weight: 500;
}
.clauseTitle.fixedPosition {
  position: fixed;
  top: 180px;
  left: 50px;
}

/*개인정봉 처리방침사용*/
.titleText {
  top: 190px;
  line-height: 1.26;
}
.titleText dt {
  padding-bottom: 12px;
}

.clauseCentont {
  max-width: 660px;
  margin: 0 0 240px 239px;
  border-top: 1px solid #272627;
}
.clauseCentont dl {
  margin-top: 30px;
  font-size: 13px;
  line-height: 1.46;
  letter-spacing: -0.65px;
}
.clauseCentont dt {
  font-weight: 700;
}
.clauseCentont dd {
  font-weight: 400;
}

@media all and (max-width: 999px) {
  #clauseBody {
    width: calc(100% - 50px);
  }
  .clauseTitle {
    position: absolute;
    width: 100%;
    top: 195px;
    padding-bottom: 37px;
  }
  .clauseCentont {
    max-width: 100%;
    margin: 184px 0 97px 0;
    border-top: none;
  }
}

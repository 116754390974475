.dropdownContainer {
  position: relative;
  width: inherit;
  height: var(--height);
  background-color: inherit;
}

.allDisabled {
  pointer-events: none;
  background-color: #e0e0e0;
  .selectedItem {
    color: #afafaf;
  }
}

.selectedItem {
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: inherit;
  padding: 0 5px 0 16px;
  border: 1px solid #afafaf;
  background-color: var(--defaultColor);
  &::after {
    display: inline-block;
    width: 30px;
    height: 30px;
    content: '';
    background: url(~public/static/images/reservation/dropdown_arrow_default.png) no-repeat center / contain;
  }
  &:hover {
    border-color: #272627;
    &::after {
      background-image: url(~public/static/images/reservation/dropdown_arrow_active.png);
    }
  }
}

.openState {
  border-color: #afafaf !important;
  background-color: inherit;
  &::after {
    background-image: url(~public/static/images/reservation/dropdown_arrow_open.png) !important;
  }
}

.dropdownList {
  position: absolute;
  z-index: 20;
  overflow: auto;
  box-sizing: border-box;
  width: 100%;
  max-height: 160px;
  border: 1px solid #afafaf;
  background-color: inherit;
}

.lowerDropdownList {
  top: calc(var(--height) - 1px);
  border-top: 0;
}

.upperDropdownList {
  bottom: calc(var(--height) - 1px);
  border-bottom: 0;
}

.dropdownItem {
  font-size: 15px;
  font-weight: 400;
  line-height: var(--height);
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  height: var(--height);
  padding: 0 16px;
  &:hover {
    background: rgba(116, 116, 119, 0.1);
  }
}

.select {
  font-weight: 500;
  color: #272627;
}

.disabledItem {
  pointer-events: none;
  color: #c8c8c8;
}

.navigationWrapper {
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 1px;
  padding: 0 4vw;
  background: rgba(0, 0, 0, 0.1);
}

.navigationBar {
  position: absolute;
  left: 0;
  display: inline-block;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.5);
}

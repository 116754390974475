.videoModal {
  display: none;
  position: fixed;
  z-index: 4000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.5);

  &.isShow {
    display: flex;
  }
}

.videoModalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  margin: auto 0;
  padding: 0 15%;
}

.videoModalCloseButton {
  width: 28px;
  height: 28px;
  margin-bottom: 29px;
  background-image: url(~public/static/images/main/video-player/icon-button-close.svg);
  background-repeat: no-repeat;
  background-size: 100%;
}

.videoContainer {
  position: relative;
  width: 100%;
  padding-bottom: 75%;
  overflow: hidden;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}

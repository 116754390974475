@import 'style/mixin';

.footer {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  height: auto;
  padding: 44px 40px 42px;
  color: #d8d8d8;
  background-color: #232423;

  @include media(main-mobile) {
    flex-direction: column;
  }
}

.moreInteresting {
  display: flex;
  justify-content: space-between;

  @include media(main-mobile) {
    flex-direction: column;
    margin-bottom: 34px;
  }
}

.moreInterestingList {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.4;
  padding-bottom: 8px;
  letter-spacing: -0.42px;

  @include media(main-pc-medium) {
    width: 441px;
  }

  @include media(main-pc-small) {
    width: 184px;
  }

  @include media(main-tablet) {
    width: 150px;
  }

  @include media(main-mobile) {
    padding-bottom: 31px;
  }
}

.moreInterestingListItem {
  & + .moreInterestingListItem {
    margin-top: 14px;
  }
}

.interestingItem {
  padding-bottom: 6px;
  color: #d8d8d8;

  &:hover {
    color: #d8d8d8;
  }
}

.moreInterestingSocialList {
  display: flex;
  align-items: flex-end;

  @include media(main-mobile) {
    margin-left: -14px;
  }
}

.moreInterestingSocialListItem {
  overflow: hidden;

  & + .moreInterestingListItem {
    margin-right: 2px;
  }
}

.linkSocial {
  display: block;
}

.iconSocial {
  display: block;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: 50px 50px;
}

.iconSocialYouTube {
  background-image: url(~public/static/images/layout/footer/youtube.svg);
}

.iconSocialNaverBlog {
  background-image: url(~public/static/images/layout/footer/naver-blog.svg);
}

.iconSocialFacebook {
  background-image: url(~public/static/images/layout/footer/facebook.svg);
}

.iconSocialTwitter {
  background-image: url(~public/static/images/layout/footer/twitter.svg);
}

.iconSocialInstagram {
  background-image: url(~public/static/images/layout/footer/instagram.svg);
}

.iconSocialKakaotalk {
  background-image: url(~public/static/images/layout/footer/kakaotalk.svg);
}

.footerInformation {
  line-height: 1.55;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 75px;
  word-break: keep-all;
}

.footerInformation > .paragraph + .paragraph {
  margin-top: 14px;
}

.privacyPolicy {
  font-size: 11px;
  display: flex;
}

.privacyPolicyFont {
  font-size: 11px;
  font-weight: bold;
  color: #d8d8d8;

  &:hover {
    color: #d8d8d8;
  }
}

.address {
  font-size: 11px;
  font-style: normal;
}

.business {
  font-size: 11px;
  letter-spacing: -0.09px;
}

.businessLink {
  text-decoration: underline;
  color: #fff;
  &:hover {
    text-decoration: underline;
    color: #fff;
  }
}

.copyright {
  font-size: 11px;
}

.linkUnderline {
  position: relative;

  &::before {
    content: '';
    transition: transform 0.3s;
    transform: scale3d(0, 1, 1);
    transform-origin: 100% 50%;
  }

  &::before,
  &::after {
    position: absolute;
    top: 100%;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 1px;
    pointer-events: none;
    background-color: #d8d8d8;
  }

  &:hover::before {
    transform: scale3d(1, 1, 1);
    transform-origin: 0 50%;
  }
}

.pencilUnderline {
  position: relative;
  display: inline-block;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    content: '';
    transition: width 0.2s;
    background-image: url(~public/static/images/layout/footer/pencil-underline.svg);
    background-size: 54px 2px;
  }

  &:hover::after {
    width: 100%;
  }

  @include media(main-mobile, main-tablet) {
    &::after {
      width: 100%;
    }
  }
}

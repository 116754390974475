.nameTag {
  font-family: neodgm, sans-serif;
  font-size: 24px;
  line-height: 40px;
  position: fixed;
  z-index: 40;
  display: inline-block;
  height: 40px;
  padding: 0 10px;
  text-align: center;
  white-space: nowrap;
  text-transform: capitalize;
  pointer-events: none;
  opacity: 0;
  color: #f5f5f5;
  border: 1px solid transparent;
  border-image-source: url(~public/static/images/flow/intro-type-title.svg);
  border-image-slice: 30 fill;
  border-image-width: 30px;
  border-image-repeat: stretch;
  filter: drop-shadow(5px 5px 5px #222);
}
